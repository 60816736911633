import EventSeatIcon from "@mui/icons-material/EventSeat";
import Refresh from "@mui/icons-material/Refresh";
import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button, PageHeader, Spacer } from "common";
import useHeaderHeight from "helpers/useHeaderHeight";
import I18n from "i18n";
import moment from "moment";
import { Outlet } from "react-router-dom";
import StreetSalesFilter from "./StreetSalesFilter";
import StreetSalesList from "./StreetSalesList";
import useStreetSalesList from "./useStreetSalesList";

export default function StreetSalesPage() {
  const {
    streetSalesData,
    loading,
    load,
    handleFilter,
    setRange,
    range,
    isEmpty,
    setSettings,
  } = useStreetSalesList();
  const headerHeight = useHeaderHeight();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        //position: "relative",
      }}
    >
      <Box
        sx={{
          px: smDown ? 2 : 0,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <PageHeader
          avatar={<EventSeatIcon />}
          title={I18n.t("StreetSales.title")}
        >
          <IconButton onClick={() => load()}>
            <Refresh />
          </IconButton>
        </PageHeader>
        <StreetSalesFilter setRange={setRange} range={range} />
        <Spacer h={1} />
        <StreetSalesList streetSalesData={streetSalesData} />
        {loading && (
          <LinearProgress
            sx={{ position: "fixed", left: 0, top: headerHeight, right: 0 }}
          />
        )}
        {isEmpty && (
          <Alert
            severity="info"
            action={
              <Button
                variant="text"
                title="Reset"
                size="small"
                onClick={() => {
                  setRange({
                    start: moment().startOf("day"),
                    end: moment().endOf("day"),
                  });
                  setSettings({ programIds: undefined, boatIds: undefined });
                }}
              />
            }
          >
            A jelenelgi feltételekkel nem található adat.
          </Alert>
        )}
      </Box>
      <Outlet />
    </Box>
  );
}
