import EventSeatIcon from "@mui/icons-material/EventSeat";
import Refresh from "@mui/icons-material/Refresh";
import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button, PageHeader, Spacer } from "common";
import I18n from "i18n";
import moment from "moment";
import { Outlet } from "react-router-dom";
import HostFilter from "./HostFilter";
import HostList from "./HostList";
import useHostList from "./useHostList";

export default function HostPage() {
  const {
    hostData,
    loading,
    load,
    handleFilter,
    setRange,
    range,
    loaded,
    isEmpty,
    setSettings,
  } = useHostList();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        //position: "relative",
      }}
    >
      <Box
        sx={{
          px: smDown ? 2 : 0,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <PageHeader avatar={<EventSeatIcon />} title={I18n.t("Host.title")}>
          <IconButton onClick={() => load()}>
            <Refresh />
          </IconButton>
        </PageHeader>
        <HostFilter setRange={setRange} range={range} />
        <Spacer h={1} />
        <HostList hostData={hostData} />
        {loading && (
          <LinearProgress
            sx={{ position: "fixed", left: 0, top: 0, right: 0 }}
          />
        )}
        {isEmpty && (
          <Alert
            severity="info"
            action={
              <Button
                variant="text"
                color="inherit"
                title="Reset"
                size="small"
                onClick={() => {
                  setRange({
                    start: moment().startOf("day"),
                    end: moment().endOf("day"),
                  });
                  setSettings({ programIds: undefined, boatIds: undefined });
                }}
              />
            }
          >
            A jelenelgi feltételekkel nem található adat.
          </Alert>
        )}
      </Box>
      <Outlet />
    </Box>
  );
}
