import CommentIcon from "@mui/icons-material/Comment";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, DialogTitle, Toolbar } from "common";
import { Layout } from "components/TableEditor/TableEditor";
import { Booking } from "features/catering/cateringApi";
import OrderItem from "features/catering/OrderItem";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import I18n from "i18n";
import { size } from "lodash";
import React, { useRef, useState } from "react";

export interface TableSelectorProps {
  value: string[] | null;
  reserved: string[] | null;
  onChange: (selectedTables: string[] | null) => void;
  layouts: Layout[]; // Updated to use layouts instead of tables
  bookings?: Booking[];
}

const TableSelector: React.FC<TableSelectorProps> = ({
  value = [],
  reserved = [],
  onChange,
  layouts = [],
  bookings = [],
}) => {
  const originalValue = useRef(value);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedBooking, setSelectedBooking] = useState<Booking | undefined>();

  const toggleTableSelection = (tableName: string) => {
    let updatedSelection;
    if (value?.find(t => String(t) === String(tableName))) {
      updatedSelection = value.filter(
        name => String(name) !== String(tableName)
      );
    } else {
      updatedSelection = [...(value || []), tableName];
    }
    onChange(updatedSelection);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const theme = useTheme();
  const { resolveOption, resolveOptions } = useDictionaries();
  const program = resolveOption<ProgramItem>(
    "programs",
    selectedBooking?.program_id
  );

  const qty: number =
    (selectedBooking?.number_of_adults || 0) +
    (selectedBooking?.number_of_kids || 0);
  const qty_babies: number = selectedBooking?.number_of_babies || 0;
  const qty_guides: number = selectedBooking?.number_of_guides || 0;

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs for layouts */}
      <Tabs value={selectedTab} onChange={handleTabChange}>
        {layouts.map((layout, index) => (
          <Tab key={index} label={layout.name} />
        ))}
      </Tabs>

      {/* Layout content for the selected tab */}
      <Box
        sx={{
          position: "relative",
          height: "500px",
          border: theme => `1px solid ${theme.palette.divider}`,
          backgroundColor: theme => theme.palette.background.sheet,
          backgroundImage: theme =>
            `linear-gradient(to right, ${theme.palette.divider} 1px, transparent 1px),linear-gradient(to bottom, ${theme.palette.divider} 1px, transparent 1px)`,
          backgroundSize: `15px 15px`,
          overflowX: "auto",
        }}
      >
        {layouts[selectedTab]?.layout.map(table => {
          const isTable = table.type === "table";
          const isBookable =
            (!reserved?.find(t => String(t) === String(table.name)) ||
              originalValue.current?.find(
                t => String(t) === String(table.name)
              )) &&
            table.type === "table";
          const booking = bookings?.find(b =>
            b.table_numbers?.includes(table.name)
          );

          const selected = !!value?.find(t => String(t) === String(table.name));
          return (
            <Box
              key={table.id}
              sx={{
                position: "absolute",
                left: table.x - 1,
                top: table.y - 1,
                width: table.width - 4,
                height: table.height - 4,
                backgroundColor: theme =>
                  selected ? theme.palette.info.dark : table.color,
                border: "2px solid",
                borderColor: theme =>
                  selected ? theme.palette.info.main : table.color,
                color: theme =>
                  theme.palette.getContrastText(
                    selected ? theme.palette.info.dark : table.color
                  ),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //cursor: isBookable ? "pointer" : "not-allowed",
                cursor: "pointer",
                opacity: isBookable || !isTable ? 1 : 0.3,
                fontWeight: 700,
              }}
              onClick={
                isBookable
                  ? () => toggleTableSelection(table.name)
                  : booking
                  ? () => setSelectedBooking(booking)
                  : undefined
              }
            >
              <Typography
                variant="caption"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 2,
                  fontWeight: 700,
                  color: theme =>
                    theme.palette.getContrastText(
                      selected ? theme.palette.info.dark : table.color
                    ),
                  opacity: 0.7,
                }}
              >
                {table.max_person}
              </Typography>
              {table.name}
            </Box>
          );
        })}
      </Box>
      <Dialog
        open={!!selectedBooking}
        onClose={() => setSelectedBooking(undefined)}
        fullWidth
      >
        <DialogTitle onClose={() => setSelectedBooking(undefined)}>
          Foglalás részletei
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
            gap={1}
          >
            <Box display="flex" flexDirection="column">
              <Toolbar sx={{ gap: 1 }}>
                <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                  {selectedBooking?.name}
                </Typography>
                {!!selectedBooking?.check_in_at && (
                  <Box
                    sx={{
                      backgroundColor:
                        selectedBooking?.seating_layout_ids &&
                        size(selectedBooking?.table_numbers) === 0
                          ? "warning.dark"
                          : "success.dark",
                      fontSize: "0.7rem",
                      height: "18px",
                      pl: "3px",
                      pr: "3px",
                      borderRadius: "3px",
                      color: theme =>
                        theme.palette.getContrastText(
                          selectedBooking?.seating_layout_ids &&
                            size(selectedBooking?.table_numbers) === 0
                            ? theme.palette.warning.dark
                            : theme.palette.success.dark
                        ),
                    }}
                  >
                    megérkezett
                  </Box>
                )}
                {!!selectedBooking?.armband_at && (
                  <Box
                    sx={{
                      backgroundColor: "info.dark",
                      fontSize: "0.7rem",
                      height: "18px",
                      pl: "3px",
                      pr: "3px",
                      borderRadius: "3px",
                      color: theme =>
                        theme.palette.getContrastText(theme.palette.info.dark),
                    }}
                  >
                    karszalag
                  </Box>
                )}
                {!selectedBooking?.paid && (
                  <Box
                    sx={{
                      backgroundColor: "error.dark",
                      fontSize: "0.7rem",
                      pl: "3px",
                      pr: "3px",
                      borderRadius: "3px",
                      height: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: theme =>
                        theme.palette.getContrastText(theme.palette.error.dark),
                    }}
                  >
                    fizetendő{" "}
                    {I18n.numberToCurrency(selectedBooking?.amount || 0, {
                      precision: 2,
                      unit: "€",
                      format: "%u %n",
                      separator: ",",
                      delimiter: " ",
                      stripInsignificantZeros: true,
                    })}
                  </Box>
                )}
              </Toolbar>
              <Typography
                variant="body2"
                sx={{ wordBreak: "break-all", overflowWrap: "break-word" }}
              >
                {selectedBooking?.email}
              </Typography>
              {selectedBooking?.description && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                    color: theme => theme.palette.info.main,
                  }}
                >
                  <CommentIcon fontSize="small" />
                  <Typography variant="body2">
                    {selectedBooking?.description}
                  </Typography>
                </Box>
              )}
              <Toolbar sx={{ gap: 1 }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "5px",
                    backgroundColor: program?.color,
                  }}
                />
                <Typography>{selectedBooking?.program_variant_name}</Typography>
                <Typography>
                  <b>{qty}</b> fő
                  {qty_babies > 0 ? ` + ${qty_babies} baba` : ""}
                  {qty_guides > 0 ? ` + ${qty_guides} kísérő` : ""}
                </Typography>

                {selectedBooking?.seating_layout_ids &&
                  selectedBooking?.table_numbers && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <EventSeatIcon fontSize="small" />
                      <Typography variant="body1" fontWeight={500}>
                        {selectedBooking?.table_numbers?.join(", ")}
                      </Typography>
                    </Box>
                  )}
              </Toolbar>
              <Toolbar>
                <Typography variant="body2" color="text.secondary">
                  {selectedBooking?.booking_number}
                </Typography>
              </Toolbar>
            </Box>
            <Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
              {selectedBooking?.variant_name && (
                <OrderItem
                  name={selectedBooking?.variant_name}
                  qty={qty}
                  type="variant"
                />
              )}
              {selectedBooking?.booking_options?.map((option, idx) => (
                <OrderItem
                  key={idx}
                  name={option.name}
                  qty={option.qty}
                  type="option"
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSelectedBooking(undefined)}
            title={I18n.t("App.close")}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TableSelector;
