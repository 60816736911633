import {
  alpha,
  Box,
  BoxProps,
  FormHelperText,
  FormHelperTextProps,
} from "@mui/material";
import I18n from "i18n";
import moment from "moment-timezone";
import { useDialog } from "./../Dialog";

export interface CreatedUpdatedByProps extends BoxProps {
  item?: object & {
    id?: any;
    created_at?: string;
    created_by?: number;
    created_by_name?: string;
    updated_at?: string;
    updated_by?: number;
    updated_by_name?: string;
  };
  renderDialog?: (props: {
    open: boolean;
    onClose?:
      | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
      | undefined;
  }) => JSX.Element;

  formHelperTextProps?: FormHelperTextProps;
}
export default function CreatedUpdatedBy({
  item,
  formHelperTextProps,
  renderDialog,
  ...boxProps
}: CreatedUpdatedByProps) {
  const [modalOpen, setModalOpen, modalProps] = useDialog<boolean>({
    value: false,
  });
  //test
  return (
    <>
      <Box
        onClick={renderDialog ? () => setModalOpen(true) : undefined}
        {...boxProps}
        sx={{
          //flex: 1,
          "&>p": {
            lineHeight: "0.9rem",
            fontStyle: "italic",
            cursor: renderDialog ? "pointer" : "default",
            color: theme => alpha(theme.palette.text.secondary, 0.4),
          },
          "&:hover > p": {
            color: theme => theme.palette.text.secondary,
          },
          ...boxProps.sx,
        }}
      >
        {!!item?.created_at && (
          <FormHelperText {...formHelperTextProps}>
            {I18n.t("App.created")}: {moment(item?.created_at).format("L LT")}{" "}
            {item?.created_by_name}
          </FormHelperText>
        )}
        {!!item?.updated_at && (
          <FormHelperText {...formHelperTextProps}>
            {I18n.t("App.updated")}: {moment(item?.updated_at).format("L LT")}
            {item?.updated_by_name},{" "}
            <Box
              component="span"
              sx={{
                fontWeight: 700,
                py: "1px",
                px: "2px",
                borderRadius: "4px",
                backgroundColor: theme => alpha(theme.palette.info.main, 0.2),
              }}
            >
              #{item.id}
            </Box>
          </FormHelperText>
        )}
      </Box>
      {renderDialog && renderDialog(modalProps)}
    </>
  );
}
