import { Box, Paper, useTheme } from "@mui/material";
import DateRangeSelector2 from "components/DateRangeSeletor/DateRangeSelector2";
import moment from "moment";
import { useSimpleReportContext } from "../useSimpleReport";

export interface SimpleReportFilterProps {}

const SimpleReportFilter: React.FC<SimpleReportFilterProps> = ({}) => {
  const { filter, setFilter, params, setParams } = useSimpleReportContext();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          p: 1,
          display: "flex",
          alignItems: "center",
          flex: 1,
          alignSelf: "stretch",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: 1,
            display: "flex",
            alignItems: "center",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "center",
            gap: 2,
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <DateRangeSelector2
            numberOfCalendars={1}
            numberOfYears={1}
            value={{ start: moment(filter.from), end: moment(filter.to) }}
            onChange={(start, end) => {
              setFilter({
                ...filter,
                from: start.format("YYYY-MM-DD"),
                to: end.format("YYYY-MM-DD"),
              });
            }}
            dateButtonMinWidth={240}
          />
        </Box>
      </Paper>
    </Box>
  );
};
export default SimpleReportFilter;
