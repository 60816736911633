//import { useGrid } from "common";
import { useParams } from "common/components/Form/ParamsContext";
import {
  SalesPersonItem,
  getSalesPersonService,
} from "./salesPersonApi";

export default function useSalesPersonList() {
  const params = useParams<"sales_person_group_id">();
  const scope = { "sales-person-groups": params.sales_person_group_id };
  const editUrl = `${
    Object.values(scope || {}).filter(v => !!v).length > 0
      ? "sales-persons/"
      : ""
  }`
  const service = getSalesPersonService({ "sales-person-groups": params.sales_person_group_id });

  return { ...service, params, scope, editUrl };
}