import { HeaderSideBarContent } from "common";
import SessionHandler from "features/profile/SessionHandler";
import WebSocketStatusBanner from "features/websoket/WebSocketStatusBanner";
import { motion } from "framer-motion";
import LayoutStyles2 from "layouts/LayoutStyles2";
import { Outlet, useLocation } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import AdminSidebarMenu from "./AdminSidebarMenu";

export default function AdminLayout() {
  const { pathname } = useLocation();
  return (
    <>
      <LayoutStyles2 />
      <HeaderSideBarContent
        closedWidth={72}
        fullWidthHeader
        drawerProps={{
          sx: {
            [`& div.MuiDrawer-paper`]: {
              borderRight: "none",
            },
          },
        }}
        header={<AdminHeader />}
        //breadcrumbs={<Breadcrumbs items={getMenu(true)} />}
        sidebar={<AdminSidebarMenu />}
      >
        <motion.div
          className="routeAnimation"
          /*key={pathname}
      initial="initial"
      animate="in"
      variants={pageVariants}
      transition={pageTransition}*/
          //key={pathname}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <WebSocketStatusBanner />
          <Outlet />
        </motion.div>
        <SessionHandler />
      </HeaderSideBarContent>
    </>
  );
}
