import { Info } from "@mui/icons-material";
import Refresh from "@mui/icons-material/Refresh";
import { Alert, IconButton, LinearProgress } from "@mui/material";
import { PageHeader, Toolbar } from "common";
import useAccess from "features/profile/useAccess";
import useSettings from "features/settings/useSettings";
import useHeaderHeight from "helpers/useHeaderHeight";
import { useSimpleReportDataContext } from "./useSimpleReportData";

export interface SimpleReportHeaderProps {}

const SimpleReportHeader: React.FC<SimpleReportHeaderProps> = ({}) => {
  const { load, loading } = useSimpleReportDataContext();
  const { hasPermissions } = useAccess();
  const [settings, setSettings] = useSettings({ key: "simpleReport" });
  const headerHeight = useHeaderHeight();
  return (
    <>
      <PageHeader title="Napi Létszámok">
        <Toolbar sx={{ gap: 2 }}>
          <IconButton
            onClick={() => {
              load();
            }}
          >
            <Refresh />
          </IconButton>
        </Toolbar>
      </PageHeader>
      {loading && (
        <LinearProgress
          sx={{ position: "fixed", left: 0, top: headerHeight, right: 0 }}
        />
      )}
      {!settings.hide_tip && (
        <Alert
          severity="info"
          icon={<Info />}
          sx={{ mb: 2 }}
          onClose={() => setSettings({ hide_tip: true })}
        >
          Tip: Klikkelj a dátumválsaztóra az időszak kiválasztásához vagy
          léptess a nyilakkal.
        </Alert>
      )}
    </>
  );
};
export default SimpleReportHeader;
