import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import I18n from "i18n";
import React, { useEffect, useState } from "react";
import { useWebSocket } from "./WebSocketProvider";

const WebSocketStatusBanner: React.FC = () => {
  const { status, reconnectAttempts, connect } = useWebSocket();
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("sm"));
  const getMessage = () => {
    switch (status) {
      case "connecting":
        return I18n.t("WebSocket.connecting", {
          reconnectAttempts: reconnectAttempts + 1,
        }); //"Connecting to WebSocket server...";
      case "disconnected":
        return I18n.t("WebSocket.disconnected"); //"Disconnected from WebSocket server. Please reconnect manually.";
      default:
        return I18n.t("WebSocket.unknown"); //"Unknown WebSocket status.";
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 5000);
  }, []);

  if (status === "connected" || !show) return null;

  return (
    <Box
      sx={{
        /*position: "fixed",
        top: 0,
        left: 0,*/
        //width: "100%",
        backgroundColor: "error.main",
        color: "white",
        textAlign: "center",
        py: 1,
        px: 2,
        mx: mdDown ? 0 : -3,
        //zIndex: 1300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography variant="body2">{getMessage()}</Typography>
      {status === "disconnected" && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={connect}
          sx={{ minWidth: 120 }}
        >
          {I18n.t("WebSocket.reconnect")}
        </Button>
      )}
    </Box>
  );
};

export default WebSocketStatusBanner;
