import api from "app/api";
import { Options } from "common";

export interface ProgramItem {
  id: number;
  name: string;
  color: string;
  code: string;
  sku: string;
  duration: number;
  has_options: boolean;
  sort_by: number;
  always_visible: boolean;
  armband: boolean;
  active: boolean;
  seating_layout_ids: number[];
}

export type ProgramOptions = Options<"role" | "status">;

export function getProgramService(scope?: any) {
  return api.getService<ProgramItem, ProgramItem, ProgramOptions>("programs", {
    scope,
    //params goes here
  });
}
