import {
  GridModalFormProps,
  UseFormReturnType,
  Validator,
  globalMessageHandler,
  useForm,
} from "common";
import { useParams } from "common/components/Form/ParamsContext";
import I18n from "i18n";
import { Params } from "react-router-dom";
import {
  SalesPersonItem,
  SalesPersonOptions,
  getSalesPersonService,
} from "./salesPersonApi";

export interface UseSalesPersonFormProps extends GridModalFormProps {}

type RouteParams = {
  sales_person_id?: string;
};

export interface UseSalesPersonFormReturnType
  extends UseFormReturnType<SalesPersonItem, SalesPersonOptions> {
  params?: Readonly<Params<"sales_person_id">>;
}

export default function useSalesPersonForm({
  id,
  afterSave,
  defaultValues,
}: UseSalesPersonFormProps): UseSalesPersonFormReturnType {
  const params = useParams<"sales_person_id">();
  const scope = {};
  const service = getSalesPersonService(scope);

  const form = useForm<SalesPersonItem, SalesPersonOptions>({
    id: params.sales_person_id || id,
    ignoreNavigateAfterCreate: true,
    translationCategory: "SalesPerson",
    rules: {
      name: Validator.rule.string().required(),
    },
    afterSave,
    defaultValues,
    onValidationFailed: () => {
      globalMessageHandler.snack({
        severity: "error",
        message: I18n.t("App.formHasErrors"),
      });
    },
    ...service,
    loadOptions: undefined,
  });

  return { ...form, params };
}
