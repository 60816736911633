import useSettings from "features/settings/useSettings";
import { useWebSocket } from "features/websoket/WebSocketProvider";
import { size } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { KitchenItem, getKitchenData } from "./kitchenApi";

export default function useKitchenList() {
  const _storageStartDate = sessionStorage.getItem(`kitchen.startDate`);
  const _storageEndDate = sessionStorage.getItem(`kitchen.endDate`);
  const [range, setRange] = useState<
    { start?: Moment; end?: Moment } | undefined
  >({
    start: !!_storageStartDate
      ? moment(_storageStartDate).startOf("day")
      : moment().startOf("day"),
    end: !!_storageEndDate
      ? moment(_storageEndDate).endOf("day")
      : moment().endOf("day"),
  });

  const [settings, setSettings] = useSettings({ key: "kitchen" });
  const loaded = useRef(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [kitchenData, setKitchenData] = useState<KitchenItem[]>([]);
  const { subscribe, unsubscribe } = useWebSocket();

  const handleFilter = async (
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null,
    silent?: boolean
  ) => {
    if (!silent) {
      setLoading(true);
    }
    if (startDate) {
      sessionStorage.setItem(`kitchen.startDate`, startDate);
    } else {
      sessionStorage.removeItem(`kitchen.startDate`);
    }
    if (endDate) {
      sessionStorage.setItem(`kitchen.endDate`, endDate);
    } else {
      sessionStorage.removeItem(`kitchen.endDate`);
    }
    const { success, data } = await getKitchenData(
      startDate,
      endDate,
      boatIds,
      programIds
    );
    loaded.current = true;
    if (success) {
      setKitchenData(data || []);
    }
    if (!silent) {
      setLoading(false);
    }
  };
  function load(silent?: boolean) {
    return handleFilter(
      range?.start?.format("YYYY-MM-DD"),
      range?.end?.format("YYYY-MM-DD"),
      settings.boatIds,
      settings.programIds,
      silent
    );
  }
  const isEmpty = loaded.current && size(kitchenData) === 0;

  useEffect(() => {
    load();
  }, [range, settings.boatIds, settings.programIds]);

  const loader = useRef<(silent?: boolean) => void>();
  loader.current = load;
  useEffect(() => {
    if (range?.start && range?.end) {
      subscribe(
        "kitchen",
        range?.start?.format("YYYY-MM-DD"),
        range?.end?.format("YYYY-MM-DD"),
        dates => {
          loader.current && loader.current(true);
        }
      );
    }
  }, [range]);

  useEffect(() => {
    return () => unsubscribe("kitchen");
  }, []);

  return {
    kitchenData,
    loading,
    load,
    handleFilter,
    setRange,
    range,
    loaded,
    isEmpty,
    setSettings,
  };
}
