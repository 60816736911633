import { ReactNode } from "react";
import useSimpleReportData, {
  SimpleReportDataContext,
} from "./useSimpleReportData";

export interface SimpleReportDataProviderProps {
  children?: ReactNode;
}

const SimpleReportDataProvider: React.FC<SimpleReportDataProviderProps> = ({
  children,
}) => {
  const context = useSimpleReportData();

  return (
    <SimpleReportDataContext.Provider value={context}>
      {children}
    </SimpleReportDataContext.Provider>
  );
};
export default SimpleReportDataProvider;
