import { BoatItem } from "features/boats/boatApi";
import { CalendarEventItem } from "features/events/eventApi";
import { Moment } from "moment";
import { VIEWS } from "./calendarApi";

type NormalizedDay = {
  day: Moment;
  events: CalendarEventItem[];
};

type NormalizedStartTime = {
  time: string;
  days: NormalizedDay[];
};

export type NormalizedWeek = {
  firstDayofWeek: Moment;
  days: Moment[];
  startTimes: NormalizedStartTime[];
};

export function normalizeWeeks(
  events: CalendarEventItem[],
  view: VIEWS,
  startOfWeek: "monday" | "sunday" = "monday"
): NormalizedWeek[] {
  const startOfWeekDay = startOfWeek === "monday" ? 1 : 0;

  // Helper function to get start time from event
  const getTimeFromEvent = (event: CalendarEventItem) =>
    event.start.format("HH:mm");

  // Group events by week
  const groupedByWeek = events.reduce((acc, event) => {
    let startOfWeek: Moment;
    if (view === VIEWS.AGENDA) {
      startOfWeek = event.start.clone().startOf("day");
    } else {
      startOfWeek = event.start
        .clone()
        .startOf("week")
        .isoWeekday(startOfWeekDay);
    }
    const weekKey = startOfWeek.format("YYYY-MM-DD");

    let days = [];
    if (view === VIEWS.DAY) {
      days.push(event.start.clone());
    } else if (view === VIEWS.AGENDA) {
      days.push(event.start.clone());
    } else {
      for (let i = 0; i < 7; i++) {
        days.push(startOfWeek.clone().add(i, "days"));
      }
    }

    if (!acc[weekKey]) {
      acc[weekKey] = {
        firstDayofWeek: startOfWeek,
        days,
        events: [] as CalendarEventItem[],
      };
    }

    acc[weekKey].events.push(event);
    return acc;
  }, {} as { [key: string]: { firstDayofWeek: Moment; events: CalendarEventItem[]; days: Moment[] } });

  // Normalize weeks
  const normalizedWeeks: NormalizedWeek[] = Object.values(groupedByWeek).map(
    ({ firstDayofWeek, events, days }) => {
      // Group events by start time
      const groupedByStartTime = events.reduce((acc, event) => {
        const time = getTimeFromEvent(event);
        if (!acc[time]) {
          acc[time] = [];
        }
        acc[time].push(event);
        return acc;
      }, {} as { [key: string]: CalendarEventItem[] });
      let groups = Object.entries(groupedByStartTime);
      groups.sort((a: any, b: any) => {
        return a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0;
      });
      const startTimes = groups.map(([time, events]) => {
        const days: NormalizedDay[] = [];
        if (view === VIEWS.DAY || view === VIEWS.AGENDA) {
          days.push({
            day: events[0].start,
            events: events.map(e => e),
          });
        } else {
          for (let i = 0; i < 7; i++) {
            const day = firstDayofWeek.clone().add(i, "days");
            days.push({
              day,
              events: events.filter(event => event.start.isSame(day, "day")),
            });
          }
        }

        return { time, days };
      });

      return {
        firstDayofWeek,
        startTimes,
        days,
      };
    }
  );

  return normalizedWeeks;
}

export function getAllBoatForWeek(week: NormalizedWeek) {
  let allBoats: { [key: number]: BoatItem } = {};

  week.startTimes.forEach(startTime => {
    startTime.days.forEach(day => {
      day.events.forEach(event => {
        if (!allBoats[event.boat_id]) {
          allBoats[event.boat_id] = event.boat;
        }
      });
    });
  });
  const _allBoats = Object.values(allBoats);
  _allBoats.sort((a, b) => a.sort_by - b.sort_by);
  return _allBoats;
}
