import {
  Alert,
  AlertTitle,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { FormBlock, Toolbar } from "common";
import CheckboxButton from "common/components/Inputs/CheckboxButton/CheckboxButton";
import CounterButton from "common/components/Inputs/CounterButton/CounterButton";
import Price from "common/components/Inputs/Price/Price";
import { BaseInputProps } from "common/components/Inputs/types";
import { FakeEvent } from "common/types";
import { BookingOptionItem } from "features/booking-options/bookingOptionApi";
import {
  BookingItem,
  BookingOptionGroupItem,
  ExtendedEventOption,
} from "features/bookings/bookingApi";
import useAccess from "features/profile/useAccess";
import { useContext } from "react";
import { BookingContext } from "./BookingForm";

export interface BookingOptionSelectorProps
  extends BaseInputProps<BookingOptionItem[]> {
  options?: BookingOptionGroupItem[];
  booking: BookingItem;
  onChange?: (event: FakeEvent<BookingOptionItem[]>) => void;
}
export default function BookingOptionSelector({
  value,
  onChange,
  options,
  booking,
  disabled,
}: BookingOptionSelectorProps) {
  console.log({ disabled });
  const bookingForm = useContext(BookingContext);
  const { hasPermissions } = useAccess();
  const number_of_guests =
    (booking.number_of_adults || 0) + (booking.number_of_kids || 0);

  function handleChangeItem(evt: any, item: ExtendedEventOption) {
    const newCount = evt.target?.value || 0;
    let newValue = value ? value.map(v => Object.assign({}, v)) : null;
    if (!newValue) {
      newValue = [];
    }

    const index = newValue.findIndex(v => v.program_option_id === item.id);

    if (index === -1) {
      newValue.push({
        //...item,
        //id:-1,
        booking_id: booking.id,
        program_option_id: item.id,
        option: item,
        qty: newCount,
        price: item.price ? parseFloat(item.price as any) : 0,
        //price: getOptionPrices(item.id),
      } as any);
    } else {
      newValue[index].qty = newCount;
    }
    onChange && onChange({ target: { value: newValue } });
  }
  function handleChangeItemPrice(evt: any, item: ExtendedEventOption) {
    const newPrice = evt.target?.value || 0;
    let newValue = value ? value.map(v => Object.assign({}, v)) : null;
    if (!newValue) {
      newValue = [];
    }

    const index = newValue.findIndex(v => v.program_option_id === item.id);

    if (index >= 0) {
      newValue[index].price = newPrice;
      onChange && onChange({ target: { value: newValue } });
    }
  }

  return (
    <FormBlock
    /*sx={{
        p: 2,
        border: theme => `solid 1px ${theme.palette.divider}`,
        backgroundColor: "unset",
        borderRadius: theme => `${theme.shape.borderRadius}px`,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}*/
    >
      <Typography variant="h5">Opciók</Typography>
      {options?.map(group => {
        const hasOnlyOneOption = group.items.length === 1;
        let groupStatus = bookingForm?.optionValidator.validateGroup(group);
        let groupOptionCount =
          bookingForm?.optionValidator.getGroupOptionCount(group);
        let groupName = hasOnlyOneOption ? group.items[0].name : group?.name;
        let groupTitle = `${groupName} ${groupOptionCount}/${number_of_guests}`;

        return (
          <List key={group.id} disablePadding>
            <ListItem>
              {groupStatus === "less" && (
                <Alert severity="warning" sx={{ flex: 1 }}>
                  <AlertTitle>{groupTitle}</AlertTitle>
                  {"Nincs mindenkinek kiválasztva."}
                </Alert>
              )}
              {groupStatus === "more" && (
                <Alert severity="error" sx={{ flex: 1 }}>
                  <AlertTitle>{groupTitle}</AlertTitle>
                  {`Több ${groupName} van választva, mint ahány vendég van.`}
                </Alert>
              )}
              {groupStatus === "ok" && number_of_guests > 0 && (
                <Alert severity="success" sx={{ flex: 1 }}>
                  <AlertTitle>{groupTitle}</AlertTitle>
                  {`Mindenkinek jut ${groupName} :).`}
                </Alert>
              )}
              {groupStatus === "ok" && number_of_guests === 0 && (
                <Alert severity="info" sx={{ flex: 1 }}>
                  <AlertTitle>{groupTitle}</AlertTitle>
                  {`Először vegyél fel személyeket.`}
                </Alert>
              )}
              {groupStatus === null &&
                number_of_guests === 0 &&
                group.type === "guest" && (
                  <Alert severity="info" sx={{ flex: 1 }}>
                    <AlertTitle>{groupTitle}</AlertTitle>
                    {`Először vegyél fel személyeket.`}
                  </Alert>
                )}
              {!hasOnlyOneOption && !groupStatus && (
                <Typography>{groupName}</Typography>
              )}
            </ListItem>
            {group?.items?.map(option => {
              const v = value?.find(v => v.program_option_id === option.id);
              const qty = v?.qty || 0;
              const disableEdit =
                disabled ||
                bookingForm?.optionValidator.number_of_guests === 0 ||
                !hasPermissions([
                  "booking_option.editany",
                  "booking_option.editown",
                ]);
              //!hasPermissions("booking.editprice");
              return (
                <ListItem key={option.id} sx={{ gap: 2 }} divider>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: theme =>
                          qty > 0
                            ? theme.palette.primary.main
                            : theme.palette.action.disabled,
                        color: theme =>
                          qty > 0
                            ? theme.palette.getContrastText(
                                theme.palette.primary.main
                              )
                            : theme.palette.getContrastText(
                                theme.palette.action.disabled
                              ),
                      }}
                    >
                      {qty}
                    </Avatar>
                  </ListItemAvatar>
                  <Toolbar sx={{ flexWrap: "wrap", flex: 1 }}>
                    <ListItemText
                      primary={option?.name}
                      secondaryTypographyProps={{ component: "div" }}
                    />
                  </Toolbar>
                  {!!group.has_price && (
                    <Price
                      disabled={
                        disableEdit ||
                        !v?.qty ||
                        !hasPermissions("booking.editprice")
                      }
                      useDelayedKeystrokes={false}
                      placeholder={
                        (option as any)?.price
                          ? String((option as any)?.price)
                          : undefined
                      }
                      //size="small"
                      value={v?.price}
                      onChange={evt => handleChangeItemPrice(evt, option)}
                      sx={{ maxWidth: 120 }}
                    />
                  )}
                  {group.type === "guest" && (
                    <CounterButton
                      value={v?.qty}
                      onChange={evt => handleChangeItem(evt, option)}
                      showCount={false}
                      //size="small"
                      disabled={disableEdit}
                    />
                  )}
                  {group.type === "booking" && (
                    <CheckboxButton
                      value={v?.qty}
                      numeric
                      onChange={evt => {
                        if (!!evt?.target.value) {
                          handleChangeItem(
                            { target: { value: number_of_guests } },
                            option
                          );
                        } else {
                          handleChangeItem({ target: { value: 0 } }, option);
                        }
                      }}
                      disabled={disableEdit}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        );
      })}
    </FormBlock>
  );
}
