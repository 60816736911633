import { Box, useMediaQuery, useTheme } from "@mui/material";
import SimpleReportDataProvider from "./SimpleReportDataProvider";
import SimpleReportHeader from "./SimpleReportHeader";
import SimpleReportProvider from "./SimpleReportProvider";
import SimpleReportFilter from "./components/SimpleReportFilter";
import SimpleReportPivotTable from "./components/SimpleReportPivotTable";
import SimpleReportToolbar from "./components/SimpleReportToolbar";

export interface SimpleReportProps {}

const SimpleReport: React.FC<SimpleReportProps> = ({}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <SimpleReportDataProvider>
      <SimpleReportHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          //ml: isMobile ? -3 : 0,
          //mr: isMobile ? -3 : 0,
          "& .MuiPaper-root": {
            borderRadius: isMobile ? 0 : undefined,
          },
        }}
      >
        <SimpleReportProvider>
          <SimpleReportFilter />
          <SimpleReportToolbar />
          <SimpleReportPivotTable />
        </SimpleReportProvider>
      </Box>
    </SimpleReportDataProvider>
  );
};
export default SimpleReport;
