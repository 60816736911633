import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import I18n from "i18n";
import { useEffect, useRef, useState } from "react";
import { GridState } from "./types";

export interface DataGridQuickSearchProps {
  handleKeywordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeywordClear: Function;
  state: GridState;
}

export default function DataGridQuickSearch({
  handleKeywordChange,
  handleKeywordClear,
  state,
}: DataGridQuickSearchProps) {
  const [value, setValue] = useState(state.keyword);
  const keywordTimeout = useRef<any>();
  //Tracking if searchinput value change triggered by this component;
  const isInternalChange = useRef<boolean>(false);

  function _handleKeywordChange(event: React.ChangeEvent<HTMLInputElement>) {
    isInternalChange.current = true;
    setValue(event.target.value);
  }

  useEffect(() => {
    if (keywordTimeout.current) {
      clearTimeout(keywordTimeout.current);
    }
    //trigger gridstate changes only on internal changes
    if (isInternalChange.current) {
      keywordTimeout.current = setTimeout(() => {
        handleKeywordChange({ target: { value: value } } as any);
      }, 600);
    }
  }, [value]);

  useEffect(() => {
    isInternalChange.current = false;
    setValue(state.keyword);
  }, [state.keyword]);

  return (
    <TextField
      //useDelayedKeystrokes
      fullWidth={false}
      variant="outlined"
      value={value}
      onChange={_handleKeywordChange}
      placeholder={I18n.t("App.search")}
      //className={classes.searchInput}
      sx={{
        flex: 1,
        //borderRadius: "1999px!important",
        color: "white",
        //maxWidth: "250px",
        minWidth: "150px",
        //backgroundColor: alpha(theme.palette.primary.dark, 0.6),
        margin: "0 auto",
        "&:not(.Mui-disabled):hover": {
          //borderColor: `${theme.palette.primary.light}!important`,
        },
        "& .MuiInputBase-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: theme =>
              state.keyword !== ""
                ? `${theme.palette.secondary.main}!important`
                : undefined,
            //borderRadius: "1999px!important",
            borderWidth: 2,
          },
        "& .MuiOutlinedInput-input": {
          padding: "6.5px 5px",
        },
        "&.Mui-focused": {
          "& .MuiOutlinedInput-notchedOutline": {
            //borderColor: `${theme.palette.primary.light}!important`,
          },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{
              marginLeft: -6,
              marginRight: 0,
              marginBottom: -2,
              marginTop: 2,
            }}
          >
            <SearchIcon
              color="action"
              //fontSize={buttonSize}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" style={{ marginRight: -6 }}>
            <IconButton
              title="Clear"
              aria-label="Clear"
              size={"small"}
              style={{
                visibility:
                  state.keyword && state.keyword !== "" ? "visible" : "hidden",
              }}
              onClick={() => handleKeywordClear()}
            >
              <ClearIcon fontSize={"small"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
