import CommentIcon from "@mui/icons-material/Comment";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import {
  Alert,
  alpha,
  Box,
  InputAdornment,
  InputBase,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import { BookingItem } from "../bookingApi";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

function BookingListItem({
  booking,
  overlimit,
  onChangeBooking,
}: {
  booking: BookingItem;
  overlimit?: boolean;
  onChangeBooking?: (booking: BookingItem) => void;
}) {
  const theme = useTheme();
  const { resolveOption, resolveOptions } = useDictionaries();
  const program = resolveOption<ProgramItem>(
    "programs",
    booking?.program_variant?.program_id
  );

  const qty: number =
    (booking.number_of_adults || 0) + (booking.number_of_kids || 0);
  const qty_babies: number = booking.number_of_babies || 0;

  return (
    <ListItem
      divider
      sx={{
        //position: "relative",
        backgroundColor: !!overlimit
          ? theme => alpha(theme.palette.error.main, 0.1)
          : undefined,
        "&.MuiListItem-secondaryAction": {
          pr: overlimit ? 19 : 12,
        },
      }}
    >
      <ListItemText
        disableTypography
        primary={
          <Box
            display="flex"
            flexDirection="row"
            //justifyContent="space-between"
            flexWrap="wrap"
            gap={1}
          >
            <Box display="flex" flexDirection="column">
              <Toolbar sx={{ gap: 1 }}>
                <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                  {booking.name}
                </Typography>
              </Toolbar>
              <Typography variant="body2">{booking.email}</Typography>
              <Toolbar sx={{ gap: 1 }}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "5px",
                    backgroundColor: program?.color,
                  }}
                />
                <Typography>{booking.program_variant?.name}</Typography>
                <Typography>
                  <b>{qty}</b> fő
                  {qty_babies > 0 ? ` + ${qty_babies} baba` : ""}
                </Typography>

                {!!booking.table_numbers && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <EventSeatIcon fontSize="small" />
                    <Typography variant="body1" fontWeight={500}>
                      {booking.table_numbers}
                    </Typography>
                  </Box>
                )}
              </Toolbar>
              <Toolbar>
                <Typography variant="body2" color="text.secondary">
                  {booking.booking_number}
                </Typography>
              </Toolbar>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              gap={1}
              flexWrap="wrap"
              alignItems="center"
              flex={1}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  //alignItems: "center",
                  gap: 1,
                  flex: 1,
                  color: theme => theme.palette.info.main,
                }}
              >
                <InputBase
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        color: theme => theme.palette.info.main,
                        alignItems: "flex-end",
                      }}
                    >
                      <CommentIcon fontSize="small" />
                    </InputAdornment>
                  }
                  fullWidth
                  placeholder="Megjegyzés"
                  value={booking.description || ""}
                  onChange={e =>
                    onChangeBooking &&
                    onChangeBooking({
                      ...booking,
                      description: e.target.value,
                    })
                  }
                  minRows={2}
                  multiline
                  sx={{
                    p: 0.5,
                    borderRadius: 1,
                    backgroundColor: alpha(theme.palette.info.main, 0.1),
                  }}
                />
              </Box>
              {overlimit ? (
                <Alert variant="outlined" severity="error">
                  {(booking as any).smallestLimit?.type === "program"
                    ? "Program"
                    : (booking as any).smallestLimit?.type === "boat"
                    ? "Boat"
                    : (booking as any).smallestLimit?.name || "Limit túllépés"}
                </Alert>
              ) : (
                <Alert severity="success">Mozgatható</Alert>
              )}
            </Box>
          </Box>
        }
      />
    </ListItem>
  );
}

export default BookingListItem;
