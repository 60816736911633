import { useAppSelector } from "app/hooks";
import { sessionId } from "app/session"; // Importáltuk a session ID-t
import settings from "app/settings";
import { selectAccessToken } from "features/identity/identitySlice";
import { selectProfile } from "features/profile/profileSlice";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

interface WebSocketContextType {
  subscribe: (
    id: string,
    from: string,
    to: string,
    callback: (dates: string[]) => void
  ) => () => void;
  unsubscribe: (id: string) => void;
  status: "connecting" | "connected" | "disconnected";
  reconnectAttempts: number;
  connect: () => void;
}

const CONNECT_TIMEOUT = 5000; // 2 seconds timeout
const MAX_RECONNECT_ATTEMPTS = 5;
const WebSocketContext = createContext<WebSocketContextType | null>(null);

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
}) => {
  const socketRef = useRef<WebSocket | null>(null);
  const [status, setStatus] = useState<
    "connecting" | "connected" | "disconnected"
  >("connecting");
  const [reconnectAttempts, setReconnectAttempts] = useState<number>(0);
  const profile = useAppSelector(selectProfile);
  const accessToken = useAppSelector(selectAccessToken);
  const subscriptionsRef = useRef<
    Map<
      string,
      { from: string; to: string; callback: (dates: string[]) => void }
    >
  >(new Map());
  const queue = useRef<
    { action: string; id: string; from?: string; to?: string }[]
  >([]);

  const connectWebSocket = () => {
    setReconnectAttempts(prev => {
      if (prev === 0) {
        attemptReconnect(0);
      }
      return 0;
    });
  };

  const attemptReconnect = (attempt: number) => {
    if (attempt >= MAX_RECONNECT_ATTEMPTS) {
      console.warn("Max reconnect attempts reached. Stopping auto-reconnect.");
      setStatus("disconnected");
      return;
    }

    if (socketRef.current) {
      socketRef.current.close();
    }
    setStatus("connecting");
    const ws = new WebSocket(
      `${settings.websocketHost}?token=${accessToken}&session=${sessionId}`
    );

    const timeout = setTimeout(() => {
      if (ws.readyState !== WebSocket.OPEN) {
        console.warn("WebSocket connection timeout. Closing connection...");
        ws.close();
      }
    }, CONNECT_TIMEOUT);

    ws.onopen = () => {
      console.log("Connected to WebSocket server");
      clearTimeout(timeout);
      socketRef.current = ws;
      setStatus("connected");
      setReconnectAttempts(0);
    };

    ws.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      console.log("Message received", data);
      console.log("Current subscriptions:", subscriptionsRef.current);
      if (data.id && data.action === "refresh") {
        const subscription = subscriptionsRef.current.get(data.id);
        if (subscription) subscription.callback([]);
      }
    };

    ws.onclose = () => {
      clearTimeout(timeout);
      console.warn("WebSocket closed, attempting to reconnect...");
      const newAttempts = attempt + 1;
      setReconnectAttempts(newAttempts);
      if (newAttempts < MAX_RECONNECT_ATTEMPTS) {
        attemptReconnect(newAttempts);
      } else {
        setStatus("disconnected");
      }
    };
  };

  useEffect(() => {
    attemptReconnect(0);
    return () => socketRef.current?.close();
  }, []);

  useEffect(() => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      console.log("Processing queued messages", { socket: socketRef.current });
      queue.current.forEach(({ action, id, from, to }) => {
        socketRef.current?.send(
          JSON.stringify({ action, user_id: profile.id, id, from, to })
        );
      });
      queue.current = [];
    }
  }, [status]);

  const subscribe = (
    id: string,
    from: string,
    to: string,
    callback: (dates: string[]) => void
  ): (() => void) => {
    const existingSubscription = subscriptionsRef.current.get(id);
    if (
      existingSubscription &&
      existingSubscription.from === from &&
      existingSubscription.to === to
    ) {
      console.log("Skipping duplicate subscription for", id);
      return () => unsubscribe(id);
    }

    subscriptionsRef.current.set(id, { from, to, callback });

    console.log("subscribe", { socket: socketRef.current });

    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.send(
        JSON.stringify({
          action: "subscribe",
          user_id: profile.id,
          id,
          from,
          to,
        })
      );
    } else {
      queue.current.push({ action: "subscribe", id, from, to });
    }

    return () => unsubscribe(id);
  };

  const unsubscribe = (id: string): void => {
    subscriptionsRef.current.delete(id);

    if (socketRef.current?.readyState === WebSocket.OPEN) {
      socketRef.current.send(
        JSON.stringify({ action: "unsubscribe", user_id: profile.id, id })
      );
    } else {
      queue.current.push({ action: "unsubscribe", id });
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        subscribe,
        unsubscribe,
        status,
        reconnectAttempts,
        connect: connectWebSocket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
