import {
  DialogProps,
  Dialog as OriginalDialog,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Draggable from "react-draggable";

interface DraggableDialogProps extends DialogProps {
  PaperComponent?: React.ComponentType<any>;
}

const DraggableDialog = React.forwardRef<HTMLDivElement, DraggableDialogProps>(
  function DraggableDialog(props, ref) {
    const { PaperComponent, ...rest } = props;
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    if (mdDown) {
      return <OriginalDialog PaperComponent={PaperComponent} {...rest} />;
    }
    return (
      <OriginalDialog
        ref={ref}
        PaperComponent={
          PaperComponent ||
          ((paperProps: any) => (
            <Draggable
              //handle=".MuiDialogTitle-root"
              handle=".MuiPageHeader, .MuiDialogTitle-root"
              cancel={'[class*="MuiDialogContent-root"]'}
            >
              <Paper {...paperProps} />
            </Draggable>
          ))
        }
        {...rest}
      />
    );
  }
);

export default DraggableDialog;
