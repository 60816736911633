import { DialogActions, DialogContent, IconButtonProps } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { Button, ButtonProps, Checkbox, VIEWS } from "common";
import CalendarPro from "common/components/CalendarPro";
import { BookingItem } from "features/bookings/bookingApi";
import { selectDictionary } from "features/dictionaries/dictionarySlice";
import CalendarToolbar from "features/events/Calendar/CalendarToolbar";
import CalendarCellHeaderSmall from "features/events/Calendar/cells/CalendarCellHeaderSmall";
import StartBoatCell from "features/events/Calendar/cells/StartBoatCell";
import useEventCalendar from "features/events/Calendar/useEventCalendar";
import {
  EventItem,
  getClosestEvents,
  prepareCalendarItems,
} from "features/events/eventApi";
import I18n from "i18n";
import moment from "moment";
import { useEffect, useState } from "react";

type AttachBookingButtonInnerBaseProps = {
  onSelect: (event: EventItem | null) => void;
  booking?: BookingItem;
  onClose: () => void;
};

type BookingIconButtonProps = {
  buttonType: "icon";
} & AttachBookingButtonInnerBaseProps &
  Omit<IconButtonProps, "onSelect">;

type AttachBookingButtonInnerButtonProps = {
  buttonType: "button";
} & AttachBookingButtonInnerBaseProps &
  Omit<ButtonProps, "onSelect">;

type AttachBookingButtonInnerProps =
  | BookingIconButtonProps
  | AttachBookingButtonInnerButtonProps;

function parseItems(items: EventItem[]) {
  let _items: any[] = [];
  items.forEach(row => {
    _items.push({
      ...row,
      title: row?.program?.name,
      start: moment(row?.start_time),
      end: moment(row?.start_time).add(1, "hour"),
    });
  });

  return _items;
}

const AttachBookingButtonInner: React.FC<AttachBookingButtonInnerProps> = ({
  onSelect,
  buttonType,
  booking,
  ...iconButtonProps
}) => {
  const program_id = booking?.program_variant?.program_id;
  const [allPrograms, setAllPrograms] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventItem | null>(null);

  const { items, loadEvents, setItems, cal } = useEventCalendar({
    programIds: allPrograms ? (undefined as any) : [program_id],
    filterKey: "attachbooking",
  });

  const dictionaries = useAppSelector(selectDictionary);

  const handleAttach = () => {
    if (selectedEvent) {
      onSelect(selectedEvent);
    }
  };
  const [loading, setLoading] = useState(false);
  const loadInitialData = async () => {
    if (!program_id) {
      return;
    }
    setLoading(true);
    try {
      const fetchedEvents = await getClosestEvents(
        allPrograms ? (undefined as any) : program_id,
        booking?.planned_datetime || booking?.event?.start_time || ""
      );
      setItems(prepareCalendarItems(fetchedEvents));
      if (fetchedEvents.length > 0) {
        setSelectedEvent(fetchedEvents[0]);
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (program_id) {
      loadInitialData();
    }
  }, []);
  useEffect(() => {
    if (cal.current) {
      cal.current.refresh();
    }
  }, [allPrograms]);
  return (
    <>
      <DialogContent>
        <CalendarPro
          calRef={cal}
          //key={filter?.from}
          navigateDelay={300}
          loadEvents={loadEvents}
          ToolbarComponent={CalendarToolbar}
          /*toolbarStyle={{
          position: "sticky",
          zIndex: 1000,
          top: 16,
          marginTop: "-8px",
        }}*/

          view={VIEWS.WEEK}
          events={items}
          date={
            selectedEvent?.start_time
              ? moment(selectedEvent?.start_time)
              : moment()
          }
          containerStyle={{
            flexDirection: "column",
          }}
          CellComponent={StartBoatCell}
          CellHeaderComponent={CalendarCellHeaderSmall}
          isSelectedFunc={cell => cell.id === selectedEvent?.id}
          onEventPress={cell => {
            setSelectedEvent(cell);
          }}
          buttons={
            <Checkbox
              label="Minden program"
              value={allPrograms}
              onChange={(e: any) => setAllPrograms(e.target.value as any)}
            />
          }
        />

        <DialogActions sx={{}}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAttach}
            disabled={!selectedEvent}
            fullWidth
          >
            {I18n.t("Booking.eventAttachBookingButton", {
              datetime: moment(selectedEvent?.start_time).format("L LT"),
            })}
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default AttachBookingButtonInner;
