import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Autocomplete } from "common";
import { UserItem } from "features/users/userApi";
import React, { useEffect, useState } from "react";
import { BookingFilter } from "./useBookingList";

// Props for the CreatedByFilter component.
interface CreatedByFilterProps {
  filter?: BookingFilter;
  onChange: (filter: BookingFilter) => void;
  users: UserItem[];
  title?: string;
}

const CreatedByFilter: React.FC<CreatedByFilterProps> = ({
  filter,
  onChange,
  users,
  title,
}) => {
  // Local state to control the dialog visibility.
  const [open, setOpen] = useState<boolean>(false);

  // Local state to keep track of the selected users.
  const [selectedUsers, setSelectedUsers] = useState<number[]>(
    filter?.created_by || []
  );

  // Update local state when the filter prop changes.
  useEffect(() => {
    if (filter?.created_by) {
      setSelectedUsers(filter.created_by);
    }
  }, [filter]);

  // Opens the dialog.
  const handleOpen = (evt: any) => {
    evt.stopPropagation();
    setOpen(true);
  };

  // Closes the dialog and applies the filter.
  const handleClose = () => {
    setOpen(false);
    if (JSON.stringify(filter?.created_by) !== JSON.stringify(selectedUsers)) {
      onChange({ created_by: selectedUsers });
    }
  };

  return (
    <>
      <IconButton
        color={selectedUsers?.length > 0 ? "info" : undefined}
        size="small"
        onClick={handleOpen}
        sx={{ position: "absolute", right: 0, top: 10 }}
      >
        {selectedUsers?.length > 0 ? (
          <FilterAltIcon />
        ) : (
          <FilterAltOutlinedIcon />
        )}
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={users}
            multiple
            onChange={(evt: any) => setSelectedUsers(evt.target.value)}
            value={selectedUsers}
            color="secondary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreatedByFilter;
