import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import DataGridQuickSearch, {
  DataGridQuickSearchProps,
} from "./DataGridQuickSearch";
import DataGridVisibleColumns, {
  DataGridVisibleColumnsProps,
} from "./DataGridVisibleColumns";
import { GridColumn, GridRowParams, GridToolbarItem } from "./types";

export interface DataGridToolbarProps
  extends DataGridQuickSearchProps,
    DataGridVisibleColumnsProps {
  //Data functions
  load: Function;
  columns: GridColumn[];
  handleToggleTrash: (event: unknown) => void;
  handleCreate: (event: unknown, item: any) => void;
  //Permission Handling
  canCreate?: boolean;
  canHandleTrash?: boolean;
  canBatchActions?: boolean;
  toolbarItems: GridToolbarItem[];
  renderCreate?: Function;
  renderTrash?: Function;
  renderRefresh?: Function;
  renderSearch?: Function;
  renderFilter?: Function;
  renderFilters?: Function;
  renderTitle?: Function;
  renderToolbarLeftItems?: (props: {
    load: () => void;
    columns: GridColumn[];
    handleCreate: (event: unknown, item: any) => void;
  }) => ReactNode;
  renderCreateItem?: (props: {
    load: () => void;
    columns: GridColumn[];
    handleCreate: (event: unknown, item: any) => void;
  }) => ReactNode;
  icon?: JSX.Element;
  title?: string;
  editUrl?: string;
  excelUrl?: string;
  canEdit?: boolean;
  canDelete?: boolean;
  canEditRow?: (params: GridRowParams) => boolean;
  canDeleteRow?: (params: GridRowParams) => boolean;
  restoreSelectedItems?: Function;
  deleteSelectedItems?: Function;
}

export default function DataGridToolbar({
  load,
  handleKeywordChange,
  handleKeywordClear,
  handleToggleTrash,
  handleCreate,
  state,
  canHandleTrash,
  toolbarItems,
  icon,
  title,
  editUrl,
  canCreate,
  canEditRow,
  canDeleteRow,
  restoreSelectedItems,
  deleteSelectedItems,
  handleToggleColumn,
  excelUrl,
  columns,
  renderToolbarLeftItems,
  renderCreateItem,
}: DataGridToolbarProps) {
  return (
    <Box
      sx={{
        flex: 1,
        flexWrap: "wrap",
        gap: 1,
        display: "flex",
        flexDirection: "row",
        px: 1,
        pt: 1,
      }}
    >
      {!!icon && (
        <Box
          sx={{
            p: 0.5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {icon}
        </Box>
      )}
      {!!title && toolbarItems.includes("title") && (
        <Typography variant="button" sx={{ marginLeft: 1 }}>
          {title}
        </Typography>
      )}
      {canCreate &&
        renderCreateItem &&
        renderCreateItem({ load: load as any, columns, handleCreate })}
      {canCreate &&
        !renderCreateItem &&
        toolbarItems.includes("create") &&
        (editUrl ? (
          <IconButton
            //@ts-ignore
            component={Link}
            to={`${editUrl.replace(":id", "create")}`}
          >
            <AddIcon />
          </IconButton>
        ) : (
          <IconButton onClick={evt => handleCreate(evt, {})}>
            <AddIcon />
          </IconButton>
        ))}
      {!!renderToolbarLeftItems &&
        renderToolbarLeftItems({ load: load as any, columns, handleCreate })}

      {canHandleTrash && toolbarItems.includes("trash") && (
        <Tooltip
          title={state.trash ? "Vissza a lista nézetre" : "Lomtár megtekintése"}
        >
          <IconButton
            onClick={handleToggleTrash}
            color={state.trash ? "primary" : "default"}
          >
            {state.trash ? <DeleteIcon /> : <DeleteOutlineIcon />}
          </IconButton>
        </Tooltip>
      )}
      {toolbarItems.includes("refresh") && (
        <Tooltip title="Frissítés">
          <IconButton onClick={() => load()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
      {toolbarItems.includes("excel") && !!excelUrl && (
        <Tooltip title="Excel export">
          <IconButton href={excelUrl} download>
            <svg
              style={{ width: 20, height: 20, fill: "currentColor" }}
              viewBox="0 0 24 24"
            >
              <path d="M 12 3 L 2 5 L 2 19 L 12 21 L 12 3 z M 14 5 L 14 7 L 16 7 L 16 9 L 14 9 L 14 11 L 16 11 L 16 13 L 14 13 L 14 15 L 16 15 L 16 17 L 14 17 L 14 19 L 21 19 C 21.552 19 22 18.552 22 18 L 22 6 C 22 5.448 21.552 5 21 5 L 14 5 z M 18 7 L 20 7 L 20 9 L 18 9 L 18 7 z M 4.1757812 8.296875 L 5.953125 8.296875 L 6.8769531 10.511719 C 6.9519531 10.692719 7.0084063 10.902625 7.0664062 11.140625 L 7.0917969 11.140625 C 7.1247969 10.997625 7.1919688 10.779141 7.2929688 10.494141 L 8.3222656 8.296875 L 9.9433594 8.296875 L 8.0078125 11.966797 L 10 15.703125 L 8.2714844 15.703125 L 7.1582031 13.289062 C 7.1162031 13.204062 7.0663906 13.032922 7.0253906 12.794922 L 7.0097656 12.794922 C 6.9847656 12.908922 6.934375 13.079594 6.859375 13.308594 L 5.7363281 15.703125 L 4 15.703125 L 6.0605469 11.996094 L 4.1757812 8.296875 z M 18 11 L 20 11 L 20 13 L 18 13 L 18 11 z M 18 15 L 20 15 L 20 17 L 18 17 L 18 15 z" />
            </svg>
          </IconButton>
        </Tooltip>
      )}
      <DataGridVisibleColumns
        state={state}
        columns={columns}
        handleToggleColumn={handleToggleColumn}
      />
      {toolbarItems.includes("search") && (
        <DataGridQuickSearch
          handleKeywordChange={handleKeywordChange}
          handleKeywordClear={handleKeywordClear}
          state={state}
        />
      )}
    </Box>
  );
}
