import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DialogProps,
  Paper,
  PaperProps,
  Table,
  TableContainer,
  TablePagination,
  TableRowProps,
} from "@mui/material";
import { ReactNode, useRef } from "react";
import DataGridBody from "./DataGridBody";
import DataGridHeader, { BulkAction } from "./DataGridHeader";
import DataGridModalForm from "./DataGridModalForm";
import DataGridToolbar from "./DataGridToolbar";
import { DataGridVisibleColumnsProps } from "./DataGridVisibleColumns";
import {
  GridColumn,
  GridModalFormProps,
  GridRowParams,
  GridToolbarItem,
  ModalApi,
  UseDataGridProps,
  UseDataGridReturnType,
} from "./types";

export interface DataGridBaseBaseProps<T = any> {
  renderModalForm?: (props: GridModalFormProps) => ReactNode;
  editUrl?: string;
  excelUrl?: string;
  showToolbar?: boolean;
  showFooter?: boolean;
  title?: string;
  id: string;
  icon?: JSX.Element;
  rowSx?: (item: T) => TableRowProps["sx"] | undefined;
  toolbarItems?: GridToolbarItem[];
  canEditRow?: (params: GridRowParams) => boolean;
  canDeleteRow?: (params: GridRowParams) => boolean;
  canOrderRows?: boolean;
  sortable?: boolean;
  modalProps?: Partial<DialogProps>;
  paperProps?: PaperProps;
  renderToolbarLeftItems?: (props: {
    load: () => void;
    columns: GridColumn[];
    handleCreate: (event: unknown, item: any) => void;
  }) => ReactNode;
  renderCreateItem?: (props: {
    load: () => void;
    columns: GridColumn[];
    handleCreate: (event: unknown, item: any) => void;
  }) => ReactNode;
  bulkActions?: BulkAction<T>[];
}

export interface DataGridBaseProps
  extends UseDataGridReturnType,
    Omit<UseDataGridProps, "deleteItem" | "restoreItem" | "createItem">,
    DataGridBaseBaseProps,
    DataGridVisibleColumnsProps {}

export default function DataGridBase({
  renderModalForm,
  editUrl,
  state,
  columns,
  hasAction,
  handleStart,
  handleEnd,
  handleMove,
  handlePageChange,
  handleRowsPerPageChange,
  handleSortChange,
  handleKeywordChange,
  handleKeywordClear,
  handleToggleSelectAll,
  handleToggleSelectRow,
  showToolbar = true,
  showFooter = true,
  title,
  icon,
  load,
  toolbarItems = ["title", "create", "refresh", "search", "trash", "excel"],
  canView = true,
  canDelete,
  canEdit,
  canEditRow,
  canDeleteRow,
  getCellValue,
  rowOrderProps,
  canOrderRows,
  orderRowsField,
  sortable = true,
  deleteItem,
  restoreItem,
  restoreSelectedItems,
  deleteSelectedItems,
  excelUrl,
  modalProps,
  rowSx,
  paperProps,
  rowActions,
  bulkActions,
  ...props
}: DataGridBaseProps) {
  const modalApi = useRef<ModalApi>();
  return (
    <>
      <TableContainer
        component={Paper}
        {...paperProps}
        sx={{
          "& table": { minWidth: "800px", width: "100%" },
          overflowX: "auto",
        }}
      >
        {showToolbar && (
          <DataGridToolbar
            columns={columns}
            toolbarItems={toolbarItems}
            load={load}
            icon={icon}
            canCreate={props.canCreate}
            canEdit={canEdit}
            canDelete={canDelete}
            canEditRow={canEditRow}
            canDeleteRow={canDeleteRow}
            canHandleTrash={props.canHandleTrash}
            restoreSelectedItems={restoreSelectedItems}
            deleteSelectedItems={deleteSelectedItems}
            canBatchActions={props.canBatchActions}
            handleToggleTrash={props.handleToggleTrash}
            handleCreate={(event: unknown, item: any) =>
              modalApi.current?.open({ id: "create", item })
            }
            handleKeywordChange={handleKeywordChange}
            handleKeywordClear={handleKeywordClear}
            handleToggleColumn={props.handleToggleColumn}
            state={state}
            title={title}
            editUrl={editUrl}
            excelUrl={excelUrl}
            renderToolbarLeftItems={props.renderToolbarLeftItems}
            renderCreateItem={props.renderCreateItem}
          />
        )}
        <DndContext
          sensors={rowOrderProps.sensors}
          collisionDetection={closestCenter}
          onDragEnd={rowOrderProps.handleDragEnd}
          //onDragStart={handleDragStart}
        >
          <SortableContext
            items={state.data as any}
            disabled={!canOrderRows}
            strategy={verticalListSortingStrategy}
          >
            <Table
              //@ts-ignore
              //style={{ --col-lastName-size:150; }}
              sx={{
                "& tr": {
                  //display: "flex",
                  //alignItems: "stretch",
                },
                "& th": {
                  //display: "flex",
                  //alignItems: "stretch",
                  //minWidth: "max(calc(var(--col-lastName-size) * 1px), 80px)",
                  //width: "calc(var(--col-lastName-size) * 1px)",
                },
                "& td": {
                  //flex: 1,
                  //background: "yellow",
                  //display: "inline-block" /* to keep IE happy */,
                },
              }}
            >
              <DataGridHeader
                id={props.id}
                state={state}
                columns={columns}
                hasAction={hasAction}
                handleStart={handleStart}
                handleEnd={handleEnd}
                handleMove={handleMove}
                handleSortChange={handleSortChange}
                canBatchActions={props.canBatchActions}
                handleToggleSelectAll={handleToggleSelectAll}
                canOrderRows={canOrderRows}
                sortable={sortable}
                restoreSelectedItems={restoreSelectedItems}
                deleteSelectedItems={deleteSelectedItems}
                bulkActions={bulkActions}
                load={load}
              />

              <DataGridBody
                state={state}
                columns={columns}
                hasAction={hasAction}
                canView={canView}
                canEdit={canEdit}
                canDelete={canDelete}
                canEditRow={canEditRow}
                canDeleteRow={canDeleteRow}
                canBatchActions={props.canBatchActions}
                handleToggleSelectRow={handleToggleSelectRow}
                onEditClick={(id, item) => modalApi.current?.open({ id, item })}
                editUrl={editUrl}
                getCellValue={getCellValue}
                rowOrderProps={rowOrderProps}
                canOrderRows={canOrderRows}
                deleteItem={deleteItem}
                restoreItem={restoreItem}
                rowSx={rowSx}
                rowActions={rowActions}
                refresh={load}
              />
            </Table>
          </SortableContext>
        </DndContext>
        {showFooter && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 500]}
            component="div"
            page={state.current_page - 1}
            rowsPerPage={state.per_page}
            //count={Math.ceil(state.total / state.per_page)}
            count={state.total}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </TableContainer>
      {!!renderModalForm && (
        <DataGridModalForm
          renderModalForm={renderModalForm}
          modalProps={modalProps}
          items={state.data}
          apiRef={modalApi}
          afterSave={(newItem, isCreate) => {
            modalApi.current?.close(load);
          }}
        />
      )}
    </>
  );
}
