import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";

import {
  Alert,
  Avatar,
  Box,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  LinearProgress,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import { COMPATIBILE_PROGRAMS } from "app/constants";
import { useAppSelector } from "app/hooks";
import {
  Button,
  Checkbox,
  globalMessageHandler,
  Spacer,
  Toolbar,
} from "common";
import CalendarPro, { VIEWS } from "common/components/CalendarPro";
import useDictionaries from "features/dictionaries/useDictionaries";
import CalendarToolbar from "features/events/Calendar/CalendarToolbar";
import CalendarCellHeaderSmall from "features/events/Calendar/cells/CalendarCellHeaderSmall";
import StartBoatCell from "features/events/Calendar/cells/StartBoatCell";
import useEventCalendar from "features/events/Calendar/useEventCalendar";
import {
  CalendarEventItem,
  checkBookings,
  moveBookings,
  MoveBookingsState,
} from "features/events/eventApi";
import { useEventCalculator } from "features/events/useEventCalculator";
import { selectProfile } from "features/profile/profileSlice";
import { ProgramItem } from "features/programs/programApi";
import { size } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BookingItem } from "../bookingApi";
import BookingListItem from "./BookingListItem";

//we have to prepend  the old event start_time and program variant name and new event start_time an program variant name to the booking descriptions. so the description should contains from what event and programvariont to what event and program variant the booking is moved program variant name and start_time should be resolved from dictionaries in hungarian
// Prepend old event info and new event info to each booking's description.
// The final description will read for example:
// "Áthelyezve: EredetiProgram (2025. 02. 15. 14:00) → ÚjProgram (2025. 02. 20. 16:00): {original description}"
// Program variant name and start time are resolved from dictionaries (in Hungarian).
// Assumes each booking has an `old_event` property containing the original event details.
function prefillDescriptions(data: MoveBookingsState, username: string) {
  if (!data.event) return data; // no new event reference available

  const newEvent = data.event;
  const newProgram = newEvent.program;
  const newStartTime = newEvent.start_time
    ? moment(newEvent.start_time).format("L LT")
    : "ismeretlen";

  const formatBooking = (b: BookingItem) => {
    // Assume that the original event info is stored on the booking as `old_event`
    const oldEvent = b.event;
    const oldVariant = b.program_variant;
    const oldProgram = oldEvent?.program;
    const oldStartTime =
      oldEvent && oldEvent.start_time
        ? moment(oldEvent.start_time).format("L LT")
        : "ismeretlen";
    const name =
      !!oldVariant?.name &&
      !!oldProgram?.name &&
      oldVariant?.name === oldProgram?.name
        ? oldProgram?.name
        : `${oldProgram?.name} (${oldVariant?.name})`;
    return {
      ...b,
      description: `${
        !!b.description ? `${b.description}\n` : ""
      }Áthelyezte ${username}: ${name} (${oldStartTime}) → ${
        newProgram.name
      } (${newStartTime})`,
    };
  };

  return {
    ...data,
    movable: data.movable.map(formatBooking),
    overlimit: data.overlimit.map(formatBooking),
  };
}

export interface MoveBookingListModalInnerProps extends DialogProps {
  eventId?: number;
  bookingIds?: number[];
  programId?: number;
  afterMove?: () => void;
}

function MoveBookingListModalInner({
  eventId: _eventId,
  bookingIds,
  programId,
  afterMove,
  ...props
}: MoveBookingListModalInnerProps) {
  const profile = useAppSelector(selectProfile);
  const username = `${profile.last_name} ${profile.first_name}`;
  const [state, setState] = useState<MoveBookingsState>({
    movable: [],
    overlimit: [],
  });
  const [allPrograms, setAllPrograms] = useState(false);
  const loaded = useRef(false);
  const { resolveOption } = useDictionaries();
  const program = resolveOption<ProgramItem>(
    "programs",
    state.event?.program_id
  );
  const [selectedEvent, setSelectedEvent] = useState<CalendarEventItem>();
  const boat = resolveOption<ProgramItem>("boats", state.event?.boat_id);
  const startTime = state.event?.start_time
    ? moment(state.event?.start_time).format("L LT")
    : "";
  const [eventId, setEventId] = useState<number | undefined>(_eventId);
  const [checkBookingLoading, setCheckBookingLoading] = useState(false);
  const [moveBookingLoading, setMoveBookingLoading] = useState<
    "force" | "normal"
  >();
  const programIds = useMemo(() => {
    return programId && COMPATIBILE_PROGRAMS[programId as any]
      ? COMPATIBILE_PROGRAMS[programId]
      : [programId];
  }, [programId]);

  const { getEventData, getBoatData } = useEventCalculator();
  const eventData = state?.event ? getEventData(state?.event) : undefined;

  const { items, loadEvents, setItems, cal } = useEventCalendar({
    programIds: allPrograms ? undefined : programIds,
    filterKey: "movebooking",
    filterStorageType: "none",
  });

  // Helper function to update a booking in a given list by id
  const updateBooking = (
    list: "movable" | "overlimit",
    updatedBooking: BookingItem
  ) => {
    setState(prevState => ({
      ...prevState,
      [list]: prevState[list].map(b =>
        b.id === updatedBooking.id ? updatedBooking : b
      ),
    }));
  };

  function moveBookingsAsync(force?: boolean) {
    globalMessageHandler.confirm(
      {
        title: `Átregisztrálás megerősítése`,
        message: programLimit?.closed
          ? `Biztosan átregisztrálod a foglalásokat a jelenlegi a(z) ${program.name} eseményre amely ${startTime}-kor indul, annak ellenére, hogy le van zárva?`
          : `Biztosan átregisztrálod a foglalásokat a jelenlegi a(z) ${program.name} eseményre, amely ${startTime}-kor indul?`,
        severity: programLimit?.closed ? "warning" : undefined,
      },
      async () => {
        setMoveBookingLoading(force ? "force" : "normal");
        const { data, success, error } = await moveBookings(
          eventId,
          [...state.movable, ...state.overlimit],
          force
        );

        setMoveBookingLoading(undefined);
        if (success) {
          //afterChange && afterChange(data as any);
          setState(prefillDescriptions(data, username));

          globalMessageHandler.snack({
            message: "Sikeres átregisztricó.",
          });
          afterMove && afterMove();
        } else {
          //console.log({ data, error });
          if (error) {
            setState(error);
          }
          /*globalMessageHandler.alert({
            message: `Az alábbi foglalások nem lettek rögzítve: ${error.overlimit
              .map((b: any) => b.name)
              .join(", ")}`,
          });*/
        }
      }
    );
  }
  async function checkBookingsAsync() {
    setCheckBookingLoading(true);
    const { data, success, error } = await checkBookings(eventId, bookingIds);
    loaded.current = true;
    setCheckBookingLoading(false);
    if (success) {
      //afterChange && afterChange(data as any);
      setState(prefillDescriptions(data, username));
    }
  }

  //const selectedEvariantClosed = isVariantClosed(, selectedEvent)
  const programLimit: any = eventData?.programlimits.find(
    p => p.program_id === programId && p.type === "program"
  );

  const moveIsDisabled = size(state.movable) === 0 && !programLimit?.closed;

  const date = useMemo(() => {
    return moment();
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.open && eventId) {
      checkBookingsAsync();
    }
  }, [eventId, bookingIds, props.open]);
  useEffect(() => {
    if (_eventId !== eventId) {
      setEventId(_eventId);
    }
  }, [_eventId]);

  useEffect(() => {
    if (programIds?.length > 0 && props.open && !_eventId) {
      loadEvents({
        start: moment().startOf("week"),
        end: moment().endOf("week"),
      });
    }
  }, [programIds, props.open]);
  useEffect(() => {
    if (cal.current && !(programIds?.length > 0)) {
      cal.current.refresh();
    }
  }, [allPrograms]);

  return (
    <>
      <DialogTitle sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
          }}
        >
          <Typography variant="h6">
            Foglalások áthelyezése: {program?.name || "válassz eseményt"}
          </Typography>
          {/*renderPrograms()*/}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ flex: 1 }} />
            <IconButton
              color="inherit"
              onClick={() => checkBookingsAsync()}
              aria-label="refresh"
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() =>
                props.onClose && props.onClose({}, "backdropClick")
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        {!!state.event && (
          <Toolbar sx={{ gap: 2 }}>
            <Avatar
              sx={{
                backgroundColor: programLimit?.closed
                  ? theme => theme.palette.grey[500]
                  : program?.color,
              }}
            >
              <Typography
                sx={{
                  color: program?.color
                    ? theme => theme.palette.getContrastText(program?.color)
                    : undefined,
                }}
              >
                {program?.code}
              </Typography>
            </Avatar>
            <Box>
              <Typography variant="h6">{startTime}</Typography>
              <Typography>{boat?.name}</Typography>
            </Box>
          </Toolbar>
        )}
        {checkBookingLoading && (
          <LinearProgress
            sx={{ position: "absolute", left: 0, bottom: 0, right: 0 }}
          />
        )}
      </DialogTitle>
      {!!programLimit?.closed && (
        <Alert square severity="warning">
          A program le van zárva
        </Alert>
      )}
      {!!state.event && (
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          {size(state.movable) === 0 && loaded.current && (
            <Alert severity="error" sx={{ flex: 1 }}>
              Egyetlen foglalás sem helyezhető át
            </Alert>
          )}
          <List disablePadding dense>
            {size(state.movable) > 0 && (
              <>
                <ListSubheader>
                  Áthelyezhető foglalások ({size(state.movable)})
                </ListSubheader>
                {state.movable?.map(booking => (
                  <BookingListItem
                    key={booking.id}
                    booking={booking}
                    onChangeBooking={(b: BookingItem) => {
                      updateBooking("movable", b);
                    }}
                  />
                ))}
              </>
            )}
            {size(state.overlimit) > 0 && (
              <>
                <ListSubheader>
                  Limiten túli foglalások ({size(state.overlimit)})
                </ListSubheader>
                {state.overlimit?.map(booking => (
                  <BookingListItem
                    key={booking.id}
                    booking={booking}
                    overlimit
                    onChangeBooking={(b: BookingItem) => {
                      updateBooking("overlimit", b);
                    }}
                  />
                ))}
              </>
            )}
          </List>
        </DialogContent>
      )}
      {!state.event && (
        <DialogContent>
          <CalendarPro
            calRef={cal}
            //key={eventId}
            navigateDelay={300}
            loadEvents={loadEvents}
            ToolbarComponent={CalendarToolbar}
            autoLoad={false}
            /*toolbarStyle={{
          position: "sticky",
          zIndex: 1000,
          top: 16,
          marginTop: "-8px",
        }}*/

            view={VIEWS.WEEK}
            events={items}
            //date={date}
            containerStyle={{
              flexDirection: "column",
            }}
            buttons={
              <Checkbox
                label="Minden program"
                value={allPrograms}
                onChange={(e: any) => setAllPrograms(e.target.value as any)}
              />
            }
            CellComponent={StartBoatCell}
            CellHeaderComponent={CalendarCellHeaderSmall}
            isSelectedFunc={cell => cell.id === selectedEvent?.id}
            onEventPress={cell => {
              //setSelectedEvent(s);
              setEventId(cell.id);
            }}
          />
        </DialogContent>
      )}
      <DialogActions>
        {!!state.event && !_eventId && (
          <Button
            color="inherit"
            title="Vissza"
            variant="outlined"
            onClick={() => {
              setEventId(undefined);
              setState({ event: undefined, movable: [], overlimit: [] });
            }}
          />
        )}
        {!!state.event && (
          <>
            <Button
              color="error"
              title="Foglalások áthelyezése a limitek figyelmenkívül hagyásával"
              variant="outlined"
              onClick={() => moveBookingsAsync(true)}
              loading={moveBookingLoading === "force"}
            />
            <Spacer right />
            <Button
              title="Foglalások áthelyezése"
              onClick={() => moveBookingsAsync()}
              loading={moveBookingLoading === "normal"}
              disabled={moveIsDisabled}
            />
          </>
        )}
        {!state.event && (
          <Button
            title="Mégse"
            onClick={() => props.onClose && props.onClose({}, "backdropClick")}
          />
        )}
      </DialogActions>
    </>
  );
}

export default MoveBookingListModalInner;
