import { ReactNode } from "react";
import useSimpleReport, {
  SimpleReportContext,
  UseSimpleReportProps,
} from "./useSimpleReport";

export interface SimpleReportProviderProps extends UseSimpleReportProps {
  children?: ReactNode;
}

const SimpleReportProvider: React.FC<SimpleReportProviderProps> = ({
  children,
  ...props
}) => {
  const context = useSimpleReport(props);

  return (
    <SimpleReportContext.Provider value={context}>
      {children}
    </SimpleReportContext.Provider>
  );
};
export default SimpleReportProvider;
