import { ExpandLess } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Alert,
  Avatar,
  Badge,
  Box,
  DialogActions,
  DialogContent,
  IconButtonProps,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useAppSelector } from "app/hooks";
import { Button, ButtonProps, DateTimePicker, VIEWS } from "common";
import CalendarPro from "common/components/CalendarPro";
import { FakeEvent } from "common/types";
import { format, parseISO } from "date-fns";
import { selectDictionary } from "features/dictionaries/dictionarySlice";
import CalendarToolbar from "features/events/Calendar/CalendarToolbar";
import CalendarCellHeaderSmall from "features/events/Calendar/cells/CalendarCellHeaderSmall";
import StartBoatCell from "features/events/Calendar/cells/StartBoatCell";
import useEventCalendar from "features/events/Calendar/useEventCalendar";
import {
  EventItem,
  getClosestEvents,
  prepareCalendarItems,
} from "features/events/eventApi";
import { ProgramItem } from "features/programs/programApi";
import I18n from "i18n";
import moment from "moment";
import { useMemo, useState } from "react";

type BookingButtonInnerBaseProps = {
  onSelect: (
    programId: number,
    event: EventItem | null,
    planned: boolean,
    datetime?: string
  ) => void;
  onClose: () => void;
};

type BookingIconButtonProps = {
  buttonType: "icon";
} & BookingButtonInnerBaseProps &
  Omit<IconButtonProps, "onSelect">;

type BookingButtonInnerButtonProps = {
  buttonType: "button";
} & BookingButtonInnerBaseProps &
  Omit<ButtonProps, "onSelect">;

type BookingButtonInnerProps =
  | BookingIconButtonProps
  | BookingButtonInnerButtonProps;

function parseItems(items: EventItem[]) {
  let _items: any[] = [];
  items.forEach(row => {
    _items.push({
      ...row,
      title: row?.program?.name,
      start: moment(row?.start_time),
      end: moment(row?.start_time).add(1, "hour"),
    });
  });

  return _items;
}

const BookingButtonInner: React.FC<BookingButtonInnerProps> = ({
  onSelect,
  buttonType,
  onClose,
  ...iconButtonProps
}) => {
  const [selectedProgram, setSelectedProgram] = useState<ProgramItem | null>(
    null
  );
  const [selectedDatetime, setSelectedDatetime] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<EventItem | null>(null);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [events, setEvents] = useState<EventItem[]>([]);
  const { items, loadEvents, setItems, cal } = useEventCalendar({
    programIds: [selectedProgram?.id],
    filterKey: "bookingbutton",
  });
  const [activeStep, setActiveStep] = useState(0);

  const dictionaries = useAppSelector(selectDictionary);
  const programs = useMemo(() => {
    if (showMore) {
      return dictionaries.programs;
    }
    return dictionaries.programs?.filter(p => p.always_visible || showMore);
  }, [showMore, dictionaries]);

  const handleProgramSelect = (program: ProgramItem) => {
    setSelectedProgram(program);
  };

  const handleDatetimeChange = (evt: FakeEvent<string | null>) => {
    setSelectedDatetime(evt.target.value);
  };

  const handleNextStep = async () => {
    if (selectedProgram && selectedDatetime) {
      setLoading(true);
      try {
        const fetchedEvents = await getClosestEvents(
          selectedProgram.id,
          selectedDatetime
        );
        setItems(prepareCalendarItems(fetchedEvents));
        if (fetchedEvents.length > 0) {
          setSelectedEvent(fetchedEvents[0]);
        }
        setActiveStep(1);
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEventSelect = (event: EventItem) => {
    setSelectedEvent(event);
  };

  const handleBooking = () => {
    if (selectedEvent) {
      onSelect(selectedProgram!.id, selectedEvent, false);
      onClose();
    }
  };

  const handlePlannedBooking = () => {
    onSelect(selectedProgram!.id, selectedEvent, true, selectedDatetime!);
    onClose();
  };

  const eventsByDay = events.reduce((acc, event) => {
    const date = format(new Date(event.start_time), "yyyy-MM-dd");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(event);
    return acc;
  }, {} as { [key: string]: EventItem[] });

  const daysWithEvents = Object.keys(eventsByDay).map(date => parseISO(date));

  return (
    <>
      <DialogContent>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>{I18n.t("Booking.chooseDateTimeProgram")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{I18n.t("Booking.selectEvent")}</StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 && (
          <Box sx={{ mt: 2, gap: 2, display: "flex", flexDirection: "column" }}>
            <DateTimePicker
              label={I18n.t("Booking.attributes.planned_datetime")}
              value={selectedDatetime}
              onChange={handleDatetimeChange}
              pickerProps={{ disablePast: true }}
            />
            <Alert severity="info">
              {I18n.t("Booking.plannedBookingInfoDatetime")}
            </Alert>
            <List disablePadding dense>
              {programs?.map(program => (
                <ListItemButton
                  key={program.id}
                  onClick={() => handleProgramSelect(program)}
                  selected={selectedProgram?.id === program.id}
                  divider
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: program.color }}>
                      <Typography
                        sx={{
                          color: theme =>
                            theme.palette.getContrastText(program.color),
                        }}
                      >
                        {program.code}
                      </Typography>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={program.name} />
                  {selectedProgram?.id === program.id && (
                    <ListItemSecondaryAction>
                      <CheckIcon color="primary" />
                    </ListItemSecondaryAction>
                  )}
                </ListItemButton>
              ))}
            </List>
            <Button
              title={showMore ? "Kevesebb" : "Több"}
              variant="text"
              endIcon={showMore ? <ExpandLess /> : <ExpandMore />}
              onClick={() => setShowMore(!showMore)}
            />
          </Box>
        )}
        {activeStep === 1 && (
          <Box>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
              {selectedProgram?.name}
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: selectedProgram?.color,
                  marginLeft: "8px",
                  borderRadius: "50%",
                }}
              />
            </Typography>
            <CalendarPro
              //calRef={cal}
              //key={filter?.from}
              navigateDelay={300}
              loadEvents={loadEvents}
              ToolbarComponent={CalendarToolbar}
              /*toolbarStyle={{
          position: "sticky",
          zIndex: 1000,
          top: 16,
          marginTop: "-8px",
        }}*/

              view={VIEWS.WEEK}
              events={items}
              //date={moment()}
              containerStyle={{
                flexDirection: "column",
              }}
              CellComponent={StartBoatCell}
              CellHeaderComponent={CalendarCellHeaderSmall}
              isSelectedFunc={cell => cell.id === selectedEvent?.id}
              onEventPress={cell => {
                setSelectedEvent(cell);
              }}
            />
            <Box
              sx={{
                display: "flex",
                overflowX: "auto",
                py: 2,
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {daysWithEvents.map(day => (
                <Box
                  key={day.toString()}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    //minWidth: 100,
                    gap: 1,
                  }}
                >
                  <Typography variant="subtitle2" align="center">
                    {format(day, "yyyy.MM.dd")}
                  </Typography>
                  {eventsByDay[format(day, "yyyy-MM-dd")]?.map(event => (
                    <Badge
                      key={event.id}
                      badgeContent={event.limits[0].remaining_places}
                      color="success"
                    >
                      <Button
                        onClick={() => handleEventSelect(event)}
                        variant={
                          selectedEvent?.id === event.id
                            ? "contained"
                            : "outlined"
                        }
                        size="small"
                        fullWidth
                      >
                        {format(new Date(event.start_time), "HH:mm")}
                      </Button>
                    </Badge>
                  ))}
                </Box>
              ))}
            </Box>
            <DialogActions
              sx={{
                flexDirection: "column",
                gap: 2,
                "&>:not(style)~:not(style)": {
                  marginLeft: 0,
                },
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleBooking}
                disabled={!selectedEvent}
                fullWidth
              >
                {I18n.t("Booking.eventBookingButton", {
                  datetime: moment(selectedEvent?.start_time).format("L LT"),
                })}
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={handlePlannedBooking}
                fullWidth
              >
                {I18n.t("Booking.plannedBookingButton", {
                  datetime: moment(selectedDatetime).format("L LT"),
                })}
              </Button>
              <Alert severity="info">
                {I18n.t("Booking.plannedBookingInfo")}
              </Alert>
              <Button onClick={() => setActiveStep(0)} variant="text">
                {I18n.t("App.back")}
              </Button>
            </DialogActions>
          </Box>
        )}
      </DialogContent>
      {activeStep === 0 && (
        <DialogActions>
          <Button
            onClick={handleNextStep}
            loading={loading}
            disabled={!selectedProgram || !selectedDatetime}
          >
            {I18n.t("Booking.plannedButton")}
          </Button>
        </DialogActions>
      )}
    </>
  );
};

export default BookingButtonInner;
