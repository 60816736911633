import { alpha, lighten } from "@mui/material";
import { generateColorPalette } from "helpers/colorUtils";
import moment from "moment";

export type SimpleReportData = {
  amount: number; // summable
  boat: string;
  canceled_amount: number; // summable
  canceled_count: number; // summable
  canceled_guests: number; // summable
  count: number; // summable
  email: string;
  guests: number; // summable
  name: string;
  partner: string;
  partner_group: string;
  program: string;
  color?: string;
  program_variant: string;
  t: string; // This represents a date, but in the example, it's a string
};

type ChartData = {
  labels: string[];
  datasets: Dataset[];
};

type Dataset = {
  label: string;
  data: number[];
  total: number;
  canceledTotal: number;
  backgroundColor?: string;
  borderColor?: string;
  fill?: boolean;
};

function transformChartData(
  data: SimpleReportData[],
  groupBy: keyof SimpleReportData,
  chartType: "bar" | "line" | "pie" = "bar",
  startDate: string | null = null,
  endDate: string | null = null,
  value: keyof SimpleReportData = "amount",
  show_canceled?: boolean
  //timeGrouping: "day" | "month" | "year" = "day"
): ChartData {
  const labels: string[] = [];
  const datasets: { [key: string]: Dataset } = {};
  const today = new Date();

  const diffInDays = moment(endDate).diff(moment(startDate), "days");
  let timeGrouping = "day";
  if (diffInDays > 400) {
    timeGrouping = "year";
  } else if (diffInDays > 40) {
    timeGrouping = "month";
  }

  // Generate all dates between start and end
  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let date = new Date(start);

    while (date <= end) {
      if (timeGrouping === "day") {
        labels.push(date.toISOString().split("T")[0]);
      } else if (timeGrouping === "month") {
        labels.push(date.toISOString().split("T")[0].slice(0, 7)); // YYYY-MM
      } else if (timeGrouping === "year") {
        labels.push(date.getFullYear().toString());
      }
      date.setDate(
        date.getDate() +
          (timeGrouping === "month" ? 30 : timeGrouping === "year" ? 365 : 1)
      );
    }
  } else {
    // Collect all unique dates for labels
    data.forEach(entry => {
      const label = entry.t;
      if (!labels.includes(label) && new Date(label) <= today) {
        labels.push(label);
      }
    });
  }

  // Sort the labels to ensure they are in chronological order
  labels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  // Create groups and initialize datasets
  const groups: any[] = [];
  const totals: { [key: string]: number } = {};
  const canceledTotals: { [key: string]: number } = {};
  const groupColorMap: { [key: string]: string } = {};
  const colorCount: { [key: string]: number } = {};

  //Sort
  if (groupBy === "program_variant" || groupBy === "program") {
    data.sort((a: SimpleReportData, b: SimpleReportData) => {
      if (!a.color) {
        return 1;
      }
      if (!b.color) {
        return -1;
      }
      return a.program > b.program ? 1 : a.program < b.program ? -1 : 0;
    });
  }

  data.forEach(entry => {
    const groupByValue: any = entry[groupBy];
    if (!groups.includes(groupByValue)) {
      groups.push(groupByValue);
      totals[groupByValue] = 0;
      canceledTotals[groupByValue] = 0;
      if (entry.color && !groupColorMap[groupByValue]) {
        if (!colorCount[entry.color]) {
          colorCount[entry.color] = 1;
        } else {
          colorCount[entry.color] += 1;
        }

        if (colorCount[entry.color] > 1) {
          groupColorMap[groupByValue] = lighten(
            entry.color,
            colorCount[entry.color] * 0.1
          );
        } else {
          groupColorMap[groupByValue] = entry.color;
        }
      }
    }
  });

  // Generate colors
  const colorMap: { [key: string]: string } = {};
  const colors = generateColorPalette(groups.length, "hex");

  groups.forEach((group, index) => {
    if (groupBy === "program_variant" && groupColorMap[group]) {
      colorMap[group] = groupColorMap[group];
    } else if (groupBy === "program" && groupColorMap[group]) {
      colorMap[group] = groupColorMap[group];
    } else {
      colorMap[group] = colors[index];
    }
  });

  // Initialize datasets
  groups.forEach(group => {
    datasets[group] = {
      label: group,
      data: Array(labels.length).fill(0),

      //dataCancelled: Array(labels.length).fill(0),
      backgroundColor: colorMap[group] || "#CCCCCC",
      total: 0,
      canceledTotal: 0,
    } as any;

    if (chartType === "line") {
      datasets[group].borderColor = colorMap[group] || "#CCCCCC";
      datasets[group].fill = false;
    }
  });

  // Fill datasets with data
  data.forEach(entry => {
    const groupByValue: any = entry[groupBy];
    const labelIndex = labels.indexOf(entry.t);

    if (labelIndex !== -1) {
      datasets[groupByValue].data[labelIndex] += entry[value] as number;
      totals[groupByValue] += entry[value] as number;

      //canceled
      //@ts-ignore
      /* datasets[groupByValue].dataCanceled[labelIndex] += entry[
        `canceled_${value}` as keyof SimpleReportData
      ] as number;*/
      if (show_canceled) {
        canceledTotals[groupByValue] +=
          (entry[`canceled_${value}` as keyof SimpleReportData] as number) ||
          0;

        // Check if a corresponding 'canceled_' field exists and add a negative dataset for it
        const canceledKey = `canceled_${value}`;
        if (entry.hasOwnProperty(canceledKey) && chartType !== "pie") {
          const canceledGroupLabel = `Canceled ${groupByValue}`;
          if (!datasets[canceledGroupLabel]) {
            datasets[canceledGroupLabel] = {
              label: canceledGroupLabel,
              data: Array(labels.length).fill(0),
              backgroundColor: alpha(colorMap[groupByValue], 0.5) || "#FFCCCC",
              total: 0,
              canceledTotal: 0,
            };

            if (chartType === "line") {
              datasets[canceledGroupLabel].borderColor =
                alpha(colorMap[groupByValue], 0.5) || "#FFCCCC";
              datasets[canceledGroupLabel].fill = false;
            }
          }
          //handle canceled totals
          if (!totals[canceledGroupLabel]) {
            totals[canceledGroupLabel] = 0;
          }
          //console.log({ value, entry });
          totals[canceledGroupLabel] +=
            (entry[canceledKey as keyof SimpleReportData] as number) * -1;
          datasets[canceledGroupLabel].data[labelIndex] +=
            (entry[canceledKey as keyof SimpleReportData] as number) * -1;
        }
      }
    }
  });
  //console.log({ totals });
  // Handle pie chart separately
  if (chartType === "pie") {
    return {
      labels: Object.keys(totals),
      datasets: [
        {
          data: Object.values(totals),
          total: Object.values(totals).reduce((acc, curr) => acc + curr, 0), // Calculate total sum of all values
          backgroundColor: Object.values(colorMap),
          borderColor: "transparent",
          color: Object.values(colorMap),
          borderWidth: 0,
        } as any,
      ],
    };
  }

  // Add total to datasets and finalize
  Object.keys(datasets).forEach(group => {
    datasets[group].total = totals[group];
    datasets[group].canceledTotal = canceledTotals[group];
  });

  return {
    labels: labels.map(l => l.replaceAll("-", ".")),
    datasets: Object.values(datasets),
  };
}

export default transformChartData;
