import { DialogProps } from "@mui/material";
import Dialog from "components/DraggableDialog/DraggableDialog";
import MoveBookingListModalInner from "./MoveBookingListModalInner";

export interface MoveBookingListModalProps extends DialogProps {
  eventId?: number;
  bookingIds?: number[];
  programId?: number;
  afterMove?: () => void;
}

function MoveBookingListModal({
  eventId,
  bookingIds,
  programId,
  afterMove,
  ...props
}: MoveBookingListModalProps) {
  return (
    <Dialog maxWidth="md" fullWidth {...props}>
      <MoveBookingListModalInner
        eventId={eventId}
        bookingIds={bookingIds}
        programId={programId}
        afterMove={afterMove}
        {...props}
      />
    </Dialog>
  );
}

export default MoveBookingListModal;
