import usePersistentState, { StorageType } from "helpers/usePersistentState";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
} from "react";
type FilterContextType<T = any> = [filter: T, onChange: (filter: T) => void];
const FilterContext = createContext<FilterContextType>({} as FilterContextType);
export interface FilterProviderProps {
  children?: ReactNode;
  id: string;
  storageType: StorageType;
  defaultFilter?: any;
}
export default function FilterProvider({
  children,
  id,
  storageType = "none",
  defaultFilter,
}: FilterProviderProps) {
  const [filter, setFilter] = usePersistentState<any>(
    defaultFilter || {},
    id,
    storageType
  );
  const onChange = useCallback(
    (newFilter: any) => {
      if (filter === null || filter === undefined) {
        setFilter(newFilter);
      } else {
        setFilter((prevFilter: any) => ({ ...prevFilter, ...newFilter }));
      }
    },
    [filter]
  );

  useEffect(() => {
    if (
      defaultFilter &&
      JSON.stringify(defaultFilter) !== JSON.stringify(filter)
    ) {
      setFilter(defaultFilter);
    }
  }, [defaultFilter]);
  return (
    <FilterContext.Provider value={[filter, onChange]}>
      {children}
    </FilterContext.Provider>
  );
}

export function useFilter<T = any>(): FilterContextType<T> {
  return useContext(FilterContext);
}
