import { selectAccessToken } from "features/identity/identitySlice";
import QueryString from "qs";
import { useAppSelector } from "./hooks";
import settings from "./settings";

export default function useExcelUrl(
  controller: string,
  scope?: any,
  filter?: any
) {
  const accessToken = useAppSelector(selectAccessToken);
  if (scope) {
    let scopeParams: any[] = [];
    Object.keys(scope).forEach(key => {
      if (scope[key]) {
        if (scopeParams.length === 0) {
          scopeParams.push(`${key}/${scope[key]}`);
        }
      }
    });
    if (scopeParams.length > 0) {
      controller = `${scopeParams.join("/")}/${controller}`;
    }
  }
  const params = QueryString.stringify({ filter }, { encode: false });
  return `${settings.apiHost}/${controller}/excel?token=${accessToken}${
    params ? "&" + params : ""
  }`;
}
