import KeyIcon from "@mui/icons-material/Key";
import {
  Alert,
  Box,
  DialogContent,
  Fade,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import FilterProvider from "components/FilterProvider";
import BookingForm from "features/bookings/BookingForm";
import BookingList from "features/bookings/BookingList";
import { BOOKING_STATUSES } from "features/bookings/bookingConstants";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { useContext, useState } from "react";
import useProgramSummary from "../useProgramSummary";
import { EventCalendarContext } from "./EventCalendarForm";
import LimitSummary from "./LimitSummary";
import OptionSummary from "./OptionSummary";
import VariantSummary from "./VariantSummary";
import { UseEventCalendarFormReturnType } from "./useEventCalendarForm";
export interface EventSummaryProps {
  afterSave?: () => void;
}
export default function EventSummary({ afterSave }: EventSummaryProps) {
  const { item, load } = useContext(
    EventCalendarContext
  ) as UseEventCalendarFormReturnType;
  const [tab, setTab] = useState(0);
  const { hasPermissions, canEditItem } = useAccess();
  const theme = useTheme();

  const { variants, options, summary, limits } = useProgramSummary({ item });

  return (
    <>
      <Box
        sx={{
          borderBottom: theme => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Tabs value={tab} onChange={(evt, t) => setTab(t)}>
          <Tab value={0} label={I18n.t("Booking.title")} />
          <Tab value={1} label={I18n.t("Limit.title")} />
          <Tab value={2} label={I18n.t("ProgramVariant.title")} />
          {item?.program?.has_options && (
            <Tab value={3} label={I18n.t("Option.title")} />
          )}
        </Tabs>
      </Box>
      <DialogContent sx={{ overflowX: "hidden" }}>
        {tab === 0 &&
          hasPermissions(["booking.viewany", "booking.viewown"]) && (
            <FilterProvider id="eventSummary.bookings" storageType="session">
              <BookingList
                id="event_summary"
                paperProps={{ elevation: 0 }}
                isInCalendar
                showFooter={false}
                icon={<KeyIcon />}
                title={I18n.t("Booking.title")}
                showHeader={false}
                modalProps={{
                  maxWidth: item?.program?.has_options ? "lg" : "sm",
                }}
                renderModalForm={props => (
                  <BookingForm
                    {...props}
                    defaultValues={{
                      event: item,
                      event_id: item.id,
                      program_id: item.program_id,
                      schedule_id: item.schedule_id,
                      status: BOOKING_STATUSES.ACTIVE,
                    }}
                    afterSave={(newItem, isCreate) => {
                      props.afterSave && props.afterSave(newItem, isCreate);
                      afterSave && afterSave();
                      //load();
                    }}
                  />
                )}
                /*showHeader={false} toolbarItems={["title", "refresh", "create"]}  showFooter={false}*/
              />
            </FilterProvider>
          )}
        {tab === 1 && <LimitSummary event={item} items={limits} />}
        {tab === 2 && (
          <VariantSummary event={item} items={variants} summary={summary} />
        )}
        {tab === 3 && <OptionSummary items={options} />}
        <Box sx={{ minHeight: "68px", mt: 3 }}>
          <Fade in={!!(item as any).updated_by_name}>
            <Box>
              <Alert severity="info" sx={{ flex: 0 }}>
                <Typography variant="body2" color="textSecondary">
                  {I18n.t("Event.activeBookings", {
                    numberOfBookings: (item as any).number_of_bookings || 0,
                    numberOfGuests: (summary as any).number_of_guests || 0,
                    averageGuestsPerBooking:
                      Math.round(
                        (summary.number_of_guests /
                          (item as any).number_of_bookings || 0) * 10
                      ) / 10,
                  })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {I18n.t("Event.cancelledBookings", {
                    numberOfCancelledBookings:
                      (item as any).number_of_cancelled_bookings || 0,
                    numberOfCancelledGuests:
                      (item as any).number_of_cancelled || 0,
                    averageGuestsPerCancelledBooking: !!(item as any)
                      .number_of_cancelled_bookings
                      ? Math.round(
                          ((item as any).number_of_cancelled /
                            (item as any).number_of_cancelled_bookings) *
                            10
                        ) / 10
                      : 0,
                  })}
                </Typography>
              </Alert>
            </Box>
          </Fade>
        </Box>
      </DialogContent>
    </>
  );
}
