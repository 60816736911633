import { DoubleArrow } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { alpha, Box, IconButton, useTheme } from "@mui/material";
import {
  Autocomplete,
  Checkbox,
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridLinkColumn,
  DateRangePicker,
  enumToOptions,
  GridColumns,
  PageHeader,
  Spacer,
} from "common";
import CheckboxGroup from "common/components/Inputs/CheckboxGroup/CheckboxGroup";
import BoatCheckboxSelector from "components/BoatCheckboxSelector/BoatCheckboxSelector";
import ProgramCheckboxSelector from "components/ProgramCheckboxSelector/ProgramCheckboxSelector";
import useAccess from "features/profile/useAccess";
import LimitsCell from "features/schedules/columns/LimitsCell";
import OptionPricesCell from "features/schedules/columns/OptionPricesCell";
import PricesCell from "features/schedules/columns/PricesCell";
import { getScheduleService } from "features/schedules/scheduleApi";
import I18n from "i18n";
import EventForm from "./EventForm";
import { EventItem } from "./eventApi";
import { EVENT_STATUSES } from "./eventConstants";
import useEventList from "./useEventList";
export interface EventListProps extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
  id: string;
}

export default function EventList({
  showHeader = true,
  id,
  ...props
}: EventListProps) {
  const {
    params,
    scope,
    editUrl,
    gridFilter,
    onChange,
    filter,
    showMore,
    setShowMore,
    cancelEventsByIdAsync,
    ...service
  } = useEventList(id);
  const { hasPermissions, canEditItem } = useAccess();
  const scheduleService = getScheduleService();
  let columns: GridColumns<EventItem> = [
    {
      field: "start_time",
      headerName: I18n.t("Event.attributes.start_time"),
      type: "datetime",
    },
    {
      hide: !!params.boat_id,
      hideable: !params.boat_id,
      field: "boat.name",
      headerName: I18n.t("Event.attributes.boat_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/boats/${params.row?.boat_id}`}
          params={params}
        />
      ),
    },
    {
      hide: !!params.program_id,
      hideable: !params.program_id,
      field: "program.name",
      headerName: I18n.t("Event.attributes.program_id"),
      renderCell: params => (
        <DataGridLinkColumn
          href={`/programs/${params.row?.program_id}`}
          params={params}
        />
      ),
    },
    {
      hide: !!params.schedule_id,
      hideable: !params.schedule_id,
      valueFormatter: params =>
        `${params?.row?.schedule?.name} ${params?.row?.schedule?.date_from}-${params?.row?.schedule?.date_to}`,
      field: "schedule_id",
      headerName: I18n.t("Event.attributes.schedule_id"),
      type: "boolean",
    },
    {
      field: "duration",
      headerName: I18n.t("Event.attributes.duration"),
      hideable: true,
      type: "number",
    },
    {
      field: "limits",
      headerName: I18n.t("Event.attributes.limits"),
      type: "json",
      renderCell: params => <LimitsCell params={params} />,
    },
    {
      field: "variants",
      headerName: I18n.t("Event.attributes.variants"),
      type: "json",
      renderCell: params => <PricesCell params={params} />,
    },
    {
      field: "options",
      headerName: I18n.t("Event.attributes.options"),
      type: "json",
      renderCell: params => <OptionPricesCell params={params} />,
    },
    /*{
      field: "number_of_guests",
      headerName: I18n.t("Event.attributes.number_of_guests"),
      type: "number",
    },
    {
      field: "number_of_seats",
      headerName: I18n.t("Event.attributes.number_of_seats"),
      type: "number",
    },
    {
      field: "number_of_babies",
      headerName: I18n.t("Event.attributes.number_of_babies"),
      type: "number",
    },*/
    {
      field: "status",
      headerName: I18n.t("Event.attributes.status"),
      type: "singleSelect",
      valueOptions: enumToOptions(EVENT_STATUSES, "Event.options.status"),
    },
    {
      field: "closed",
      headerName: I18n.t("Event.attributes.closed"),
      type: "json",
    },
    {
      field: "online_closed",
      headerName: I18n.t("Event.attributes.online_closed"),
      type: "json",
    },
    {
      field: "private",
      headerName: I18n.t("Event.attributes.private"),
      type: "boolean",
      hideable: true,
    },
    {
      field: "custom",
      headerName: I18n.t("Event.attributes.custom"),
      type: "boolean",
      hideable: true,
    },
  ];
  const theme = useTheme();

  return (
    <>
      {showHeader && (
        <>
          <PageHeader
            avatar={<CalendarMonthIcon />}
            title={I18n.t("Event.title")}
          >
            <Box sx={{ minWidth: 500 }}>
              <Autocomplete
                disableClearable={false}
                fullWidth
                loadItems={scheduleService.loadAutocomplete}
                value={filter?.scheduleId}
                onChange={(evt: any) => {
                  onChange({ scheduleId: (evt.target.value as any) || [] });
                }}
              />
            </Box>
            <DateRangePicker
              value={{ from: filter?.range?.start, to: filter?.range?.end }}
              defaultRangeIdStorageKey="events_default_range"
              onChange={({ from, to }) =>
                onChange({
                  range: {
                    start: from as any,
                    end: to as any,
                  },
                })
              }
            />
          </PageHeader>
          <BoatCheckboxSelector
            value={filter?.boatIds}
            color={theme.palette.primary.main}
            onChange={evt => {
              onChange({ boatIds: (evt.target.value as any) || [] });
            }}
          />
          <Spacer h={2} />
          <ProgramCheckboxSelector
            value={filter?.programIds}
            onChange={evt => {
              onChange({ programIds: (evt.target.value as any) || [] });
            }}
            showMore={showMore}
            setShowMore={setShowMore}
          />
          <Spacer h={2} />
        </>
      )}
      <DataGrid
        paperProps={showHeader === false ? { elevation: 0 } : undefined}
        renderToolbarLeftItems={({ handleCreate }) => (
          <>
            <CheckboxGroup
              options={enumToOptions(EVENT_STATUSES, "Event.options.status")}
              onChange={(evt: any) => onChange({ status: evt.target.value })}
              value={filter?.status}
            />
            <Checkbox
              label="Lezárt"
              numeric
              value={filter.variant_closed_status as any}
              onChange={(evt: any) =>
                onChange({ variant_closed_status: evt.target.value })
              }
            />
          </>
        )}
        filter={gridFilter}
        {...service}
        columns={columns}
        id="events"
        renderModalForm={props => <EventForm {...props} />}
        modalProps={{ maxWidth: "lg" }}
        {...props}
        //getHeaderName={field => I18n.t(`Event.attributes.${field.replaceAll(".", "_")}`)}
        defaultState={{
          hidden_columns: [
            "schedule_id",
            "boat_id",
            "duration",
            "custom",
            "limits",
            "variants",
            "options",
          ],
        }}
        canCreate={hasPermissions("event.create")}
        canDelete={hasPermissions(["event.editany", "event.editown"])}
        canEdit={hasPermissions(["event.editany", "event.editown"])}
        canDeleteRow={params => canEditItem("event", params.row)}
        canEditRow={params => canEditItem("event", params.row)}
        canBatchActions={hasPermissions(["event.editany", "event.editown"])}
        canHandleTrash={hasPermissions(["event.editany", "event.editown"])}
        rowActions={(params, openEdit, refresh) => {
          let actions: any[] = [];
          actions.push(
            <IconButton
              key="move"
              size="small"
              title="Foglalás áthelyezése"
              onClick={() => {
                cancelEventsByIdAsync([params.row?.id], refresh as any);
              }}
            >
              <DoubleArrow />
            </IconButton>
          );
          return actions;
        }}
        bulkActions={[
          {
            id: "cancelEvents",
            label: "Események visszavonása",
            action: cancelEventsByIdAsync,
            icon: <DoubleArrow />,
          },
        ]}
        rowSx={(item: EventItem) => {
          let sx: any = {};
          if (item.variant_closed_status === 1) {
            sx["& td:nth-child(2)"] = {
              color: (theme: any) =>
                `${theme.palette.text.secondary}!important`,
              textDecoration: "line-through",
              backgroundColor: (theme: any) =>
                alpha(theme.palette.action.disabledBackground, 0.15),
            };
          }
          if (item.status === EVENT_STATUSES.CANCELED) {
            return {
              ...sx,
              backgroundColor: theme => alpha(theme.palette.error.main, 0.15),
            };
          }
          if (item.private) {
            return {
              ...sx,
              backgroundColor: theme => alpha(theme.palette.warning.main, 0.15),
            };
          }

          return sx;
        }}
      />
    </>
  );
}
