import { CalendarCellProps } from "common/components/CalendarPro";
import { BoatItem } from "features/boats/boatApi";
import moment from "moment";
import { useMemo } from "react";
import { CalendarEventItem } from "../../eventApi";

//export const CALENDAR_MONTH_CELL_WIDTH = "14.2857143%";
export const CALENDAR_MONTH_CELL_WIDTH = "14.25%";

export type StartGroup = {
  key: string;
  number_of_guests: number;
  number_of_places: number;
  duration: number;
  start_time: string;
  boats: BoatGroup[];
};

export type BoatGroup = {
  key: string;
  boat: BoatItem;
  events: CalendarEventItem[];
};

type StartGroupMap = {
  boats: { [key: string]: BoatGroup };
} & Omit<StartGroup, "boats">;

function getDiff(startTime: string, endTime: string) {
  let fromTime = moment(startTime, "HH:mm");
  let toTime = moment(endTime, "HH:mm");
  let duration = moment.duration(toTime.diff(fromTime));
  let diff = duration.asHours();
  return diff;
}
function getHours(startTime: string, endTime: string) {
  let fromTime = moment(startTime, "HH:00");
  let diff = getDiff(startTime, endTime);
  let array = [];

  for (let i = 0; diff > i; i++) {
    let result = moment(fromTime).add(i, "hours").format("HH:mm");
    array.push(result);
  }
  return array;
}

export interface BoatStartCellProps
  extends CalendarCellProps<CalendarEventItem> {}
export default function useCell(props: BoatStartCellProps) {
  return useMemo(() => {
    let result: { [key: string]: BoatGroup } = {};
    props.events.forEach(item => {
      const boatKey = `${item.boat_id}`;

      if (!result[boatKey]) {
        result[boatKey] = {
          key: boatKey,
          boat: item.boat,
          events: [],
          number_of_guests: 0,
          number_of_places: 0,
        } as BoatGroup;
      }

      //result[boatKey].number_of_guests += item.summary.number_of_guests;
      //result[boatKey].number_of_places += item.summary.number_of_places;
      result[boatKey].events.push(item);
    });
    //const _startTimes = Object.values(result);

    const _startTimes = Object.values(result);
    _startTimes.sort((a, b) => a.boat?.sort_by - b.boat?.sort_by);
    return { startTimes: _startTimes };
  }, [props.events]);
}
