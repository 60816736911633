import LowPriorityIcon from "@mui/icons-material/LowPriority";
import {
  Badge,
  Box,
  Checkbox,
  LinearProgress,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import I18n from "i18n";
import { ReactNode } from "react";
import DataGridBulkActions from "./DataGridBulkActions";
import { GridState } from "./types";

export type BulkAction<T> = {
  id: string;
  label: string;
  action: (ids: any[], load: () => void, state: GridState<T>) => Promise<any>;
  icon?: ReactNode;
};

export interface DataGridHeaderProps<T = any> {
  id: string;
  columns: any[];
  state: GridState<T>;
  hasAction?: boolean;
  handleStart: (e: any, colIndex: number) => void;
  handleEnd: (e: any, colIndex: number) => void;
  handleMove: (e: any, colIndex: number) => void;
  handleSortChange: (field: string, miltiple?: boolean) => void;
  handleToggleSelectAll: (event: unknown) => void;
  canBatchActions?: boolean;
  canOrderRows?: boolean;
  sortable?: boolean;
  restoreSelectedItems?: Function;
  deleteSelectedItems?: Function;
  bulkActions?: BulkAction<T>[];
  load: () => void;
}

export default function DataGridHeader<T = any>(props: DataGridHeaderProps<T>) {
  const {
    id,
    state,
    columns,
    hasAction,
    handleStart,
    handleEnd,
    handleMove,
    handleSortChange,
    canBatchActions,
    handleToggleSelectAll,
    sortable,
    canOrderRows,
  } = props;
  const numSelected = state.selectedIds.length;
  const rowCount = state.data.length;
  return (
    <TableHead>
      <TableRow>
        {canOrderRows && (
          <TableCell
            //padding="checkbox"
            width={40}
            sx={{
              //padding: "0px!important",
              paddingBottom: "8px!important",
              paddingRight: "0px!important",
            }}
          >
            <LowPriorityIcon />
          </TableCell>
        )}
        {canBatchActions && (
          <TableCell
            padding="checkbox"
            sx={{ paddingTop: 1, paddingBottom: 1 }}
          >
            <DataGridBulkActions {...props}>
              <Checkbox
                color="primary"
                sx={{ padding: 1 }}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                //onChange={onSelectAllClick}
                onClick={handleToggleSelectAll}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </DataGridBulkActions>
          </TableCell>
        )}
        {columns
          .filter(
            c => c.hide !== true && !state.hidden_columns.includes(c.field)
          )
          .map((column, colIndex) => {
            const sortIndex = state.sorting.findIndex(
              s => s.field === column.field
            );
            const sort = sortIndex >= 0 ? state.sorting[sortIndex] : undefined;
            return (
              <TableCell
                key={column.field}
                id={`${id}-col-${colIndex}`}
                sx={{
                  position: "relative",
                  paddingTop: 1,
                  paddingBottom: 1,
                  //maxWidth: "1px",
                }}
                width={column.width}
              >
                <TableSortLabel
                  title={column.headerName}
                  active={!!sort}
                  direction={sort?.direction}
                  onClick={evt => handleSortChange(column.field, evt.ctrlKey)}
                  disabled={
                    !sortable || canOrderRows || column.sortable === false
                  }
                  sx={{
                    width: sortable ? "calc(100% - 18px)" : "100%",
                    "& .MuiTableSortLabel-icon": {
                      right: "-18px",
                      position: "absolute",
                    },
                  }}
                >
                  <Box
                    sx={{
                      //maxWidth: "1px",
                      //maxWidth: "100%",
                      //maxWidth: "100px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      position: "absolute",
                      left: 0,
                      right: 0,
                      //paddingRight: 2,
                    }}
                  >
                    {column.headerName}
                  </Box>
                  <Badge
                    sx={{ position: "absolute", right: -16, top: -4 }}
                    badgeContent={
                      state.sorting.length > 1 && sortIndex >= 0
                        ? sortIndex + 1
                        : undefined
                    }
                  />
                </TableSortLabel>
                <Box
                  sx={{
                    width: "4px",
                    right: 0,
                    top: 0,
                    bottom: 0,
                    position: "absolute",
                    cursor: "col-resize",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                      "&>div": {
                        backgroundColor: theme => theme.palette.text.primary,
                      },
                    },
                  }}
                  draggable
                  onDragStart={e => handleStart(e, colIndex)}
                  onDrag={e => handleMove(e, colIndex)}
                  onDragEnd={e => handleEnd(e, colIndex)}
                >
                  <Box
                    sx={{
                      width: "2px",
                      height: "12px",
                      backgroundColor: theme => theme.palette.grey[700],
                    }}
                  />
                </Box>
                {column.headerActions}
              </TableCell>
            );
          })}
        {hasAction && (
          <TableCell sx={{ paddingTop: 1, paddingBottom: 1 }}>
            {I18n.t("App.actions")}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          padding="none"
          colSpan={columns.length + 2}
          sx={{ height: 0, borderBottom: "none", position: "relative" }}
        >
          {state.loading && (
            <LinearProgress
              sx={{ position: "absolute", left: 0, top: 0, right: 0 }}
            />
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
