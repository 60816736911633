import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Skeleton,
  Toolbar,
  ToolbarProps,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useNavigationContext } from "./../Navigation";
import Spacer from "./../Spacer";
import { usePageHeaderSetter } from "./PageHeaderProvider";

export interface PageHeaderProps extends ToolbarProps {
  title?: string;
  subtitle?: string | ReactNode;
  loading?: boolean;
  backUrl?: string;
  rightContent?: ReactNode;
  leftContent?: ReactNode;
  avatar?: ReactNode;
  titleProps?: TypographyProps;
  subtitleProps?: TypographyProps;
  onClose?: Function;
  isGlobal?: boolean;
}
export default function PageHeader({
  loading,
  title,
  subtitle,
  backUrl,
  rightContent,
  leftContent,
  children,
  avatar,
  titleProps,
  subtitleProps,
  onClose,
  isGlobal,
  sx,
  ...rest
}: PageHeaderProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { prevLocation } = useNavigationContext();

  const canGoBack = !!prevLocation;

  let _sx: any = {
    //borderBottom: 1,
    //borderTop: 1,
    //borderColor: "divider",
    //marginLeft: -3,
    //marginRight: -3,
    ...sx,
  };

  if (onClose) {
    _sx.padding = (theme: any) => `${theme.spacing(2)} ${theme.spacing(3)}`;
    //_sx.minHeight = 0;
  } else {
    _sx.marginLeft = backUrl ? -3 : 0;
    //sx.marginRight = -3;
  }
  if (isMobile) {
    _sx.px = 2;
  }

  usePageHeaderSetter({
    loading,
    title,
    subtitle,
    backUrl,
    rightContent,
    leftContent,
    children,
    avatar,
    titleProps,
    subtitleProps,
    onClose,
    isGlobal,
    ...rest,
  });
  if (isGlobal !== false && window.__GLOBAL_HEADER__) {
    return null;
  } else {
  }

  return (
    <Toolbar className="MuiPageHeader" disableGutters sx={_sx} {...rest}>
      {!!backUrl && canGoBack && (
        <IconButton
          onClick={(evt: any) => {
            evt.stopPropagation();
            navigate(-1);
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      {!!backUrl && !canGoBack && (
        <IconButton
          //@ts-ignore
          component={Link}
          to={backUrl}
          onClick={(evt: any) => {
            evt.preventDefault();
            evt.stopPropagation();
            navigate(-1);
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}

      {avatar}
      {!!avatar && <Spacer w={2} />}
      <div>
        {(!!title || loading) &&
          (loading ? (
            <Skeleton variant="text" sx={{ minWidth: 200, minHeight: 36 }} />
          ) : (
            <Typography variant="h5" {...titleProps}>
              {title}
            </Typography>
          ))}
        {(!!subtitle || loading) &&
          (loading ? (
            <Skeleton variant="text" sx={{ minWidth: 140, minHeight: 24 }} />
          ) : typeof subtitle === "string" ? (
            <Typography variant="body1" color="GrayText" {...subtitleProps}>
              {subtitle}
            </Typography>
          ) : (
            subtitle
          ))}
      </div>
      {!!leftContent && (
        <>
          <Spacer w={2} />
          {leftContent}
          <Spacer w={2} />
        </>
      )}
      {!!rightContent && (
        <>
          <Spacer right />
          {rightContent}
        </>
      )}
      {!!children && (
        <>
          <Spacer right />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              gap: 1,
            }}
          >
            {children}
          </Box>
        </>
      )}
      {!!onClose && !rightContent && !children && <Spacer right />}
      {!!onClose && (
        <IconButton
          onClick={(evt: any) => {
            evt.stopPropagation();
            onClose && onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Toolbar>
  );
}
