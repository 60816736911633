import api from "app/api";
import { Options } from "common";

export interface PartnerGroupItem {
  id: number;
  name: string;
  pay_location_req: boolean;
}

export type PartnerGroupOptions = Options<"role" | "status">;

export function getPartnerGroupService(scope?: any) {
  return api.getService<
    PartnerGroupItem,
    PartnerGroupItem,
    PartnerGroupOptions
  >("partner-groups", {
    scope,
    //params goes here
  });
}
