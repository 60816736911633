import InterestsIcon from "@mui/icons-material/Interests";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Typography } from "@mui/material";
import {
  Autocomplete,
  Button,
  Checkbox,
  ColorPicker,
  CreatedUpdatedBy,
  Field,
  FieldsContainer,
  Form,
  FormBlock,
  PageHeaderBreadcrumbs,
  Spacer,
  TextField,
  Toolbar,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import HistoryListDialog from "features/histories/HistoryListDialog";
import OptionList from "features/options/OptionList";
import useAccess from "features/profile/useAccess";
import ProgramVariantList from "features/program-variants/ProgramVariantList";
import I18n from "i18n";
import { createContext } from "react";
import { useOutletContext } from "react-router-dom";
import useProgramForm, {
  UseProgramFormProps,
  UseProgramFormReturnType,
} from "./useProgramForm";
export const ProgramContext = createContext<
  UseProgramFormReturnType | undefined
>(undefined);
export interface ProgramFormProps extends UseProgramFormProps {}

export default function ProgramForm() {
  const { hasPermissions, canEditItem } = useAccess();
  const context = useOutletContext<any>();
  const parents = context?.parents;
  const form = useProgramForm({
    defaultValues: { active: true, always_visible: true },
  });
  const {
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
    params,
  } = form;
  const title = isCreate ? I18n.t("Program.addTitle") : item.name;
  const subtitle = isCreate ? undefined : undefined;

  return (
    <ParamsContext.Provider value={params}>
      <ProgramContext.Provider value={form}>
        <PageHeaderBreadcrumbs
          avatar={<TheaterComedyIcon />}
          loading={loading}
          title={title}
          subtitle={subtitle}
          backUrl=".."
          parents={parents}
        ></PageHeaderBreadcrumbs>
        <FormBlock>
          <Typography variant="button">
            {I18n.t("Program.titleSingular")} {I18n.t("App.mainData")}
          </Typography>
          <Spacer h={3} />
          <Form
            loading={loading}
            item={item}
            options={options}
            errors={errors}
            onChange={onChange}
            translationCategory="Program.attributes"
            translationPlaceholdersCategory="Program.placeholders"
            translationHelpersCategory="Program.helpers"
            isDirty={getIsDirty}
            requiredFields={getRequiredFields()}
            disabled={!canEditItem("program", item)}
          >
            <FieldsContainer>
              <Field>
                <TextField id="name" />
              </Field>
              <Field>
                <ColorPicker id="color" />
              </Field>
              <Field>
                <TextField id="code" />
              </Field>
              <Field>
                <TextField id="sku" />
              </Field>
              <Field>
                <TextField id="duration" />
              </Field>
              <Field>
                <Autocomplete id="seating_layout_ids" multiple />
              </Field>
              <Field fullWidth>
                <Checkbox id="has_options" />
                <Checkbox id="always_visible" />
                <Checkbox id="armband" />
                <Checkbox id="active" />
              </Field>
            </FieldsContainer>
          </Form>
          <Spacer h={2} />
          <Toolbar>
            {isUpdate && (
              <CreatedUpdatedBy
                item={item}
                renderDialog={
                  hasPermissions(["history.viewany", "history.viewown"])
                    ? props => <HistoryListDialog {...props} subtitle={title} />
                    : undefined
                }
              />
            )}
            <Spacer right />
            <Button
              title={I18n.t("App.save")}
              disabled={!isDirty}
              onClick={() => save()}
              loading={saving}
            />
          </Toolbar>
        </FormBlock>
        {isUpdate && (
          <>
            <Spacer h={3} />
            {hasPermissions([
              "program_variant.viewany",
              "program_variant.viewown",
            ]) && (
              <ProgramVariantList
                icon={<InterestsIcon />}
                title={I18n.t("ProgramVariant.title")}
                showHeader={false}
                modalProps={{
                  maxWidth: "sm",
                }} /*showHeader={false} toolbarItems={["title", "refresh", "create"]}  showFooter={false}*/
              />
            )}
            <Spacer h={3} />
            {hasPermissions([
              "program_option.viewany",
              "program_option.viewown",
            ]) && (
              <OptionList
                icon={<WorkspacePremiumIcon />}
                title={I18n.t("Option.title")}
                showHeader={false}
                modalProps={{
                  maxWidth: "sm",
                }} /*showHeader={false} toolbarItems={["title", "refresh", "create"]}  showFooter={false}*/
              />
            )}
          </>
        )}
      </ProgramContext.Provider>
    </ParamsContext.Provider>
  );
}
