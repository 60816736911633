import api from "app/api";
import { Options } from "common";

export interface SalesPersonItem {
  id: number;
  name: string;
  email: string;
  phone: string;
  active: boolean;
}

export type SalesPersonOptions = Options<"role" | "status">;

export function getSalesPersonService(scope?: any) {
  return api.getService<SalesPersonItem, SalesPersonItem, SalesPersonOptions>(
    "sales-persons",
    {
      scope,
      //params goes here
    }
  );
}
