import useSettings from "features/settings/useSettings";
import { useWebSocket } from "features/websoket/WebSocketProvider";
import { size } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { CateringItem, getCateringData } from "./cateringApi";

export default function useCateringList() {
  const _storageStartDate = sessionStorage.getItem(`catering.startDate`);
  const _storageEndDate = sessionStorage.getItem(`catering.endDate`);
  const [range, setRange] = useState<
    { start?: Moment; end?: Moment } | undefined
  >({
    start: !!_storageStartDate
      ? moment(_storageStartDate).startOf("day")
      : moment().startOf("day"),
    end: !!_storageEndDate
      ? moment(_storageEndDate).endOf("day")
      : moment().endOf("day"),
  });

  const [settings, setSettings] = useSettings({ key: "catering" });
  const loaded = useRef(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [cateringData, setCateringData] = useState<CateringItem[]>([]);
  const { subscribe, unsubscribe } = useWebSocket();
  async function handleFilter(
    startDate: string | undefined,
    endDate: string | undefined,
    boatIds: number[] | null,
    programIds: number[] | null,
    silent?: boolean
  ) {
    if (!silent) {
      setLoading(true);
    }
    if (startDate) {
      sessionStorage.setItem(`catering.startDate`, startDate);
    } else {
      sessionStorage.removeItem(`catering.startDate`);
    }
    if (endDate) {
      sessionStorage.setItem(`catering.endDate`, endDate);
    } else {
      sessionStorage.removeItem(`catering.endDate`);
    }
    const { success, data } = await getCateringData(
      startDate,
      endDate,
      boatIds,
      programIds
    );
    loaded.current = true;
    if (success) {
      setCateringData(data || []);
    }
    if (!silent) {
      setLoading(false);
    }
  }
  /*const [handleFilter, stopFilter, startFilter] = usePoll(
    async (
      isPoll: boolean,
      startDate: string | undefined,
      endDate: string | undefined,
      boatIds: number[] | null,
      programIds: number[] | null
    ) => {
      if (!isPoll) {
        setLoading(true);
      }
      const { success, data } = await getCateringData(
        startDate,
        endDate,
        boatIds,
        programIds
      );
      loaded.current = true;
      if (success) {
        setCateringData(data || []);
      }
      if (!isPoll) {
        setLoading(false);
      }
    },
    60,
    true
  );*/

  function load(silent?: boolean) {
    return handleFilter(
      range?.start?.format("YYYY-MM-DD"),
      range?.end?.format("YYYY-MM-DD"),
      settings.boatIds,
      settings.programIds,
      silent
    );
  }

  const isEmpty = loaded.current && size(cateringData) === 0;

  useEffect(() => {
    load();
  }, [range, settings.boatIds, settings.programIds]);

  const loader = useRef<(silent?: boolean) => void>();
  loader.current = load;
  useEffect(() => {
    if (range?.start && range?.end) {
      subscribe(
        "catering",
        range?.start?.format("YYYY-MM-DD"),
        range?.end?.format("YYYY-MM-DD"),
        dates => {
          loader.current && loader.current(true);
        }
      );
    }
  }, [range]);

  useEffect(() => {
    return () => unsubscribe("catering");
  }, []);

  return {
    cateringData,
    loading,
    load,
    handleFilter,
    setRange,
    range,
    loaded,
    isEmpty,
    setSettings,
  };
}
