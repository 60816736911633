import { Box, BoxProps, styled } from "@mui/material";
import { clone } from "lodash";
import { Moment } from "moment";
import { FunctionComponent, MutableRefObject, ReactNode } from "react";

import {
  CalendarCellItem,
  CalendarDateRangeItem,
  CalendarEventItem,
  VIEWS,
} from "./calendarApi";
import CalendarCell, { CalendarCellProps } from "./CalendarCell";
import CalendarCellHeader, {
  CalendarCellHeaderProps,
} from "./CalendarCellHeader";
import CalendarHeaderCell, {
  CalendarHeaderCellProps,
} from "./CalendarHeaderCell";
import CalendarToolbar, { CalendarToolbarProps } from "./CalendarToolbar";
import useCalendar from "./useCalendar";

const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const Container = styled(Box)<BoxProps>`
  flex: 1;
  display: flex;
  gap: ${props => props.theme.spacing(1)};
`;

const Content = styled(Box)`
  flex: 1;
  //background-color: red;
`;

interface BadgeProps extends BoxProps {
  color?: string;
}
const Badge = styled(Box, {
  shouldForwardProp: prop => prop !== "color",
})<BadgeProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: ${props => props.color || ""};
  border-radius: 5px;
  min-width: 20px;
  height: 20px;
`;
const CellsContainer = styled(Box)`
  display: grid;
  flex: 1;
  position: relative;
  border: solid 1px ${props => props.theme.palette.divider};
  overflow: hidden;
  gap: 8px;
  border-radius: ${props => props.theme.shape.borderRadius}px;
  overflow-x: auto;
`;

export interface CellContainerProps extends BoxProps {
  view?: VIEWS;
}

const CellContainer = styled(Box, {
  shouldForwardProp: prop => prop !== "view",
})<CellContainerProps>`
  display: flex;
  min-height: 50px;
  position: relative;
`;

export interface CellBackgroundProps extends BoxProps {
  isHoliday?: boolean;
  isOtherMonth?: boolean;
  isCurrentDay?: boolean;
  isSelected?: boolean;
  view?: VIEWS;
}
const CellBackground = styled(Box, {
  shouldForwardProp: (prop: any) =>
    ![
      "isHoliday",
      "isOtherMonth",
      "isCurrentDay",
      "isSelected",
      "view",
    ].includes(prop),
})<CellBackgroundProps>`
  flex: 1;
  border-radius: 5px;
  margin: 1px;
  display: flex;
  flex-direction: column;
`;

export type CalendarRefType<
  EventType extends CalendarEventItem = CalendarEventItem
> = {
  getNextCell: (
    date?: string,
    hasEvent?: boolean
  ) => CalendarCellProps<EventType> | undefined;
  getPrevCell: (
    date?: string,
    hasEvent?: boolean
  ) => CalendarCellProps<EventType> | undefined;
  refresh: (silent?: boolean) => Promise<any> | undefined | void;
  date?: Moment;
  view?: VIEWS;
};

export interface CalendarProps<EventType extends CalendarEventItem> {
  events?: EventType[];
  loadEvents?: (
    range: CalendarDateRangeItem
  ) => Promise<any> | undefined | void;
  onCellPress?: (cell: CalendarCellItem<EventType>, view: VIEWS) => void;
  onEventPress?: (
    item: EventType,
    cell: CalendarCellItem<EventType>,
    view: VIEWS
  ) => void;
  onAddPress?: (
    item: EventType,
    cell: CalendarCellItem<EventType>,
    view: VIEWS
  ) => void;
  date?: Moment;
  view?: VIEWS;
  views?: VIEWS[];
  /** Trigger cell press on specified views. Deafult trigger only MONTH view */
  triggerCellPressOnViews?: VIEWS[];
  buttons?: any[] | ReactNode;
  customToolbar?: any;
  headerContent?: ReactNode;
  calendarTop?: ReactNode;
  loading?: boolean;
  showHeader?: boolean;
  selectedDate?: Moment;
  selectedRange?: CalendarDateRangeItem;
  containerStyle?: BoxProps["sx"];
  swipeableStyle?: BoxProps["style"];
  toolbarStyle?: BoxProps["sx"];
  navigateDelay?: number;
  ToolbarComponent?: FunctionComponent<CalendarToolbarProps>;
  HeaderCellComponent?: FunctionComponent<CalendarHeaderCellProps>;
  CellHeaderComponent?: FunctionComponent<CalendarCellHeaderProps>;
  CellComponent?: FunctionComponent<CalendarCellProps<EventType>>;
  showRefreshButton?: boolean;
  emptyCellPressEnabled?: boolean;
  sortFunction?: (a: any, b: any) => number;
  dayNameShortFunc?: (date: Moment) => string;
  calRef?: MutableRefObject<CalendarRefType<EventType> | undefined>;
  autoLoad?: boolean;
}
export default function Calendar<EventType extends CalendarEventItem>({
  events,
  loadEvents,
  buttons,
  customToolbar,
  loading,
  onCellPress,
  showHeader = true,
  emptyCellPressEnabled = false,
  triggerCellPressOnViews = [VIEWS.MONTH, VIEWS.WEEK],
  containerStyle,
  selectedRange,
  ToolbarComponent = CalendarToolbar,
  HeaderCellComponent = CalendarHeaderCell,
  CellComponent = CalendarCell,
  CellHeaderComponent = CalendarCellHeader,
  showRefreshButton,
  swipeableStyle,
  toolbarStyle,
  headerContent,
  calRef,
  calendarTop,
  onEventPress,
  onAddPress,
  ...calProps
}: CalendarProps<EventType>) {
  const {
    view,
    date,
    newDate,
    cal,
    newView,
    views,
    handleNavigate,
    handleView,
    refresh,
  } = useCalendar<EventType>({ events, loadEvents, ...calProps });

  const gridStyle = {
    gridTemplateColumns: `repeat(${cal.layout?.columns}, 1fr)`,
    //overflowX: "hidden",
    //rowGap: "8px",
    //gridTemplateRows: `auto repeat(${cal.layout.rows}, 1fr)`
  };

  //Ref
  if (calRef) {
    calRef.current = {
      getNextCell: (date, hasEvent = false) => {
        if (!date) {
          return;
        }
        let cell = cal.data?.find(
          d => date && d.date > date && (!hasEvent || d.events.length > 0)
        );
        if (!cell) {
          cell = cal.data?.find(d => !hasEvent || d.events.length > 0);
        }
        return cell;
      },
      getPrevCell: (date, hasEvent = false) => {
        if (!date) {
          return;
        }
        let cell = clone(cal.data)
          ?.reverse()
          .find(
            d => date && d.date < date && (!hasEvent || d.events.length > 0)
          );
        if (!cell) {
          cell = clone(cal.data)
            ?.reverse()
            .find(d => !hasEvent || d.events.length > 0);
        }
        return cell;
      },
      refresh,
    };
  }

  return (
    <Container sx={containerStyle}>
      {showHeader && (
        <ToolbarComponent
          label={cal.getLabel(newDate)}
          date={newDate}
          view={newView}
          views={views}
          onView={handleView}
          onNavigate={handleNavigate}
          buttons={buttons}
          customToolbar={customToolbar}
          loading={loading}
          refresh={refresh}
          showRefreshButton={showRefreshButton}
          headerContent={headerContent}
          sx={toolbarStyle}
        />
      )}
      {calendarTop}
      <Content>
        <HeaderContainer sx={gridStyle}>
          {cal.headers.map((header, index) => (
            <CalendarHeaderCell key={index} {...header} view={view} />
          ))}
        </HeaderContainer>
        <CellsContainer sx={gridStyle} className="CellsContainer">
          {cal.data?.map((d, index) => {
            const cellPressEnabled =
              triggerCellPressOnViews.includes(view) &&
              (d.events.length > 0 || emptyCellPressEnabled);
            return (
              <CellContainer
                className="CellContainer"
                key={index}
                view={view}
                onClick={
                  onCellPress && cellPressEnabled
                    ? () => onCellPress(d as CalendarCellItem<EventType>, view)
                    : undefined
                }
              >
                <CellBackground
                  isSelected={d.isSelected}
                  isHoliday={d.isHoliday}
                  isCurrentDay={d.isCurrentDay}
                  isOtherMonth={d.isOtherMonth && view === VIEWS.MONTH}
                >
                  <CellHeaderComponent {...d} view={view} />
                  <CellComponent
                    {...(d as CalendarCellItem<EventType>)}
                    view={view}
                    onPress={onEventPress}
                    onAddPress={onAddPress}
                  />
                </CellBackground>
              </CellContainer>
            );
          })}
        </CellsContainer>
      </Content>
    </Container>
  );
}
