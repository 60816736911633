import Refresh from "@mui/icons-material/Refresh";
import { IconButton, LinearProgress } from "@mui/material";
import { Permissions } from "app/constants";
import { ButtonGroup, PageHeader, Toolbar } from "common";
import useAccess from "features/profile/useAccess";
import useSettings from "features/settings/useSettings";
import useHeaderHeight from "helpers/useHeaderHeight";
import { useAdvancedReportDataContext } from "./useAdvancedReportData";

export interface AdvancedReportHeaderProps {}

const AdvancedReportHeader: React.FC<AdvancedReportHeaderProps> = ({}) => {
  const { load, loading } = useAdvancedReportDataContext();
  const { hasPermissions } = useAccess();
  const [settings, setSettings] = useSettings({ key: "advancedReport" });
  const headerHeight = useHeaderHeight();
  return (
    <>
      <PageHeader title="Vezetői riport">
        <Toolbar sx={{ gap: 2 }}>
          {hasPermissions(Permissions.ReportPrices) && (
            <ButtonGroup
              size="small"
              options={[
                { value: undefined, label: "EUR" },
                { value: "HUF", label: "HUF" },
                // { value: "USD", label: "USD" },
              ]}
              value={settings.currency}
              onChange={(evt: any) => {
                setSettings({
                  currency: evt.target.value,
                });
              }}
            />
          )}
          <IconButton
            onClick={() => {
              load();
            }}
          >
            <Refresh />
          </IconButton>
        </Toolbar>
      </PageHeader>
      {loading && (
        <LinearProgress
          sx={{ position: "fixed", left: 0, top: headerHeight, right: 0 }}
        />
      )}
    </>
  );
};
export default AdvancedReportHeader;
