import Add from "@mui/icons-material/Add";
import { Toolbar } from "common";
import BookingButton from "components/BookingButton/BookingButton";
import BookingSearch, {
  BookingSearchProps,
} from "components/BookingSearch/BookingSearch";
import { EVENT_STATUSES } from "features/events/eventConstants";
import { BoatSelectorProps } from "../../bookings/BoatSelector";
import CalendarHelpButton from "./CalendarHelpButton";
import CalendarSettings from "./CalendarSettings";
import { openCalendarModal } from "./EventCalendarModals";

export interface EventToolbarProps
  extends BookingSearchProps,
    BoatSelectorProps {}
export default function EventToolbar({
  setBooking,
  setBoatIds,
  boatIds,
}: EventToolbarProps) {
  return (
    <>
      <BookingSearch setBooking={setBooking} />
      <Toolbar sx={{ gap: 2 }}>
        <BookingButton
          buttonType="icon"
          onSelect={(programId, event, planned, datetime) => {
            if (planned) {
              openCalendarModal("booking", {
                //event_id: event.id,
                planned_datetime: datetime,
                planned_event: event as any,
                //event: event as any,
                planned_event_id: event?.id,
                planned: true,
                status: EVENT_STATUSES.ACTIVE,
              });
            } else {
              openCalendarModal("booking", {
                event_id: event?.id,
                event: event as any,
                status: EVENT_STATUSES.ACTIVE,
              });
            }
          }}
        >
          <Add />
        </BookingButton>
        <CalendarSettings />
        <CalendarHelpButton />
      </Toolbar>
    </>
  );
}
