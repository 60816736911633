import HandshakeIcon from "@mui/icons-material/Handshake";
import {
  DataGridV2 as DataGrid,
  DataGridBaseBaseProps,
  DataGridModalEditColumn,
  GridColumns,
  PageHeader,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { SalesPersonItem } from "./salesPersonApi";
import SalesPersonForm from "./SalesPersonForm";
import useSalesPersonList from "./useSalesPersonList";

export interface SalesPersonListProps
  extends Omit<DataGridBaseBaseProps, "id"> {
  showHeader?: boolean;
}

export default function SalesPersonList({
  showHeader = true,
  ...props
}: SalesPersonListProps) {
  const { params, scope, editUrl, ...service } = useSalesPersonList();
  const { hasPermissions, canEditItem } = useAccess();
  let columns: GridColumns<SalesPersonItem> = [
    {
      field: "name",
      headerName: I18n.t("SalesPerson.attributes.name"),
      renderCell: params => (
        <DataGridModalEditColumn
          href={`${editUrl}${params.row?.id}`}
          params={params}
        />
      ),
      flex: 1,
    },
    {
      field: "email",
      headerName: I18n.t("SalesPerson.attributes.email"),
      flex: 1,
    },
    {
      field: "phone",
      headerName: I18n.t("SalesPerson.attributes.phone"),
      flex: 1,
    },
    {
      field: "active",
      headerName: I18n.t("SalesPerson.attributes.active"),
      type: "boolean",
    },
  ];

  return (
    <>
      {showHeader && (
        <PageHeader
          avatar={<HandshakeIcon />}
          title={I18n.t("SalesPerson.title")}
        />
      )}
      <DataGrid
        {...service}
        columns={columns}
        id="sales-persons"
        renderModalForm={props => (
          <SalesPersonForm {...props} defaultValues={{ active: true }} />
        )}
        modalProps={{ maxWidth: "lg" }}
        defaultState={{
          hidden_columns: ["customer_email", "customer_phone"],
        }}
        {...props}
        //getHeaderName={field => I18n.t(`SalesPerson.attributes.${field.replaceAll(".", "_")}`)}
        canCreate={hasPermissions("sales_person.create")}
        canDelete={hasPermissions([
          "sales_person.editany",
          "sales_person.editown",
        ])}
        canEdit={hasPermissions([
          "sales_person.editany",
          "sales_person.editown",
        ])}
        canDeleteRow={params => canEditItem("sales-person", params.row)}
        canEditRow={params => canEditItem("sales-person", params.row)}
        canBatchActions={hasPermissions([
          "sales_person.editany",
          "sales_person.editown",
        ])}
        canHandleTrash={hasPermissions([
          "sales_person.editany",
          "sales_person.editown",
        ])}
      />
    </>
  );
}
