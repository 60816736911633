import { Warning as WarningIcon } from "@mui/icons-material";
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Typography,
} from "@mui/material";
import api from "app/api";
import { Spacer } from "common";
import LimitSummary from "features/events/Calendar/LimitSummary";
import { EventItem } from "features/events/eventApi";
import useProgramSummary from "features/events/useProgramSummary";
import useAccess from "features/profile/useAccess";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import moment from "moment";
import React, { useEffect, useState } from "react";

// Slide animation transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...(props as any)} />;
});

// --- A globális modál komponens ---
const GlobalOnlineCheckModal: React.FC = () => {
  const { hasPermissions } = useAccess();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<{
    limits: string[];
    variants: ProgramVariantItem[];
    event: EventItem;
  } | null>(null);
  const [shake, setShake] = useState(false);
  const { variants, options, summary, limits } = useProgramSummary({
    item: data?.event as any,
  });
  // Eseményfigyelő beállítása
  useEffect(() => {
    const handleCheckLimits = (e: CustomEvent<{ eventId: string }>) => {
      checkEventOnlineLimits(e.detail.eventId);
    };
    document.addEventListener(
      "checkEventOnlineLimits",
      handleCheckLimits as EventListener
    );

    return () => {
      document.removeEventListener(
        "checkEventOnlineLimits",
        handleCheckLimits as EventListener
      );
    };
  }, []);

  // --- Az API hívás helye ---
  const checkEventOnlineLimits = async (eventId: string, refresh?: number) => {
    if (!hasPermissions("booking.create")) {
      return;
    }
    try {
      const { success, data } = await api.get(
        `/events/${eventId}/check-online-limits`,
        { params: { refresh } }
      );
      if (success && data && data.limits) {
        setData(data);
      }
    } catch (error) {
      console.error("API hiba:", error);
    }
  };

  useEffect(() => {
    if (data) {
      setOpen(true);
      setTimeout(() => setShake(true), 100); // Shake effect delay
    }
  }, [data]);

  useEffect(() => {
    if (!open) {
      setData(null);
      setShake(false);
    }
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={open}
      //onClose={() => setOpen(false)}
      TransitionComponent={Transition as any}
      PaperProps={{
        sx: {
          //backgroundColor: "#ffdddd",
          border: "3px solid red",
          boxShadow: "0px 0px 15px red",
          transform: shake ? "translateX(0px)" : "translateX(-10px)",
          animation: shake
            ? "shake 0.4s ease-in-out infinite alternate"
            : "none",
        },
      }}
    >
      <DialogTitle
        sx={{ color: "red", display: "flex", alignItems: "center", gap: 1 }}
      >
        <WarningIcon fontSize="large" color="error" />
        <Box>
          <Typography variant="h5">Online limit túllépés!</Typography>
          <Typography>
            {data?.event?.boat?.name}{" "}
            <span style={{ fontWeight: 700 }}>
              {data?.event?.start_time
                ? moment(data?.event?.start_time).format("L LT")
                : ""}
            </span>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography color="error">Az alábbi programokat zárni kell.</Typography>
        <List dense>
          {data?.variants?.map((variant, i) => (
            <ListItem
              key={i}
              divider
              sx={{
                borderBottom: theme =>
                  `1px solid ${alpha(theme.palette.error.main, 0.4)}`,
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    color: theme =>
                      theme.palette.getContrastText(
                        variant.program?.color || theme.palette.text.primary
                      ),
                    backgroundColor: variant.program?.color,
                  }}
                >
                  {variant.program?.code}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h6",
                  color: "error",
                  sx: { fontWeight: "bold" },
                }}
                primary={variant.name}
              />
            </ListItem>
          ))}
        </List>
        <Spacer h={2} />
        <Card>
          <CardHeader
            title="Limitek lezárása"
            titleTypographyProps={{ variant: "body1" }}
          />
          <LimitSummary
            event={data?.event as any}
            items={limits}
            afterClose={() => {
              checkEventOnlineLimits(data?.event?.id as any, 1);
            }}
          />
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          color="error"
        >
          Bezárás
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GlobalOnlineCheckModal;

// --- Külső függvény az esemény kiváltására ---
export const checkEventOnlineLimits = (eventId: string) => {
  const event = new CustomEvent("checkEventOnlineLimits", {
    detail: { eventId },
  });
  document.dispatchEvent(event);
};
