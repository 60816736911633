import HandshakeIcon from "@mui/icons-material/Handshake";
import KeyIcon from "@mui/icons-material/Key";
import { DialogActions, DialogContent } from "@mui/material";
import {
  Button,
  Checkbox,
  CreatedUpdatedBy,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  Spacer,
  TextField,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import FilterProvider from "components/FilterProvider";
import BookingList from "features/bookings/BookingList";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { createContext } from "react";
import useSalesPersonForm, {
  UseSalesPersonFormProps,
  UseSalesPersonFormReturnType,
} from "./useSalesPersonForm";

export const SalesPersonContext = createContext<
  UseSalesPersonFormReturnType | undefined
>(undefined);

export interface SalesPersonFormProps extends UseSalesPersonFormProps {}

export default function SalesPersonForm({
  id,
  afterSave,
  onClose,
  defaultValues,
}: SalesPersonFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = useSalesPersonForm({
    id,
    afterSave,
    defaultValues,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
  } = form;
  const title = isCreate ? I18n.t("SalesPerson.addTitle") : item.name;
  const subtitle = isCreate ? undefined : undefined;
  return (
    <ParamsContext.Provider value={{ sales_person_id: id }}>
      <SalesPersonContext.Provider value={form}>
        <PageHeader
          avatar={<HandshakeIcon />}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          loading={loading}
        />
        <DialogContent>
          <Form
            loading={loading}
            item={item}
            options={options}
            errors={errors}
            onChange={onChange}
            translationCategory="SalesPerson.attributes"
            translationPlaceholdersCategory="SalesPerson.placeholders"
            translationHelpersCategory="SalesPerson.helpers"
            isDirty={getIsDirty}
            requiredFields={getRequiredFields()}
            disabled={!canEditItem("sales-person", item)}
          >
            <FieldsContainer>
              <Field>
                <TextField id="name" />
              </Field>
              <Field>
                <TextField id="email" />
              </Field>
              <Field>
                <TextField id="phone" />
              </Field>
              <Field>
                <Checkbox id="active" />
              </Field>
            </FieldsContainer>
          </Form>
          {isUpdate && (
            <>
              <Spacer h={3} />
              {hasPermissions(["booking.viewany", "booking.viewown"]) && (
                <FilterProvider
                  id="sales-person.bookings"
                  storageType="session"
                >
                  <BookingList
                    id="sales-person.booking-list"
                    icon={<KeyIcon />}
                    title={I18n.t("Booking.title")}
                    showHeader={false}
                    modalProps={{
                      maxWidth: "lg",
                    }} /*showHeader={false} toolbarItems={["title", "refresh", "create"]}  showFooter={false}*/
                  />
                </FilterProvider>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {isUpdate && (
            <CreatedUpdatedBy
              item={item}
              sx={{
                p: 1,
                paddingTop: 0,
                paddingBottom: 0.5,
                marginRight: "auto",
              }}
              renderDialog={
                hasPermissions(["history.viewany", "history.viewown"])
                  ? props => <HistoryListDialog {...props} subtitle={title} />
                  : undefined
              }
            />
          )}
          <Button
            title={I18n.t("App.save")}
            disabled={!isDirty}
            onClick={() => save()}
            loading={saving}
          />
        </DialogActions>
      </SalesPersonContext.Provider>
    </ParamsContext.Provider>
  );
}
