import api from "app/api";
import { globalMessageHandler } from "common";
import useSettings from "features/settings/useSettings";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";

export type SimpleReportData = {
  amount: number;
  boat: string;
  canceled_amount: number;
  canceled_count: number;
  canceled_guests: number;
  count: number;
  email: string;
  guests: number;
  name: string;
  partner: string;
  partner_group: string;
  program: string;
  program_variant: string;
  t: string; // This represents a date, but in the example, it's a string
};

export type SimpleReportDataFilterType = {
  from: string; //date string like "2024-01-01"
  to: string; //date string like "2024-01-01"
  boatIds?: number[];
  programIds?: number[];
  currency?: null | "EUR" | "HUF";
};

export type SimpleReportDataContextType = {
  data: SimpleReportData[];
  filter: SimpleReportDataFilterType;
  setFilter: (filter: SimpleReportDataFilterType) => void;
  load: () => Promise<void>;
  loading: boolean;
  firstLoaded: boolean;
};

export const SimpleReportDataContext =
  createContext<SimpleReportDataContextType | null>(null);

export default function useSimpleReportData() {
  const [filter, setFilter] = useState<SimpleReportDataFilterType>({
    from: moment().startOf("day").format("YYYY-MM-DD"),
    to: moment().endOf("day").format("YYYY-MM-DD"),
  });
  const [loading, setLoading] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [data, setData] = useState<SimpleReportData[]>([]);
  const [settings] = useSettings({ key: "simpleReport" });

  async function load() {
    //api is an axios instance
    setLoading(true);
    const { success, data, error } = await api.get("reports/advanced", {
      params: {
        ...filter,
        currency: settings.currency,
        boatIds: [],
        programIds: [],
      },
    });
    setLoading(false);
    if (success) {
      if (!firstLoaded) {
        setFirstLoaded(true);
      }
      setData(data);
    } else {
      globalMessageHandler.alert({
        severity: "error",
        message: typeof error === "string" ? error : JSON.stringify(error),
      });
    }
  }

  useEffect(() => {
    load();
  }, [
    filter.programIds,
    filter.boatIds,
    filter.from,
    filter.to,
    settings.currency,
  ]);
  return { data, filter, setFilter, load, loading, firstLoaded };
}

export function useSimpleReportDataContext() {
  const context = useContext(SimpleReportDataContext);
  if (!context) {
    throw Error(
      "useSimpleReportDataContext cannot be used outside of SimpleReportDataProvider."
    );
  }
  return context;
}
