export enum BOOKING_STATUSES {
  ACTIVE = "active",
  CANCELED = "canceled",
  PLANNED = "planned",
}

export enum BOOKING_PAY_LOCATIONS {
  DOCK = "dock",
  HOTEL = "hotel",
  ONLINE = "online",
}
