import { SxProps, Theme, darken, useTheme } from "@mui/material";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { ProgramItem } from "features/programs/programApi";
import { LIMIT_TYPES } from "features/schedules/scheduleConstants";
import { CalendarSettings } from "features/settings/settingsSlice";
import { useMemo } from "react";
import { EventItem, EventLimit } from "./eventApi";
import { EVENT_STATUSES } from "./eventConstants";

export type EventData = {
  eventLimit: EventLimit | undefined;
  reachProgramLimit: boolean;
  programColor: string;
  programTextColor: string;
  programBorderColor: string;
  variants: number[];
  limits: EventLimit[];
  eventSx: SxProps<Theme>;
  eventClassName: string;
  boatClassName: string;
};

function getEventClassName(
  event: EventItem,
  theme: Theme,
  eventLimit?: EventLimit,
  boatLimit?: EventLimit
): string {
  let classNames: string[] = ["calEvent"];

  if (boatLimit?.closed) {
    classNames.push("boatClosed");
  }
  if (eventLimit?.closed) {
    classNames.push("eventClosed");
  }
  if (event?.status === EVENT_STATUSES.CANCELED) {
    classNames.push("eventCanceled");
  }
  if (boatLimit?.online_closed) {
    classNames.push("boatOnlineClosed");
  }
  if (eventLimit?.online_closed) {
    classNames.push("eventOnlineClosed");
  }
  if (eventLimit?.over_tolerance) {
    classNames.push("eventOverTolerance");
  }
  if (eventLimit?.over_limit) {
    classNames.push("eventOverLimit");
  }
  if (eventLimit?.online_over_tolerance) {
    classNames.push("eventOverOnlineTolerance");
  }
  if (eventLimit?.online_over_limit) {
    classNames.push("eventOverOnlineLimit");
  }
  if (event.status === EVENT_STATUSES.CANCELED) {
    classNames.push("eventStatusCancelled");
  }

  return classNames.join(" ");
}
function getEventStyle(
  event: EventItem,
  theme: Theme,
  eventLimit?: EventLimit,
  boatLimit?: EventLimit
): SxProps<Theme> {
  const programColor = event.program?.color || "#FFFFFF";

  const programTextColor = theme.palette.getContrastText(programColor);

  return {
    backgroundColor: programColor,
    "&:hover": {
      backgroundColor: darken(programColor, 0.2),
    },
    color: programTextColor,
  };
}
function getBoatClassName(
  theme: Theme,
  event?: EventItem,
  limit?: EventLimit
): string {
  let classNames: string[] = ["calBoat"];

  //Closed
  if (limit?.closed) {
    classNames.push("boatClosed");
  }
  if (limit?.online_closed) {
    classNames.push("boatOnlineClosed");
  }

  //Over limits
  if (limit?.over_tolerance) {
    classNames.push("boatOverTolerance");
  }
  if (limit?.over_limit) {
    classNames.push("boatOverLimit");
  }
  if (limit?.online_over_tolerance) {
    classNames.push("boatOverOnlineTolerance");
  }
  if (!limit?.over_limit && limit?.online_over_limit) {
    classNames.push("boatOverOnlineLimit");
  }

  return classNames.join(" ");
}
export function getLimitClassName(limit: EventLimit): string {
  const classNames = ["calLimit"];
  if (limit.online_over_tolerance) {
    classNames.push("limitOverOnlineTolerance");
  }
  if (!limit.over_limit && limit.online_over_limit) {
    classNames.push("limitOverOnlineLimit");
  }
  if (limit.over_tolerance) {
    classNames.push("limitOverTolerance");
  }
  if (limit.over_limit) {
    classNames.push("limitOverLimit");
  }
  if (limit.online_closed) {
    classNames.push("limitOnlineClosed");
  }
  if (limit.closed) {
    classNames.push("limitClosed");
  }

  return classNames.join(" ");
}
export function getSettingsClassName(settings: CalendarSettings): string {
  let classNames = ["calContent"];

  if (settings.show_closed_events) {
    classNames.push("showClosedEvents");
  }
  if (settings.show_full_events) {
    classNames.push("showFullEvents");
  }
  if (settings.show_indicators) {
    classNames.push("showIndicatiors");
  }

  return classNames.join(" ");
}

//TODO: nem igazán kell
function getBoatStyle(
  theme: Theme,
  event?: EventItem,
  limit?: EventLimit
): SxProps<Theme> {
  const color =
    event?.status === EVENT_STATUSES.CANCELED || limit?.closed
      ? theme.palette.action.disabled
      : limit?.over_limit
      ? theme.palette.error.main
      : limit?.over_tolerance
      ? theme.palette.warning.main
      : undefined;

  return {
    //boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
    //backgroundColor: color,
    /*border: theme =>
      `${limit?.over_limit ? "dashed" : "solid"} ${
        limit?.over_limit ? 2 : 1
      }px ${limit?.over_limit ? theme.palette.error.main : "transparent"}`,*/
  };
}

export function useEventCalculator() {
  const theme = useTheme();
  const { resolveOption } = useDictionaries();

  return useMemo(() => {
    function getBoatData(event?: EventItem) {
      const boatLimit = event?.limits?.find(l => l.type === LIMIT_TYPES.BOAT);
      const boatClassName = getBoatClassName(theme, event, boatLimit);
      return {
        boatLimit: event?.limits?.find(l => l.type === LIMIT_TYPES.BOAT),
        boatSx: getBoatStyle(theme, event, boatLimit),
        boatClassName,
      };
    }
    function getEventData(event: EventItem, boatLimit?: EventLimit) {
      const eventLimit =
        event.limits?.find(
          l =>
            l.type === LIMIT_TYPES.PROGRAM && l.program_id === event.program_id
        ) || boatLimit;
      const reachProgramLimit = false;
      const programColor =
        event.status === EVENT_STATUSES.CANCELED ||
        boatLimit?.closed ||
        eventLimit?.closed
          ? theme.palette.action.disabled
          : event.program?.color || theme.palette.background.default;

      const programTextColor = theme.palette.getContrastText(programColor);

      const programBorderColor = event.private
        ? theme.palette.info.main
        : event.custom
        ? theme.palette.warning.main
        : reachProgramLimit
        ? theme.palette.error.light
        : programColor;
      const variants =
        event.variants
          ?.map(v => {
            const variantModel = resolveOption<ProgramVariantItem>(
              "variants",
              v.variant_id
            );
            return {
              ...v,
              ...variantModel,
            };
          })
          ?.filter(v => {
            return v.program_id === event.program_id;
          })
          ?.map(v => v.variant_id) || [];
      const limits =
        event.limits?.filter(
          l =>
            l.type === LIMIT_TYPES.CUSTOM &&
            l.variant_ids.some(ll => variants.includes(ll))
        ) || [];

      let programlimits: any[] = [];
      event?.limits?.forEach(limit => {
        const programModel = resolveOption<ProgramItem>(
          "programs",
          limit.program_id
        );
        //console.log(limit.variant_ids.some(l => variantIds.includes(l)));
        if (limit.variant_ids.some(l => variants.includes(l))) {
          if (programModel) {
            programlimits.push({ ...limit, name: programModel.name });
          } else {
            programlimits.push(limit);
          }
        }
      });

      const visibleLimits = limits.filter(l => l.over_tolerance);
      const eventClassName = getEventClassName(
        event,
        theme,
        eventLimit,
        boatLimit
      );

      return {
        eventSx: getEventStyle(event, theme, eventLimit, boatLimit),
        eventLimit,
        reachProgramLimit,
        programColor,
        programTextColor,
        programBorderColor,
        variants,
        limits,
        eventClassName,
        visibleLimits,
        programlimits,
      };
    }
    return { getEventData, getBoatData };
  }, []);
}
