import { store } from "app/store";
import { createApi } from "common";
import { refreshAsync } from "features/identity/identitySlice";
import { sessionId } from "./session"; // Importáltuk a session ID-t
import settings from "./settings";

const api = createApi(
  () => store,
  settings,
  () => refreshAsync,
  {
    baseURL: settings.apiHost,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": settings.i18n.locale,
      "X-Session-ID": sessionId, // Hozzáadjuk a session ID-t a kérésekhez
    },
    timeout: settings.requestTimeout,
  }
);
export default api;
