import {
  Alert,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Checkbox, Toolbar } from "common";
import useSettings from "features/settings/useSettings";
import { formatNumber, formatPrice } from "helpers/priceHelper";
import I18n from "i18n";
import { size } from "lodash";
import React from "react";
import { useSimpleReportContext } from "../useSimpleReport";

export type GroupedReportData = {
  label: string;
  amount: number;
  canceled_amount: number;
  canceled_count: number;
  canceled_guests: number;
  count: number;
  guests: number;
  color: string;
};

export interface SimpleReportPivotTableProps {}

const SimpleReportPivotTable: React.FC<SimpleReportPivotTableProps> = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { pivot: data, params, firstLoaded } = useSimpleReportContext(); // data is of type ChartData
  const [settings, setSettings] = useSettings({ key: "simpleReport" });
  function formatValue(value?: number) {
    if (params.value === "amount" || params.value === "canceled_amount") {
      return formatPrice(value, settings.currency, settings.short_prices);
    }
    return formatNumber(value);
  }

  // Get labels (dates)
  const dates = data.labels as string[];

  // Datasets
  const datasets = data.datasets;

  // Compute column totals (normal and canceled)
  const columnTotals = dates.map((_, colIndex) => {
    let total = 0;
    let canceledTotal = 0;
    datasets.forEach(dataset => {
      total += dataset.data[colIndex] as number;
      //@ts-ignore
      canceledTotal += dataset.dataCanceled[colIndex] as number;
    });
    return { total, canceledTotal };
  });

  // Compute grand totals (normal and canceled)
  const grandTotal = columnTotals.reduce((sum, { total }) => sum + total, 0);
  const grandCanceledTotal = columnTotals.reduce(
    (sum, { canceledTotal }) => sum + canceledTotal,
    0
  );
  let groupName = I18n.t("Program.titleSingular");
  if (params.groupBy === "boat") {
    groupName = I18n.t("Boat.titleSingular");
  } else if (params.groupBy === "partner") {
    groupName = I18n.t("Partner.titleSingular");
  } else if (params.groupBy === "partner_group") {
    groupName = I18n.t("PartnerGroup.titleSingular");
  } else if (params.groupBy === "program_variant") {
    groupName = I18n.t("ProgramVariant.titleSingular");
  }
  return (
    <>
      {firstLoaded && size(data.datasets) === 0 && (
        <Alert severity="info">
          Egy foglalást se rögzítettek az adott időszakra
        </Alert>
      )}
      <TableContainer component={Paper}>
        <Table
          padding="none"
          sx={{
            "& td, th": {
              py: "3px", // General vertical padding for all cells
              px: "8px", // General horizontal padding for all cells
            },
            "& td:first-of-type, th:first-of-type": {
              pl: "16px", // Left padding for the first column cells
            },
            "& td:last-of-type, th:last-of-type": {
              pr: "16px", // Right padding for the last column cells
            },
            "& thead th": {
              py: "12px", // Top padding for the header cells
            },
            "& tr:last-of-type td": {
              py: "12px", // Bottom padding for the last row cells
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  {groupName}
                </Typography>
              </TableCell>
              {dates.map((date, index) => (
                <TableCell key={index} align="right">
                  <Typography variant="subtitle1" fontWeight="bold">
                    {smDown ? date.substring(5) : date}
                  </Typography>
                </TableCell>
              ))}
              {size(dates) > 1 && (
                <TableCell align="right">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {datasets.map((dataset, rowIndex) => {
              const color = dataset.backgroundColor || (dataset as any).color;
              return (
                <TableRow key={rowIndex}>
                  <TableCell component="th" scope="row">
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box
                        sx={{
                          width: 16,
                          minWidth: 16,
                          height: 16,
                          backgroundColor: color,
                          borderRadius: "50%",
                        }}
                      />
                      {dataset.label}
                    </Box>
                  </TableCell>
                  {dataset.data.map((value, colIndex) => {
                    //@ts-ignore
                    const canceledValue = dataset.dataCanceled[colIndex];
                    return (
                      <TableCell
                        key={colIndex}
                        align="right"
                        sx={{
                          backgroundColor: color,
                          color: theme => theme.palette.getContrastText(color),
                        }}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {formatValue(value)}
                        </Typography>
                        {settings.show_canceled &&
                          !isNaN(canceledValue) &&
                          canceledValue !== 0 && (
                            <Typography
                              sx={{ fontSize: "0.7rem", opacity: 0.6 }}
                            >
                              ({formatValue(canceledValue)})
                            </Typography>
                          )}
                      </TableCell>
                    );
                  })}
                  {size(dates) > 1 && (
                    <TableCell
                      align="right"
                      sx={{
                        backgroundColor: color,
                        color: theme => theme.palette.getContrastText(color),
                        fontWeight: "bold",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        {formatValue(dataset.total)}
                      </Typography>
                      {settings.show_canceled &&
                        !isNaN((dataset as any).canceledTotal) &&
                        (dataset as any).canceledTotal !== 0 && (
                          <Typography sx={{ fontSize: "0.7rem", opacity: 0.6 }}>
                            ({formatValue((dataset as any).canceledTotal)})
                          </Typography>
                        )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {/* Add a totals row */}
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" fontWeight="bold">
                  Total
                </Typography>
              </TableCell>
              {columnTotals.map((totals, index) => (
                <TableCell
                  key={index}
                  align="right"
                  sx={{ fontWeight: "bold" }}
                >
                  <Typography sx={{ fontWeight: 500 }}>
                    {formatValue(totals.total)}
                  </Typography>
                  {settings.show_canceled &&
                    !isNaN(totals.canceledTotal) &&
                    totals.canceledTotal !== 0 && (
                      <Typography sx={{ fontSize: "0.7rem", opacity: 0.6 }}>
                        ({formatValue(totals.canceledTotal)})
                      </Typography>
                    )}
                </TableCell>
              ))}
              {size(dates) > 1 && (
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    {formatValue(grandTotal)}
                  </Typography>
                  {settings.show_canceled && !isNaN(grandCanceledTotal) && (
                    <Typography sx={{ fontSize: "0.7rem", opacity: 0.6 }}>
                      ({formatValue(grandCanceledTotal)})
                    </Typography>
                  )}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Toolbar
        sx={{
          mt: -1,
          justifyContent: "flex-end",
          "& label span.MuiTypography-root": {
            fontSize: "0.75rem",
          },
        }}
      >
        <Checkbox
          disabled={!!params.value?.includes("canceled")}
          value={settings.show_canceled}
          onChange={(evt: any) => {
            setSettings({ show_canceled: !settings.show_canceled });
          }}
          size="small"
          label="Visszamondott értékek mutatása"
        />
      </Toolbar>
    </>
  );
};

export default SimpleReportPivotTable;
