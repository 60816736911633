import {
  Box,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import moment from "moment";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { HostItem } from "./hostApi";
import OrderItem from "./OrderItem";

const summaryColors = {
  option: "lightblue",
  variant: "lightgreen",
};

function HostListItem({ item }: { item: HostItem }) {
  const [open, setOpen] = useState(false);
  const { resolveOption } = useDictionaries();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [keyword, setKeyword] = useState<string>();
  const [loading, setLoading] = useState(false);

  function load() {}

  /* useEffect(() => {
    if (!!keyword) {
    }
  }, [keyword]);*/

  const renderPrograms = useCallback(() => {
    return (
      <>
        {item.program_ids.map(program_id => {
          const program = resolveOption<ProgramItem>("programs", program_id);
          return (
            <Toolbar key={program_id} sx={{ gap: 1 }}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: "5px",
                  backgroundColor: program?.color,
                }}
              />
              <Typography>{program?.name}</Typography>
            </Toolbar>
          );
        })}
      </>
    );
  }, [item.program_ids]);

  const summary = item.summary;

  summary.sort((a, b) => b.qty - a.qty);

  return (
    <ListItemButton
      component={Link}
      to={`${encodeURIComponent(item.start_time)}/${item.boat_id}`}
      state={{
        item,
      }}
      divider
    >
      <ListItemText
        disableTypography
        primary={
          <Toolbar sx={{ gap: 1, flexWrap: "wrap" }}>
            <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>{`${moment(
              item.start_time
            ).format("L LT")} - ${item.boat_name}`}</Typography>
            {renderPrograms()}
          </Toolbar>
        }
        secondary={
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            mt={1}
            flexWrap="wrap"
          >
            <OrderItem
              name="Vendégek"
              qty={item.guestSummary.total.number_of_guests || 0}
              type="option"
            />
            <OrderItem
              name="Foglalások"
              qty={item.guestSummary.total.number_of_bookings || 0}
              type="option"
            />
            <OrderItem
              name="Megérkezett"
              qty={item.guestSummary.total.guests_arrived}
              type="option"
            />
            <OrderItem
              name="Leültetett"
              qty={item.guestSummary.total.tables_reserved}
              type="option"
            />
          </Box>
        }
      />
    </ListItemButton>
  );
}

export default HostListItem;
