import api from "app/api";
import { BoatItem } from "features/boats/boatApi";
import { OptionItem } from "features/options/optionApi";
import { PartnerGroupItem } from "features/partner-groups/partnerGroupApi";
import { PartnerItem } from "features/partners/partnerApi";
import { ProgramVariantItem } from "features/program-variants/programVariantApi";
import { ProgramItem } from "features/programs/programApi";
import { SalesPersonItem } from "features/sales-persons/salesPersonApi";
import { SeatingLayoutItem } from "features/seating-layouts/seatingLayoutApi";
import { keyBy } from "lodash";
// Define the API response types
interface VariantsResponse {
  variants: Array<{ id: number; name: string /* other properties */ }>;
}

interface DictionaryUrls {
  [key: string]: [string, (o: any) => string];
}

//Extend the items vith value and label
export type DictionaryItem<T = any> = T & { value: any; label: string };
// Extend this interface for the specific dictionary structure
export interface Dictionary {
  variants: DictionaryItem<ProgramVariantItem>[];
  options: DictionaryItem<OptionItem>[];
  partners: DictionaryItem<PartnerItem>[];
  partnergroups: DictionaryItem<PartnerGroupItem>[];
  programs: DictionaryItem<ProgramItem>[];
  boats: DictionaryItem<BoatItem>[];
  roles: DictionaryItem[];
  layouts: DictionaryItem<SeatingLayoutItem>[];
  salespersons: DictionaryItem<SalesPersonItem>[];
  users: DictionaryItem[];
  // Add other API response types here if needed
}

const dictionaryUrls: DictionaryUrls = {
  variants: ["/program-variants", o => o.name],
  options: ["/options", o => o.name],
  partners: ["/partners", o => o.name],
  programs: ["/programs", o => o.name],
  boats: ["/boats", o => o.name],
  layouts: ["/layouts", o => o.name],
  salespersons: ["/sales-persons", o => o.name],
  // Add other URLs here if needed
};

const nameResolvers: DictionaryUrls = {
  variants: ["/program-variants", o => o.name],
  options: ["/options", o => o.name],
  partners: ["/partners", o => o.name],
  programs: ["/programs", o => o.name],
  boats: ["/boats", o => o.name],
  layouts: ["/layouts", o => o.name],
  salespersons: ["/sales-persons", o => o.name],
  users: ["/users", o => o.last_name + " " + o.first_name],
  // Add other URLs here if needed
};

export async function loadDictionary(): Promise<Dictionary> {
  const { success, data } = await api.get("/dictionaries");
  if (success) {
    let dictionaries: any = {};
    Object.keys(data).map(key => {
      dictionaries[key] = data[key];
      dictionaries[`__${key}_map`] = keyBy(data[key], "value");
    });

    return dictionaries;
  } else {
    console.log("A szótárak betöltése nem sikerült");
    return {} as Dictionary;
  }
}
export async function loadDictionary2(): Promise<Dictionary> {
  const requests = Object.entries(dictionaryUrls).map(([key, [url]]) =>
    api.get(url).then(response => ({ key, data: response.data?.data }))
  );

  const responses = await Promise.all(requests);

  const result = responses.reduce((acc: any, { key, data }) => {
    const nameResolver = dictionaryUrls[key][1];
    const extendedData = data?.map((d: any) => ({
      ...d,
      label: nameResolver(d),
      value: d.id,
    }));
    acc[key] = extendedData;
    acc[`__${key}_map`] = keyBy(extendedData, "id");
    return acc;
  }, {} as Dictionary);

  return result;
}
