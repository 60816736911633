import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  alpha,
  Avatar,
  Box,
  Button,
  ButtonProps,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import { getLabel } from "helpers/dateHelper";
import moment, { Moment } from "moment";
import React, { useRef, useState } from "react";

interface DateRangeSelector2Props
  extends Omit<ButtonProps, "onChange" | "value"> {
  value?: { start?: Moment; end?: Moment };
  onChange: (start: Moment, end: Moment) => void;
  dateButtonMinWidth?: number;
  numberOfCalendars?: number;
  numberOfYears?: number;
}

const DateRangeSelector2: React.FC<DateRangeSelector2Props> = ({
  value,
  onChange,
  dateButtonMinWidth,
  numberOfCalendars = 3,
  numberOfYears = 3,
  ...buttonProps
}) => {
  const [open, setOpen] = useState(false);
  const [displayDate, setDisplayDate] = useState<Moment>(moment());
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelect = (start: Moment, end: Moment) => {
    onChange(start, end);
    handleClose();
  };

  const handlePrev = () => {
    setDisplayDate(prev => prev.clone().subtract(numberOfCalendars, "months"));
  };

  const handleNext = () => {
    setDisplayDate(prev => prev.clone().add(numberOfCalendars, "months"));
  };

  const handleStepPrev = () => {
    if (!value?.start || !value?.end) return;
    let duration = moment.duration(value.end.diff(value.start)).asDays();
    if (duration === 0) {
      duration = 1;
    }

    if (
      value.start.dayOfYear() === 1 &&
      value.end.dayOfYear() === value.end.clone().endOf("year").dayOfYear()
    ) {
      const newStart = value.start.clone().subtract(1, "years").startOf("year");
      const newEnd = value.end.clone().subtract(1, "years").endOf("year");
      handleSelect(newStart, newEnd);
    } else if (
      value.start.date() === 1 &&
      value.end.date() === value.end.daysInMonth()
    ) {
      const newStart = value.start
        .clone()
        .subtract(1, "months")
        .startOf("month");
      const newEnd = value.end.clone().subtract(1, "months").endOf("month");
      handleSelect(newStart, newEnd);
    } else {
      const newStart = value.start.clone().subtract(duration, "days");
      const newEnd = value.end.clone().subtract(duration, "days");
      handleSelect(newStart, newEnd);
    }
  };

  const handleStepNext = () => {
    if (!value?.start || !value?.end) return;
    let duration = moment.duration(value.end.diff(value.start)).asDays();
    if (duration === 0) {
      duration = 1;
    }
    if (
      value.start.dayOfYear() === 1 &&
      value.end.dayOfYear() === value.end.clone().endOf("year").dayOfYear()
    ) {
      const newStart = value.start.clone().add(1, "years").startOf("year");
      const newEnd = value.end.clone().add(1, "years").endOf("year");
      handleSelect(newStart, newEnd);
    } else if (
      value.start.date() === 1 &&
      value.end.date() === value.end.daysInMonth()
    ) {
      const newStart = value.start.clone().add(1, "months").startOf("month");
      const newEnd = value.end.clone().add(1, "months").endOf("month");
      handleSelect(newStart, newEnd);
    } else {
      const newStart = value.start.clone().add(duration, "days");
      const newEnd = value.end.clone().add(duration, "days");
      handleSelect(newStart, newEnd);
    }
  };

  const renderDays = (monthDate: Moment, color?: ButtonProps["color"]) => {
    const startOfMonth = monthDate.clone().startOf("month");
    const endOfMonth = monthDate.clone().endOf("month");
    const startOfWeek = startOfMonth.clone().startOf("isoWeek");
    const endOfWeek = endOfMonth.clone().endOf("isoWeek");

    const days: Moment[] = [];
    for (
      let day = startOfWeek.clone();
      day.isBefore(endOfWeek);
      day.add(1, "day")
    ) {
      days.push(day.clone());
    }

    const weeks: Moment[][] = [];
    let currentWeek: Moment[] = [];

    days.forEach(day => {
      currentWeek.push(day.clone());
      if (currentWeek.length === 7) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    if (currentWeek.length) {
      weeks.push(currentWeek);
    }

    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(8, 1fr)",
          position: "relative",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            top: -14,
            left: 0,
            width: "36px",
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          Hét
        </Typography>
        {["Hé", "Ke", "Sze", "Csü", "Pé", "Szo", "Va"].map((day, index) => {
          return (
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: -14,
                left: 36 + index * 36,
                width: "36px",
                textAlign: "center",
                color: "text.secondary",
              }}
            >
              {day}
            </Typography>
          );
        })}

        {weeks.map((week, index) => (
          <React.Fragment key={index}>
            <Button
              color={color}
              variant="contained"
              size="small"
              onClick={() =>
                handleSelect(
                  week[0].clone().startOf("isoWeek"),
                  week[0].clone().endOf("isoWeek")
                )
              }
              sx={{
                gridColumn: "span 1",
                minWidth: "28px",
                minHeight: "28px",
                padding: 0,
                borderRadius: "50%",
                fontSize: "0.70rem",
                margin: "2px",
              }}
            >
              {week[0].isoWeek()}
            </Button>
            {week.map(day => (
              <Box key={day.format("YYYY-MM-DD")} sx={{ textAlign: "center" }}>
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    bgcolor: theme =>
                      day.clone().startOf("day").isSame(moment().startOf("day"))
                        ? alpha(theme.palette.info.main, 0.2)
                        : "background.paper",
                    color: "text.primary",
                    cursor: "pointer",
                    margin: "2px",
                    fontSize: "0.8rem",
                    "&:hover": {
                      backgroundColor: theme => theme.palette.primary.main,
                      color: theme =>
                        theme.palette.getContrastText(
                          theme.palette.primary.main
                        ),
                    },
                  }}
                  onClick={() => handleSelect(day, day)}
                >
                  {day.date()}
                </Avatar>
              </Box>
            ))}
          </React.Fragment>
        ))}
      </Box>
    );
  };

  const renderMonth = (monthOffset: number, color?: ButtonProps["color"]) => {
    const monthDate = displayDate.clone().add(monthOffset, "months");
    return (
      <Box
        key={monthOffset}
        sx={{
          display: "flex",
          flexDirection: "column",
          m: 1,
        }}
      >
        <Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Button
              color={color}
              onClick={() =>
                handleSelect(
                  monthDate.clone().startOf("month"),
                  monthDate.clone().endOf("month")
                )
              }
              variant="text"
            >
              {monthDate.format("YYYY MMMM")}
            </Button>
          </Box>
        </Box>
        {renderDays(monthDate, color)}
        <Box sx={{ flex: 1 }} />
      </Box>
    );
  };

  const renderYear = (yearOffset: number, color?: ButtonProps["color"]) => {
    const yearDate = displayDate.clone().add(yearOffset, "years");
    return (
      <Box
        key={yearOffset}
        sx={{
          display: "flex",
          flexDirection: "column",
          m: 1,
        }}
      >
        <Box>
          {/*<Typography sx={{ fontWeight: 500, ml: 0.75 }}>Year</Typography>*/}
          <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
            <Button
              color={color}
              onClick={() =>
                handleSelect(
                  yearDate.clone().startOf("year"),
                  yearDate.clone().endOf("year")
                )
              }
              variant="text"
            >
              {yearDate.format("YYYY")}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
  const labels = getLabel(value);
  const theme = useTheme();
  return (
    <Box ref={anchorRef} display="flex" alignItems="center">
      <IconButton onClick={handleStepPrev} color={buttonProps.color}>
        <ArrowBack />
      </IconButton>
      <Button
        variant="text"
        onClick={handleOpen}
        color={theme.palette.mode === "dark" ? "inherit" : undefined}
        {...buttonProps}
        style={
          dateButtonMinWidth ? { minWidth: dateButtonMinWidth } : undefined
        }
      >
        <Box>
          <Typography component="div">{labels.exactLabel}</Typography>
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            mt={-0.5}
          >
            {labels.niceLabel}
          </Typography>
        </Box>
      </Button>
      <IconButton onClick={handleStepNext} color={buttonProps.color}>
        <ArrowForward />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        sx={{ zIndex: 1500 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ p: 2 }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{
                    borderBottom: theme => `solid 1px ${theme.palette.divider}`,
                    pb: 1,
                  }}
                >
                  <IconButton onClick={handlePrev} color={buttonProps.color}>
                    <ArrowBack />
                  </IconButton>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ fontWeight: 500 }}>
                      Válassz időszakot
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      napot/hetet/hónapot
                      {numberOfYears > 0 ? "/évet" : ""}
                    </Typography>
                  </Box>
                  <IconButton onClick={handleNext} color={buttonProps.color}>
                    <ArrowForward />
                  </IconButton>
                </Box>
                <Box display="flex" justifyContent="center">
                  {Array.from({ length: numberOfCalendars }).map((_, index) =>
                    renderMonth(index, buttonProps.color)
                  )}
                </Box>
                {numberOfYears > 0 && (
                  <Box display="flex" justifyContent="center">
                    {Array.from({ length: numberOfYears }).map((_, index) =>
                      renderYear(
                        index -
                          (numberOfYears === 1 ? 0 : (numberOfYears - 1) / 2),
                        buttonProps.color
                      )
                    )}
                  </Box>
                )}
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};

export default DateRangeSelector2;
