import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { Button, ButtonProps, DialogTitle } from "common";
import Dialog from "components/DraggableDialog/DraggableDialog";
import { EventItem } from "features/events/eventApi";
import I18n from "i18n";
import moment from "moment";
import { useState } from "react";
import BookingButtonInner from "./BookingButtonInner";

type BookingButtonBaseProps = {
  onSelect: (
    programId: number,
    event: EventItem | null,
    planned: boolean,
    datetime?: string
  ) => void;
};

type BookingIconButtonProps = {
  buttonType: "icon";
} & BookingButtonBaseProps &
  Omit<IconButtonProps, "onSelect">;

type BookingButtonButtonProps = {
  buttonType: "button";
} & BookingButtonBaseProps &
  Omit<ButtonProps, "onSelect">;

type BookingButtonProps = BookingIconButtonProps | BookingButtonButtonProps;

function parseItems(items: EventItem[]) {
  let _items: any[] = [];
  items.forEach(row => {
    _items.push({
      ...row,
      title: row?.program?.name,
      start: moment(row?.start_time),
      end: moment(row?.start_time).add(1, "hour"),
    });
  });

  return _items;
}

const BookingButton: React.FC<BookingButtonProps> = ({
  onSelect,
  buttonType,
  ...iconButtonProps
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {buttonType === "icon" && (
        <Tooltip title={I18n.t("Booking.addTitle")}>
          <IconButton onClick={handleOpen} {...iconButtonProps} />
        </Tooltip>
      )}
      {buttonType === "button" && (
        <Button onClick={handleOpen} {...(iconButtonProps as ButtonProps)} />
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle onClose={handleClose}>
          {I18n.t("Booking.addTitle")}
        </DialogTitle>
        <BookingButtonInner
          onSelect={onSelect}
          buttonType={buttonType}
          onClose={handleClose}
        />
      </Dialog>
    </>
  );
};

export default BookingButton;
