// FILECONTENT_START src/common/components/Inputs/Price/Price.tsx
import { Euro } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { TextField, TextFieldProps } from "common";
import { useExchangeRate } from "features/exchange-rates/ExchangeRateProvider";

export interface PriceProps extends TextFieldProps {
  count?: number;
  mode?: "normal" | "total";
}
type Currency = "HUF" | "EUR";
export default function Price({
  size,
  sx,
  count = 1,
  mode = "normal",
  value,
  onChange,
  helperText: _helperText,
  ...props
}: PriceProps) {
  const { exchangeRate, currency, setCurrency } = useExchangeRate();
  const displayValue =
    mode === "total" && typeof value === "number" ? value * count : value;

  function getEur(v: any) {
    if (currency === "HUF" && exchangeRate) {
      return parseFloat(v || 0) / exchangeRate.rate;
    } else {
      return v;
    }
  }
  function getHUF(v: any) {
    if (currency === "HUF" && exchangeRate) {
      return Math.round(exchangeRate.rate * parseFloat(v || 0));
    } else {
      return v;
    }
  }
  function getVal(v: any) {
    if (currency === "HUF") {
      return getHUF(v);
    } else {
      return getEur(v);
    }
  }

  const handleChange = (event: {
    target: { value?: string | number | null };
  }) => {
    let newValue = getEur(event.target.value);

    if (mode === "total" && typeof newValue === "number") {
      newValue = newValue / count;
    }
    if (onChange) {
      onChange({ target: { value: newValue } });
    }
  };
  let floatValue = parseFloat((value as any) || 0);
  let v = mode === "total" ? floatValue * count : floatValue;
  let vRounded = Math.round(v * 100000) / 100000;
  let helperText =
    currency !== "EUR"
      ? `${vRounded} EUR (1 EUR = ${exchangeRate?.rate} Ft)`
      : undefined;

  if (_helperText) {
    if (helperText) {
      helperText = helperText + " " + _helperText;
    } else {
      helperText = _helperText;
    }
  }
  return (
    <TextField
      mask={Number}
      numberOptions={{
        scale: 5,
      }}
      unmask
      size="small"
      value={getVal(displayValue)}
      onChange={handleChange}
      sx={{
        ...(size === "small"
          ? {
              minHeight: "27px",
              "& input": {
                padding: "3px",
                paddingLeft: "8px",
                paddingRight: "8px",
              },
            }
          : {
              "& .MuiInputBase-root": { paddingRight: "0px" },
              "& input": {
                paddingRight: "0px!important",
              },
            }),
        ...sx,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() =>
                exchangeRate &&
                setCurrency &&
                setCurrency(currency === "EUR" ? "HUF" : "EUR")
              }
            >
              {currency === "HUF" && (
                <Box
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 700,
                    color: !exchangeRate?.rate ? "error.main" : "info.main",
                    border: theme => `solid 1px ${theme.palette.info.main}`,
                    borderRadius: "2px",
                    px: "3px",
                    py: "1px",
                  }}
                >
                  Ft
                </Box>
              )}
              {currency !== "HUF" && (
                <Euro
                  fontSize="small"
                  color={props.disabled ? "disabled" : undefined}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
      helperText={helperText}
    />
  );
}
// FILECONTENT_END src/common/components/Inputs/Price/Price.tsx
