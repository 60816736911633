import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  Rules,
  ValidationErrors,
  Validator,
  globalMessageHandler,
} from "common";
import I18n from "i18n";
import { useEffect, useRef, useState } from "react";
import { LoginForm } from "./identityAPI";
import { loginAsync, selectIdentityStatus } from "./identitySlice";

export const detectAutofill = (element: any): Promise<boolean> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(
        window
          .getComputedStyle(element, null)
          .getPropertyValue("appearance") === "menulist-button"
      );
    }, 600);
  });
};

export interface LoginFromProps {
  defaultValues?: LoginForm;
}

export function validateLoginForm(values: LoginForm) {
  const rules: Rules = {
    email: Validator.rule.string().required(),
    password: Validator.rule.string().required(),
  };
  return Validator.validate(rules, values, "LoginForm");
}

export default function useLoginFrom({ defaultValues }: LoginFromProps) {
  const [item, setItem] = useState<LoginForm>(
    defaultValues || ({} as LoginForm)
  );
  const emailFieldRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [autoFilled, setAutoFilled] = useState<boolean>();
  const status = useAppSelector(selectIdentityStatus);

  const dispatch = useAppDispatch();
  function onChange(values: LoginForm) {
    if (autoFilled) {
      setAutoFilled(undefined);
    }
    setItem(values);
  }

  /**
   * Check if dom input values are not equal with state. Predicting its an autofill
   * @returns boolean
   */
  function isAutoFilled(): boolean {
    console.log({ v: emailFieldRef.current?.value });
    return !!emailFieldRef.current?.matches(":-webkit-autofill");
    /*return !!(
      (
        (emailFieldRef.current &&
          emailFieldRef.current.value !== item.email &&
          item.email !== undefined) ||
        (passwordFieldRef.current &&
          passwordFieldRef.current.value !== item.password &&
          item.password !== undefined)
      )
      //emailFieldRef.current.matches(":-internal-autofill-selected")
    );*/
  }

  async function login() {
    let _item = { ...item };

    if (isAutoFilled()) {
      _item.email = emailFieldRef.current?.value || "";
      _item.password = passwordFieldRef.current?.value || "";
      setItem(_item);
    }

    const result = validateLoginForm(_item);
    setErrors(result.errors);

    if (!result.success) {
      return;
    }

    const response = await dispatch(loginAsync(_item));

    if (response.meta.requestStatus === "fulfilled") {
      globalMessageHandler.snack({
        severity: "success",
        message: I18n.t("LoginForm.successfulLogin"),
      });
    } else {
      console.log(response.payload);
      if (response.payload) {
        setErrors({
          password: response.payload,
        });
      }
    }
  }

  async function changeAutofill() {
    setAutoFilled((await detectAutofill(emailFieldRef.current)) || undefined);
  }

  useEffect(() => {
    changeAutofill();
  }, []);

  return {
    item,
    errors,
    loading: status === "loading",
    onChange,
    login,
    emailFieldRef,
    passwordFieldRef,
    autoFilled,
  };
}
