import { Box, Paper, useTheme } from "@mui/material";
import CalendarPro from "common/components/CalendarPro/CalendarPro";
import BoatCheckboxSelector from "components/BoatCheckboxSelector/BoatCheckboxSelector";
import ProgramCheckboxSelector from "components/ProgramCheckboxSelector/ProgramCheckboxSelector";
import CalendarToolbar from "./CalendarToolbar";
import EventCalendarModals from "./EventCalendarModals";
import EventToolbar from "./EventToolbar";
import CalendarCellHeaderSmall from "./cells/CalendarCellHeaderSmall";
import StartBoatCell from "./cells/StartBoatCell";
import useEventCalendar from "./useEventCalendar";

export default function EventCalendar() {
  const theme = useTheme();
  const {
    boatIds,
    setBoatIds,
    items,
    event,
    setEvent,
    eventDialog,
    booking,
    setBooking,
    bookingDialog,
    loadEvents,
    cal,
    programIds,
    setProgramIds,
    settings,
    setSettings,
    initialDate,
    initialView,
  } = useEventCalendar({ filterKey: "event", filterStorageType: "session" });

  return (
    <Paper
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        //maxHeight: "calc(100vh - 84px)",
        /*"& .CellContainer:first-of-type .HOURS": {
          display: "block",
        },*/
      }}
    >
      <CalendarPro
        calRef={cal}
        //key={filter?.from}
        navigateDelay={300}
        autoLoad
        loadEvents={loadEvents}
        ToolbarComponent={CalendarToolbar}
        /*toolbarStyle={{
          position: "sticky",
          zIndex: 1000,
          top: 16,
          marginTop: "-8px",
        }}*/
        headerContent={
          <EventToolbar
            setBooking={setBooking}
            setBoatIds={setBoatIds}
            boatIds={boatIds}
          />
        }
        customToolbar={
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              px: 1,
              gap: 2,
              "& label": {
                fontWeight: 500,
              },
            }}
          >
            <BoatCheckboxSelector
              value={boatIds}
              color={theme.palette.primary.main}
              onChange={evt => {
                setBoatIds(evt.target.value || []);
              }}
            />
            <ProgramCheckboxSelector
              value={programIds}
              onChange={evt => {
                setProgramIds(evt.target.value || []);
              }}
              showMore={settings.show_all_programs}
              setShowMore={show_all =>
                setSettings({ show_all_programs: show_all })
              }
            />
          </Box>
        }
        view={initialView.current}
        events={items}
        date={initialDate.current}
        containerStyle={{
          flexDirection: "column",
        }}
        CellComponent={StartBoatCell}
        CellHeaderComponent={CalendarCellHeaderSmall}
        /*onEventPress={cell => {
          setEvent(cell);
        }}
        onAddPress={cell => {
          setBooking({
            event_id: cell.id,
            event: cell,
            status: EVENT_STATUSES.ACTIVE,
          });
        }}*/
      />
      <EventCalendarModals cal={cal} />
    </Paper>
  );
}
