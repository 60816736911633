import { Settings } from "common/types";
import en from "i18n/en.json";
import hu from "i18n/hu.json";

const settings: Settings = {
  apiHost: process.env.REACT_APP_API_HOST,
  websocketHost: process.env.REACT_APP_WEBSOCKET_HOST,
  requestTimeout: 30000,
  loginUrl: "/auth/login",
  refreshTokenUrl: "/auth/refresh",
  logoutUrl: "/auth/logout",
  refreshTokenDeltaTime: 1800,
  refreshTokenNotificationDeltaTime: 600,
  i18n: {
    fallbacks: true,
    locale: "hu", //navigator.language, //"hu-HU",
    defaultLocale: "hu",
    translations: { en, hu },
    moment: {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    },
  },
};

export default settings;
