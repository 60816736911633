import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HistoryIcon from "@mui/icons-material/History";
import HomeIcon from "@mui/icons-material/Home";
import KeyIcon from "@mui/icons-material/Key";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import PersonIcon from "@mui/icons-material/Person";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import {
  alpha,
  Avatar,
  Box,
  BoxProps,
  darken,
  Divider,
  IconButton,
  Link,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import {
  getMonogram,
  ListNavigation,
  NavigationMenuItem,
  Spacer,
  Toolbar,
  useDownloadUrl,
} from "common";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
//@ts-ignore
import { BarChart } from "@mui/icons-material";
import Logo from "app/components/Logo/Logo";
import { Permissions, ROLES } from "app/constants";
import { useAppSelector } from "app/hooks";
import { selectProfile } from "features/profile/profileSlice";
import WangoghLogo from "wangogh/WangoghLogo";
import AdminUserMenu from "./AdminUserMenu";

export interface GroupProps extends BoxProps {
  open?: boolean;
}

export interface AdminSidebarMenuProps {
  open?: boolean;
  temporary?: boolean;
  setOpen?: (open?: boolean) => void;
}
export default function AdminSidebarMenu({
  open,
  setOpen,
  temporary,
}: AdminSidebarMenuProps) {
  const theme = useTheme();
  const profile = useAppSelector(selectProfile);
  const { hasPermissions, hasRoles, notHasRoles } = useAccess();
  const { downloadUrl } = useDownloadUrl();
  return (
    <Paper
      className={"AppSidebarWrapper"}
      /*sx={
        temporary
          ? {
              flex: 1,
              display: "flex",
              flexDirection: "column",
              color: theme => theme.palette.common.white,
              backgroundColor: theme =>
                theme.palette.mode === "dark"
                  ? theme.palette.background.paper
                  : theme.palette.primary.main,
            }
          : {
              flex: 1,
              display: "flex",
              flexDirection: "column",
              color: theme => theme.palette.common.white,
              borderRadius: theme => theme.shape.borderRadius / 3,
              backgroundColor: theme =>
                theme.palette.mode === "dark"
                  ? theme.palette.background.paper
                  : theme.palette.primary.main,
              m: 2,
            }
      }*/
    >
      {!temporary && (
        <>
          <Box
            sx={{
              p: 2,
              overflow: "hidden",
            }}
          >
            <Toolbar sx={{ width: "fit-content" }}>
              <Logo size={40} />
              {open && (
                <>
                  <Spacer w={2} />
                  <Typography
                    variant="h6"
                    sx={{ display: open ? "block" : "none" }}
                  >
                    {I18n.t("App.title")}
                  </Typography>
                </>
              )}
            </Toolbar>
          </Box>
          <Divider />
        </>
      )}
      <ListNavigation minimized={!open} pattern="/">
        {hasPermissions(Permissions.CalendarMenu) && (
          <NavigationMenuItem
            label={I18n.t("Home.title")}
            value=""
            absolute
            icon={<HomeIcon />}
          />
        )}
        {hasPermissions("booking.menu") && (
          <NavigationMenuItem
            label={I18n.t("Booking.title")}
            value="bookings"
            icon={<KeyIcon />}
          />
        )}
        {hasPermissions(Permissions.KitchenMenu) && (
          <NavigationMenuItem
            label={I18n.t("Kitchen.title")}
            value="kitchen"
            icon={<RestaurantMenuIcon />}
          />
        )}
        {hasPermissions(Permissions.CateringMenu) && (
          <NavigationMenuItem
            label={I18n.t("Catering.title")}
            value="catering"
            icon={<EventSeatIcon />}
          />
        )}
        {hasPermissions(Permissions.HostMenu) && (
          <NavigationMenuItem
            label={I18n.t("Host.title")}
            value="host"
            icon={<SupportAgentIcon />}
          />
        )}
        {hasPermissions(Permissions.StreetMenu) && (
          <NavigationMenuItem
            label={I18n.t("StreetSales.title")}
            value="street-sales"
            icon={<StorefrontIcon />}
          />
        )}
        {hasPermissions("event.menu") && (
          <NavigationMenuItem
            label={I18n.t("Event.title")}
            value="events"
            icon={<CalendarMonthIcon />}
          />
        )}
      </ListNavigation>
      {hasPermissions("report.menu") && (
        <ListNavigation
          grouped
          groupTitle={"Riportok".substring(0, open ? undefined : 1)}
          minimized={!open}
          pattern="/reports"
        >
          <NavigationMenuItem
            label={I18n.t("SimpleReport.title")}
            value="simple"
            to="/reports/simple"
            icon={<BarChart />}
          />
          <NavigationMenuItem
            label={I18n.t("AdvancedReport.title")}
            value="advanced"
            to="/reports/advanced"
            icon={<BarChart />}
          />
        </ListNavigation>
      )}
      {hasPermissions([
        "seating_layout.menu",
        "partner_group.menu",
        "program.menu",
        "option_group.menu",
        "boat.menu",
        "schedule.menu",
        "sales_person.menu",
        "partner.menu",
        "exchange_rate.menu",
      ]) && (
        <ListNavigation
          grouped
          groupTitle={"Admin".substring(0, open ? undefined : 1)}
          minimized={!open}
          pattern="/"
        >
          {hasPermissions("seating_layout.menu") && (
            <NavigationMenuItem
              label={I18n.t("SeatingLayout.title")}
              value="seating-layouts"
              icon={<EventSeatIcon />}
            />
          )}
          {hasPermissions("partner_group.menu") && (
            <NavigationMenuItem
              label={I18n.t("PartnerGroup.title")}
              value="partner-groups"
              icon={<WorkspacesIcon />}
            />
          )}
          {hasPermissions("program.menu") && (
            <NavigationMenuItem
              label={I18n.t("Program.title")}
              value="programs"
              icon={<TheaterComedyIcon />}
            />
          )}
          {hasPermissions("option_group.menu") && (
            <NavigationMenuItem
              label={I18n.t("OptionGroup.title")}
              value="option-groups"
              icon={<WorkspacesIcon />}
            />
          )}
          {hasPermissions("boat.menu") && (
            <NavigationMenuItem
              label={I18n.t("Boat.title")}
              value="boats"
              icon={<DirectionsBoatIcon />}
            />
          )}
          {hasPermissions("schedule.menu") && (
            <NavigationMenuItem
              label={I18n.t("Schedule.title")}
              value="schedules"
              icon={<EditCalendarIcon />}
            />
          )}
          {hasPermissions("partner.menu") && (
            <NavigationMenuItem
              label={I18n.t("Partner.title")}
              value="partners"
              icon={<HandshakeIcon />}
            />
          )}
          {hasPermissions("sales_person.menu") && (
            <NavigationMenuItem
              label={I18n.t("SalesPerson.title")}
              value="sales-persons"
              icon={<LoyaltyIcon />}
            />
          )}
          {hasPermissions("exchange_rate.menu") && (
            <NavigationMenuItem
              label={I18n.t("ExchangeRate.title")}
              value="exchange-rates"
              icon={<CurrencyExchangeIcon />}
            />
          )}
        </ListNavigation>
      )}
      {hasRoles([ROLES.SUPERADMIN]) && (
        <ListNavigation
          grouped
          groupTitle={"SuperAdmin".substring(0, open ? undefined : 1)}
          minimized={!open}
          pattern="/"
        >
          {hasPermissions(["user.viewown", "user.viewany"]) && (
            <NavigationMenuItem
              label={I18n.t("User.title")}
              value="users"
              icon={<PersonIcon />}
            />
          )}
          {hasPermissions("role.view") && (
            <NavigationMenuItem
              label={I18n.t("Role.title")}
              value="roles"
              icon={<AdminPanelSettingsIcon />}
            />
          )}
          {hasPermissions(["history.viewany", "history.viewown"]) && (
            <NavigationMenuItem
              label={I18n.t("History.title")}
              value="history"
              icon={<HistoryIcon />}
            />
          )}
          {hasPermissions(["file.view"]) && (
            <NavigationMenuItem
              label={I18n.t("File.title")}
              value="files"
              icon={<AttachFileIcon />}
            />
          )}
        </ListNavigation>
      )}
      <Box flex={1}></Box>
      <Box sx={{ padding: 1 }}>
        <Toolbar
          sx={{
            justifyContent: open ? "flex-end" : "center",
            paddingBottom: 2,
          }}
        >
          <IconButton onClick={() => setOpen && setOpen(!open)}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Toolbar>
        <Toolbar
          sx={{
            marginLeft: theme.spacing(1),
          }}
        >
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.primary.dark, 0.6),
              color: "white",
            }}
            src={profile.file_id ? downloadUrl(profile.file_id) : undefined}
          >
            {getMonogram(profile)}
          </Avatar>
          {open && (
            <>
              <Spacer w={1} />
              <Box>
                <Typography variant="body2">
                  {profile.last_name} {profile.first_name}
                </Typography>
                <Typography
                  variant="body2"
                  fontSize={10}
                  sx={{
                    color: theme => theme.palette.text.secondary,
                  }}
                >
                  {profile.roles?.join(",")}
                </Typography>
              </Box>
              <Spacer right />
              {<AdminUserMenu />}
            </>
          )}
        </Toolbar>
      </Box>
      <Divider />
      <Spacer h={1} />
      <Toolbar
        sx={{
          paddingLeft: 1,
          paddingRight: 2,
          paddingTop: 0,
          paddingBottom: 0.5,
        }}
      >
        <Spacer right />
        <Link
          component="a"
          href="https://www.wangoghsoft.com"
          target="_blank"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: theme => darken(theme.palette.primary.dark, 0.4),
            textDecoration: "none",
          }}
        >
          {open && (
            <>
              <Typography variant="body2" fontSize={10}>
                created by
              </Typography>
              <Spacer w={1} />
            </>
          )}
          <WangoghLogo sx={{ width: 36 }} typo={false} />
        </Link>
      </Toolbar>
    </Paper>
  );
}
