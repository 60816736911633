import {
  DrawerProps as MuiDrawerProps,
  ToolbarProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box, { BoxProps } from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import React, { ReactNode, cloneElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Spacer from "../../components/Spacer";

//const drawerWidth = 240;

const openedMixin = (theme: Theme, sidebarWidth: number): CSSObject => ({
  width: sidebarWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme, closedWidth?: number): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: closedWidth
    ? `calc(${closedWidth}px - ${theme.spacing(1)})`
    : `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: closedWidth
      ? `${closedWidth}px`
      : `calc(${theme.spacing(11)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  sidebarWidth?: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "open",
})<AppBarProps>(({ theme, open, sidebarWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: sidebarWidth,
    width: `calc(100% - ${sidebarWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface DrawerProps extends MuiDrawerProps {
  open?: boolean;
  sidebarWidth: number;
  closedWidth?: number;
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop =>
    prop !== "open" && prop !== "sidebarWidth" && prop !== "closedWidth",
})<DrawerProps>(({ theme, open, sidebarWidth, closedWidth }) => ({
  width: sidebarWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, sidebarWidth),
    "& .MuiDrawer-paper": openedMixin(theme, sidebarWidth),
  }),
  ...(!open && {
    ...closedMixin(theme, closedWidth),
    "& .MuiDrawer-paper": closedMixin(theme, closedWidth),
  }),
}));

export interface HeaderSideBarContent {
  sidebarWidth?: number;
  closedWidth?: number;
  header?: any;
  sidebar?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  breadcrumbs?: ReactNode;
  toolbarProps?: ToolbarProps;
  drawerProps?: MuiDrawerProps;
  fixHeader?: boolean;
  fullWidthHeader?: boolean;
  conetentProps?: BoxProps;
}

export default function HeaderSideBarContent({
  sidebarWidth = 240,
  closedWidth,
  header,
  sidebar,
  children,
  breadcrumbs,
  toolbarProps,
  drawerProps,
  fixHeader,
  footer,
  fullWidthHeader,
  conetentProps,
}: HeaderSideBarContent) {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [openTemporary, setOpenTemporary] = useState(false);
  const [openPersistent, setOpenPersistent] = useState(mdUp);
  const location = useLocation();

  const toggleTemporary = () => {
    setOpenTemporary(!openTemporary);
  };

  const showHeader = fixHeader || (!mdUp && !!header);

  useEffect(() => {
    setOpenPersistent(mdUp);
  }, [mdUp]);
  useEffect(() => {
    if (!mdUp) {
      setOpenTemporary(false);
    }
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex", flex: 1, minHeight: "100%", maxWidth: "100%" }}>
      {showHeader &&
        fullWidthHeader &&
        React.cloneElement(header, {
          onMenuClick: toggleTemporary,
          showMenuButton: sidebar && !openPersistent,
          open: openPersistent,
          setOpen: setOpenPersistent,
          mdUp,
          position: "fixed",
        })}
      {sidebar && (
        <>
          <Drawer
            className="AppSidebar"
            variant="permanent"
            open={openPersistent}
            sidebarWidth={sidebarWidth}
            closedWidth={closedWidth}
            {...drawerProps}
            sx={{
              display: { xs: "none", sm: "none", md: "block" },
              //width: sidebarWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                //width: sidebarWidth,
                boxSizing: "border-box",
              },
              ...drawerProps?.sx,
            }}
          >
            {showHeader && fullWidthHeader && <Toolbar {...toolbarProps} />}
            {sidebar &&
              cloneElement(sidebar as any, {
                open: openPersistent,
                setOpen: setOpenPersistent,
              })}
          </Drawer>
          <MuiDrawer
            className="AppSidebar"
            anchor="left"
            variant="temporary"
            open={openTemporary}
            onClose={toggleTemporary}
            {...drawerProps}
            sx={{
              display: { sm: "block", md: "none" },
              ...drawerProps?.sx,
              "& .MuiDrawer-paper": {
                width: `calc(${sidebarWidth}px - ${theme.spacing(2)})`,
              },
            }}
          >
            {showHeader && fullWidthHeader && <Toolbar {...toolbarProps} />}
            {sidebar &&
              cloneElement(sidebar as any, {
                open: openTemporary,
                setOpen: setOpenTemporary,
                temporary: true,
              })}
          </MuiDrawer>
        </>
      )}
      <Box
        component="main"
        {...conetentProps}
        sx={{
          transition: theme.transitions.create("max-width", {
            easing: theme.transitions.easing.sharp,
            duration: openPersistent
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
          }),
          flexGrow: 1,
          maxWidth:
            sidebar && openPersistent
              ? `calc(100% - ${sidebarWidth}px)`
              : "100%",
          position: "relative",
          ...conetentProps?.sx,
        }}
      >
        {((showHeader && fullWidthHeader) || !!fixHeader) && (
          <Toolbar {...toolbarProps} />
        )}
        {!fullWidthHeader && !!header && (
          <>
            {React.cloneElement(header, {
              onMenuClick: toggleTemporary,
              showMenuButton: sidebar && !openPersistent,
              open: openPersistent,
              setOpen: setOpenPersistent,
              mdUp,
              position: fixHeader ? "fixed" : "static",
              sx: {
                width:
                  sidebar && mdUp
                    ? `calc(100% - ${
                        openPersistent ? sidebarWidth : closedWidth
                      }px)`
                    : "100%",
                transition: theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              },
            })}
          </>
        )}
        <Box
          sx={{ flexGrow: 1, p: smDown ? 0 : 3, pt: 0, maxWidth: "100%" }}
          className="AppContent"
        >
          {breadcrumbs && (
            <>
              {breadcrumbs}
              <Spacer h={2} />
            </>
          )}
          {children}
        </Box>
        {footer}
      </Box>
    </Box>
  );
}
