import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { Button, ButtonProps, DialogTitle } from "common";
import Dialog from "components/DraggableDialog/DraggableDialog";
import { BookingItem } from "features/bookings/bookingApi";
import { EventItem } from "features/events/eventApi";
import I18n from "i18n";
import moment from "moment";
import { useState } from "react";
import AttachBookingButtonInner from "./AttachBookingButtonInner";

type AttachBookingButtonBaseProps = {
  onSelect: (event: EventItem | null) => void;
  booking?: BookingItem;
};

type BookingIconButtonProps = {
  buttonType: "icon";
} & AttachBookingButtonBaseProps &
  Omit<IconButtonProps, "onSelect">;

type AttachBookingButtonButtonProps = {
  buttonType: "button";
} & AttachBookingButtonBaseProps &
  Omit<ButtonProps, "onSelect">;

type AttachBookingButtonProps =
  | BookingIconButtonProps
  | AttachBookingButtonButtonProps;

function parseItems(items: EventItem[]) {
  let _items: any[] = [];
  items.forEach(row => {
    _items.push({
      ...row,
      title: row?.program?.name,
      start: moment(row?.start_time),
      end: moment(row?.start_time).add(1, "hour"),
    });
  });

  return _items;
}

const AttachBookingButton: React.FC<AttachBookingButtonProps> = ({
  onSelect,
  buttonType,
  booking,
  ...iconButtonProps
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {buttonType === "icon" && (
        <Tooltip title={I18n.t("Booking.addTitle")}>
          <IconButton onClick={handleOpen} {...iconButtonProps} />
        </Tooltip>
      )}
      {buttonType === "button" && (
        <Button onClick={handleOpen} {...(iconButtonProps as ButtonProps)} />
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle onClose={handleClose}>
          {I18n.t("Booking.addTitle")}
        </DialogTitle>
        <AttachBookingButtonInner
          onSelect={onSelect}
          buttonType={buttonType}
          onClose={handleClose}
          booking={booking}
        />
      </Dialog>
    </>
  );
};

export default AttachBookingButton;
