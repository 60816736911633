import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  alpha,
  Box,
  Collapse,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Button, Toolbar } from "common";
import useDictionaries from "features/dictionaries/useDictionaries";
import { ProgramItem } from "features/programs/programApi";
import useSettings from "features/settings/useSettings";
import { get } from "lodash";
import { useMemo } from "react";
import { GuestSummary } from "./cateringApi";

export interface CateringBookingListSummaryProps {
  program_ids?: number[];
  selectedProgramIds?: number[];
  toggleProgramSelection?: (programId?: number) => void;
  summary?: { [key: string | number]: GuestSummary };
}
function CateringBookingListSummary({
  program_ids,
  summary,
  selectedProgramIds,
  toggleProgramSelection,
}: CateringBookingListSummaryProps) {
  const { dictionaries, resolveOption, resolveOptions } = useDictionaries();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [settings, setSettings] = useSettings({ key: "catering" });

  const { variantCountMap, hasDetails } = useMemo(() => {
    let map: any = {};
    let details = false;

    dictionaries.variants.forEach(variant => {
      if (program_ids?.includes(variant.program_id)) {
        if (!map[variant.program_id]) {
          map[variant.program_id] = 0;
        }
        map[variant.program_id]++;
        if (map[variant.program_id] > 1) {
          details = true;
        }
      }
    });

    return { variantCountMap: map, hasDetails: details };
  }, [summary, dictionaries.variants, program_ids]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          gap: 1,
          flexWrap: "wrap",
          flex: 1,
        }}
      >
        {program_ids?.map(program_id => {
          const program = resolveOption<ProgramItem>("programs", program_id);
          const isSelected = selectedProgramIds
            ? selectedProgramIds.includes(program_id)
            : false;
          const summaries: GuestSummary = get(
            summary,
            `programs.${program_id}`,
            {} as GuestSummary
          );
          const variants = summaries.variants || {};
          const variantIds: number[] = Object.keys(variants) as any;
          return (
            <Box
              key={program_id}
              sx={{
                //flex: 1,
                //gap: 1,
                flex: 1,
                flexWrap: "wrap",
                display: "flex",
                //justifyContent: "center",
                flexDirection: "column",
                // p: 1,
                borderRadius: "6px",
                minWidth: "200px",
                border: theme =>
                  `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
                backgroundColor: theme => alpha(theme.palette.info.main, 0.1),
                overflow: "hidden",
              }}
            >
              <Toolbar
                sx={{
                  gap: 1,
                  pt: 1,
                  pl: 1,
                  pr: 1,
                  pb: 1,
                  overflow: "hidden",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() =>
                  toggleProgramSelection && toggleProgramSelection(program.id)
                }
              >
                {isSelected ? (
                  <CheckBox sx={{ color: program?.color }} fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlank
                    sx={{ color: program?.color }}
                    fontSize="small"
                  />
                )}
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flex: 1,
                  }}
                >
                  {program?.name}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                >
                  {`${summaries.bookings_arrived}/${summaries.number_of_bookings}`}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                >
                  {`(${summaries.guests_arrived} / ${summaries.number_of_guests})`}
                </Typography>
                {/*<Typography
                  variant="caption"
                  sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                >
                  {`(${summaries.bookings_armbanded} / ${summaries.number_of_bookings})`}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                >
                  {`(${summaries.guests_armbanded} / ${summaries.number_of_guests})`}
                </Typography>*/}
              </Toolbar>
              {variantCountMap[program_id] > 1 && (
                <Collapse in={settings.detailsOpen} sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      //flex: 1,
                      //gap: 1,
                      flex: 1,
                      flexWrap: "wrap",
                      display: "flex",
                      //justifyContent: "center",
                      flexDirection: "column",
                      // p: 1,
                      //borderRadius: "6px",
                      border: theme =>
                        `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
                      backgroundColor: theme =>
                        alpha(theme.palette.info.main, 0.1),
                      //overflow: "hidden",
                    }}
                  >
                    {variantIds.map(variandId => {
                      const variantSummary =
                        variants[variandId] || ({} as GuestSummary);
                      const programVariant = resolveOption(
                        "variants",
                        variandId
                      );

                      return (
                        <Toolbar
                          key={variandId}
                          sx={{
                            gap: 1,
                            pt: 0.5,
                            pl: 1,
                            pr: 1,
                            pb: 0.5,
                            overflow: "hidden",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              width: 10,
                              height: 10,
                              minWidth: 10,
                              minHeight: 10,
                              borderRadius: "5px",
                              backgroundColor: program?.color,
                            }}
                          />
                          <Typography
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              flex: 1,
                            }}
                          >
                            {programVariant?.name}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                          >
                            {`${variantSummary.bookings_arrived}/${variantSummary.number_of_bookings}`}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
                          >
                            {`(${variantSummary.guests_arrived} / ${variantSummary.number_of_guests})`}
                          </Typography>
                        </Toolbar>
                      );
                    })}
                  </Box>
                </Collapse>
              )}
              <Box sx={{ flex: 1 }} />
              <LinearProgress
                sx={{
                  flex: 1,
                  maxHeight: 8,
                  height: 8,
                  minHeight: 8,
                  justifySelf: "flex-end",
                }}
                variant="determinate"
                color="info"
                value={
                  (summaries.bookings_arrived / summaries.number_of_bookings) *
                  100
                }
              />
            </Box>
          );
        })}
        <Box
          sx={{
            //flex: 1,
            //gap: 1,
            flex: 1,
            flexWrap: "wrap",
            display: "flex",
            //justifyContent: "center",
            flexDirection: "column",
            // p: 1,
            borderRadius: "6px",
            border: theme => `solid 1px ${alpha(theme.palette.info.main, 0.4)}`,
            backgroundColor: theme => alpha(theme.palette.info.main, 0.1),
            overflow: "hidden",
            minWidth: "200px",
          }}
        >
          <Toolbar
            sx={{
              gap: 1,
              pt: 1,
              pl: 1,
              pr: 1,
              pb: 1,
              overflow: "hidden",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: 1,
              }}
            >
              Összesen
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
            >
              {`${summary?.total.bookings_arrived}/${summary?.total.number_of_bookings}`}
            </Typography>
            <Typography
              variant="caption"
              sx={{ fontWeight: 700, whiteSpace: "nowrap" }}
            >
              {`(${summary?.total.guests_arrived} / ${summary?.total.number_of_guests})`}
            </Typography>
          </Toolbar>
          <Box sx={{ flex: 1 }} />
          <LinearProgress
            sx={{ flex: 1, maxHeight: 8, height: 8, minHeight: 8 }}
            variant="determinate"
            color="info"
            value={
              ((summary?.total.bookings_arrived || 0) /
                (summary?.total.number_of_bookings || 1)) *
              100
            }
          />
        </Box>
      </Box>
      {hasDetails && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="text"
            color="inherit"
            title={settings.detailsOpen ? "részletek elrejtése" : "részletek"}
            size="small"
            endIcon={settings.detailsOpen ? <ExpandLess /> : <ExpandMore />}
            onClick={() => setSettings({ detailsOpen: !settings.detailsOpen })}
          />
        </Box>
      )}
    </Box>
  );
}

export default CateringBookingListSummary;
