// app/session.ts
import { v4 as uuidv4 } from "uuid";

const SESSION_KEY = "sessionId";

// Ellenőrizzük, hogy van-e már session ID a sessionStorage-ban
let sessionId = sessionStorage.getItem(SESSION_KEY);

// Ha nincs, akkor generálunk egyet és eltároljuk
if (!sessionId) {
  sessionId = `session_${uuidv4()}`;
  sessionStorage.setItem(SESSION_KEY, sessionId);
}

// Exportáljuk a session ID-t, hogy bárhol elérhető legyen
export { sessionId };
