import api from "app/api";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ExchangeRateItem } from "./exchangeRateApi";
export type Currency = "HUF" | "EUR";
export type ExchangeRateState = {
  currency: Currency;
  setCurrency: (currency: Currency) => void;
  load: () => Promise<void>;
  exchangeRate?: ExchangeRateItem;
};

const ExchangeRateContext = createContext<ExchangeRateState>({} as any);

export interface ExchangeRateProviderProps {
  children: any;
}
export default function ExchangeRateProvider({
  children,
}: ExchangeRateProviderProps) {
  const [exchangeRate, setExchangeRate] = useState<ExchangeRateItem>();
  const [currency, setCurrency] = useState<Currency>("EUR");
  async function load() {
    const { success, data, error } = await api.get("/rates/get-rate", {
      params: {
        type: "HUF_EUR",
        date: new Date(),
      },
    });
    if (success) {
      setExchangeRate(data);
    } else {
      console.log(`Error: ${error?.error?.message || "Ismeretlen hiba"}`);
    }
  }
  useEffect(() => {
    load();
  }, []);

  const context = useMemo(() => {
    return {
      exchangeRate,
      currency,
      setCurrency,
      load,
    };
  }, [currency, exchangeRate]);

  return (
    <ExchangeRateContext.Provider value={context}>
      {children}
    </ExchangeRateContext.Provider>
  );
}

export function useExchangeRate(): ExchangeRateState {
  const context = useContext(ExchangeRateContext);
  if (!context) {
    return {} as any;
  }
  return context;
}
