import {
  Alert,
  Avatar,
  Box,
  BoxProps,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fade,
  Popper,
  Typography,
  useTheme,
} from "@mui/material";
import { Toolbar } from "common";
import { EventItem, EventLimit } from "features/events/eventApi";
import { EVENT_STATUSES } from "features/events/eventConstants";
import useSettings from "features/settings/useSettings";
import I18n from "i18n";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  getLimitClassName,
  useEventCalculator,
} from "../../useEventCalculator";
import {
  openCalendarBookingModal,
  openCalendarEventModal,
} from "../EventCalendarModals";
import LimitSummary from "../LimitSummary";

export interface StartBoatCellViewMonthProps extends Omit<BoxProps, "onClick"> {
  item: EventItem;
  boatLimit?: EventLimit;
  onClick?: (item: EventItem) => void;
  onAddClick?: (item: EventItem) => void;
  isSelected?: boolean;
}

export default function EventDot({
  item,
  boatLimit,
  onClick,
  onAddClick,
  isSelected,
  sx,
  ...props
}: StartBoatCellViewMonthProps) {
  const theme = useTheme();
  const refEl = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);
  const leaveTimeout = useRef<NodeJS.Timeout | null>(null);

  function clearTimouts() {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current);
    }
  }
  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    clearTimouts();
    hoverTimeout.current = setTimeout(() => {
      setAnchorEl(refEl.current);
    }, 1000); // 1 second delay
  };

  const handleMouseLeave = () => {
    clearTimouts();
    if (!anchorEl) {
      return;
    }
    leaveTimeout.current = setTimeout(() => {
      setAnchorEl(null);
    }, 1000); // 1 second delay
  };

  useEffect(() => {
    return () => {
      clearTimouts();
    };
  }, []);

  const { getEventData } = useEventCalculator();
  const {
    eventLimit,
    programColor,
    programTextColor,
    programBorderColor,
    limits,
    visibleLimits,
    eventSx,
    programlimits,
    eventClassName,
  } = getEventData(item, boatLimit);
  const [settings] = useSettings({ key: "calendar" });
  const limitsToShow = settings.always_show_remaining_spaces
    ? limits
    : visibleLimits;
  return (
    <Box
      ref={refEl}
      onClick={() => {
        clearTimouts();
        if (onClick) {
          onClick(item);
        } else {
          openCalendarBookingModal(item);
        }
        setAnchorEl(null);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={item.id}
      className={`${eventClassName}${isSelected ? " selected" : ""}`}
      sx={{
        minWidth: "16px",
        padding: "2px",
        paddingLeft: "2px",
        paddingRight: "2px",
        minHeight: "18px",
        borderRadius: "3px",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
        ...(eventSx as any),
        ...sx,
      }}
      {...props}
    >
      <Toolbar sx={{ gap: "2px", paddingLeft: "2px", paddingRight: "2px" }}>
        {limitsToShow.map((limit, index) => {
          const limitClassName = getLimitClassName(limit);

          return (
            <Typography
              key={index}
              className={`${limitClassName} ${settings.font_size}`}
              sx={{
                backgroundColor: theme.palette.getContrastText(
                  item.program?.color
                ),
                color: theme.palette.getContrastText(
                  theme.palette.getContrastText(item.program?.color)
                ),
                padding: "1px",
                borderRadius: "2px",
                fontWeight: 700,
              }}
            >
              {limit.name?.substring(0, 1)}
              {limit.closed ? "" : `:${limit.remaining_places}`}
            </Typography>
          );
        })}
        {(eventLimit?.over_tolerance ||
          settings.always_show_remaining_spaces) && (
          <Typography
            className={settings.font_size}
            sx={{
              padding: "1px",
              fontWeight: 700,
            }}
          >
            {limitsToShow.length > 0 && "|"}
            {eventLimit?.remaining_places}
          </Typography>
        )}
      </Toolbar>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="top"
        transition
        sx={{ zIndex: 1500, position: "fixed" }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10], // Adjust the offset value as needed
            },
          },
          {
            name: "preventOverflow",
            options: {
              boundariesElement: "parent",
              padding: { top: 37, bottom: 10, left: 10, right: 10 },
            },
          },
          {
            name: "flip",
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: { top: 37, bottom: 10, left: 10, right: 10 },
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card
              onMouseLeave={() => {
                clearTimouts();
                setAnchorEl(null);
              }}
              onClick={evt => {
                //Dummy event to prevent parent on click
                evt.stopPropagation();
              }}
            >
              <CardHeader
                title={item?.program?.name}
                color={"red"}
                subheader={`${
                  item?.start_time
                    ? moment(item?.start_time).format("L LT")
                    : ""
                }`}
                avatar={
                  <Avatar
                    sx={{
                      color: programTextColor,
                      backgroundColor: programColor,
                    }}
                  >
                    {item?.program?.code}
                  </Avatar>
                }
              />
              {item?.status === EVENT_STATUSES.CANCELED && (
                <Alert
                  sx={{ borderRadius: 0 }}
                  variant="filled"
                  severity="error"
                >
                  {I18n.t("Event.canceledEvent")}
                </Alert>
              )}
              <CardContent>
                <LimitSummary event={item} items={programlimits} />
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={evt => {
                    evt.stopPropagation();
                    clearTimouts();
                    openCalendarEventModal(item);
                    setAnchorEl(null);
                  }}
                >
                  Részletek
                </Button>
                <Button
                  size="small"
                  onClick={evt => {
                    evt.stopPropagation();
                    clearTimouts();
                    openCalendarBookingModal(item);
                    setAnchorEl(null);
                  }}
                >
                  Foglalás
                </Button>
              </CardActions>
            </Card>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
