import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { DialogActions, DialogContent } from "@mui/material";
import {
  Button,
  Checkbox,
  CreatedUpdatedBy,
  Field,
  FieldsContainer,
  Form,
  PageHeader,
  TextField,
} from "common";
import { ParamsContext } from "common/components/Form/ParamsContext";
import HistoryListDialog from "features/histories/HistoryListDialog";
import useAccess from "features/profile/useAccess";
import I18n from "i18n";
import { createContext } from "react";
import usePartnerGroupForm, {
  UsePartnerGroupFormProps,
  UsePartnerGroupFormReturnType,
} from "./usePartnerGroupForm";
export const PartnerGroupContext = createContext<
  UsePartnerGroupFormReturnType | undefined
>(undefined);
export interface PartnerGroupFormProps extends UsePartnerGroupFormProps {}

export default function PartnerGroupForm({
  id,
  afterSave,
  onClose,
  defaultValues,
}: PartnerGroupFormProps) {
  const { hasPermissions, canEditItem } = useAccess();
  const form = usePartnerGroupForm({
    id,
    afterSave,
    defaultValues,
  });
  const {
    params,
    item,
    options,
    errors,
    loading,
    isDirty,
    isCreate,
    isUpdate,
    getIsDirty,
    getRequiredFields,
    onChange,
    save,
    saving,
  } = form;
  const title = isCreate ? I18n.t("PartnerGroup.addTitle") : item.name;
  const subtitle = isCreate ? undefined : undefined;
  return (
    <ParamsContext.Provider value={{ partner_group_id: id }}>
      <PartnerGroupContext.Provider value={form}>
        <PageHeader
          avatar={<WorkspacesIcon />}
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          loading={loading}
        />
        <DialogContent>
          <Form
            loading={loading}
            item={item}
            options={options}
            errors={errors}
            onChange={onChange}
            translationCategory="PartnerGroup.attributes"
            translationPlaceholdersCategory="PartnerGroup.placeholders"
            translationHelpersCategory="PartnerGroup.helpers"
            isDirty={getIsDirty}
            requiredFields={getRequiredFields()}
            disabled={!canEditItem("partner_group", item)}
          >
            <FieldsContainer>
              <Field fullWidth>
                <TextField id="name" />
              </Field>
              <Field fullWidth>
                <Checkbox id="pay_location_req" />
              </Field>
            </FieldsContainer>
          </Form>
        </DialogContent>
        <DialogActions>
          {isUpdate && (
            <CreatedUpdatedBy
              item={item}
              sx={{
                p: 1,
                paddingTop: 0,
                paddingBottom: 0.5,
                marginRight: "auto",
              }}
              renderDialog={
                hasPermissions(["history.viewany", "history.viewown"])
                  ? props => <HistoryListDialog {...props} subtitle={title} />
                  : undefined
              }
            />
          )}
          <Button
            title={I18n.t("App.save")}
            disabled={!isDirty}
            onClick={() => save()}
            loading={saving}
          />
        </DialogActions>
      </PartnerGroupContext.Provider>
    </ParamsContext.Provider>
  );
}
