import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Autocomplete } from "common";
import { PartnerGroupItem } from "features/partner-groups/partnerGroupApi";
import React, { useEffect, useState } from "react";
import { BookingFilter } from "./useBookingList";

// Props for the PartnerGroupFilter component.
interface PartnerGroupFilterProps {
  filter?: BookingFilter;
  onChange: (filter: BookingFilter) => void;
  partnerGroups: PartnerGroupItem[];
  title?: string;
}

const PartnerGroupFilter: React.FC<PartnerGroupFilterProps> = ({
  filter,
  onChange,
  partnerGroups,
  title,
}) => {
  // Local state to control the dialog visibility.
  const [open, setOpen] = useState<boolean>(false);

  // Local state to keep track of the selected partner group IDs.
  const [selectedPartnerGroups, setSelectedPartnerGroups] = useState<number[]>(
    filter?.partnerGroup || []
  );

  // Update local state when the filter prop changes.
  useEffect(() => {
    if (filter?.partnerGroup) {
      setSelectedPartnerGroups(filter.partnerGroup);
    }
  }, [filter]);

  // Opens the dialog.
  const handleOpen = (evt: any) => {
    evt.stopPropagation();
    setOpen(true);
  };

  // Closes the dialog and applies the filter.
  const handleClose = () => {
    setOpen(false);
    if (
      JSON.stringify(filter?.partnerGroup) !==
      JSON.stringify(selectedPartnerGroups)
    ) {
      onChange({ partnerGroup: selectedPartnerGroups });
    }
  };

  return (
    <>
      <IconButton
        color={selectedPartnerGroups?.length > 0 ? "info" : undefined}
        size="small"
        onClick={handleOpen}
        sx={{ position: "absolute", right: 0, top: 10 }}
      >
        {selectedPartnerGroups?.length > 0 ? (
          <FilterAltIcon />
        ) : (
          <FilterAltOutlinedIcon />
        )}
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={partnerGroups}
            multiple
            onChange={(evt: any) => setSelectedPartnerGroups(evt.target.value)}
            value={selectedPartnerGroups}
            color="secondary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PartnerGroupFilter;
